import React, {Component} from 'react';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import {showToast} from '../components/utils';
import Header from '../parts/header';
import FooterSection from '../parts/footersection';
import TopBanner from '../parts/topbanner';
              /*<div className="form-check pb-2 text-left text-muted">
                <input type="checkbox" id="reg_remember" name="user_remember" className="form-check-input" value="1"/>
                <label className="form-check-label" for="reg_remember">Beni Hatırla</label>
              </div>*/
export class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      token: '',
      username: '',
      passwd:''
    }
  }
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value});
  }

  handleSubmit = (event) => {
    event.preventDefault();
    var retype = 'bg-success';
    const { cookies } = this.props;
    //const url = new URL(document.referrer)
    this.setState({ isLoading: true });
    fetch(this.props.apiurl + '/api/login', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        
        body: JSON.stringify({
          "username": this.state.username,
          "password": this.state.passwd,
        })
      })
      .then((response) => {
        if(!response.ok) {
          retype = 'bg-danger';
          return response.json();
        }
        else{
          retype = 'bg-success';
          return response.json();
        } 
      })
      .then((responseData) => {
        cookies.set('access-token', responseData.token, { path: '/' });
        showToast(responseData.message, retype);
        if (retype=='bg-success') {
           /*if ((url.pathname!='/cikis' && url.pathname!='/kayitol') && (url.pathname.indexOf('/sifreyenile')<1)) {
               window.history.back();
           } else {*/
               window.location='/';
           //}
       }
       })

      .catch((error) => {
        showToast(error, 'bg-danger');
      });
      
      this.setState({ isLoading: false });

  }
  

    render() {
        document.title="Karşıkomşum- Giriş Yap"
        return (
          <div className="App">
          <TopBanner />
          <Header apiurl={this.props.apiurl}/> 
                <div className="container mb-4 mt-4 pt-3">

              <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="card-group mb-0">
                  <div className="card p-4">
                  <form onSubmit={this.handleSubmit}>
                    <div className="card-body">
                      <h2>Giriş Yap</h2>
                      <p className="text-muted">Hesabınıza Giriş Yapın</p>
                      <div className="input-group mb-3 prepend" >
                        <span className="input-group-text"><i className="fa fa-user"></i></span>
                        <input type="text" className="form-control" placeholder="Kullanıcı Adınız" name="username" onChange={this.handleChange} required/>
                      </div>
                      <div className="input-group mb-3">
                        <span className="input-group-text"><i className="fa fa-lock"></i></span>
                        <input type="password" className="form-control" placeholder="Şifre" name="passwd" onChange={this.handleChange} required/>
                      </div>

                      <div className="row">
                        <div className="col-6">
                          <a href="/sifremiunuttum" className="btn btn-link px-0">Şifremi unuttum</a>
                        </div>
                        <div className="col-6 text-right">
                          <button type="submit" className="btn btn-blue px-4 float-right">Giriş Yap</button>
                        </div>

                      </div>
                      
                    </div>
                    </form>
                  </div>
                  <div className="card text-white btn-green pt-4 pb-4 d-md-down-none">
                    <div className="card-body text-center">
                      <div>
                        <h2>Henüz Üye Değil Misiniz?</h2>
                        <p className="mb-5 mt-5">Üyelerimize özel hizmetlerimizden faydalanabilmek için üye olun.</p>
                        <a href="/kayitol" className="btn btn-dark mt-3">Kayıt Ol</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


                </div>
        <FooterSection />
        </div>
        )
    }
}

export default withCookies(LoginPage);