import React from "react";

const Step1 = props => {
  if (props.currentStep !== 1) {
    return null;
  }
  /*const changeCategories= useCallback(
    () => {
    {props.categories.filter(category => category.parent === obj.value).map((e, key) => {
      var option = document.createElement("option");
      option.text = e.title;
      option.value = e.slug;
      var select = document.getElementById("kategori");
      select.appendChild(option);
    })}
  })*/

  return (
    <>
      <p>Lütfen kategori seçin</p>
        <div>
        <form>
          <div className="row">
            <div className="col-md-3 m-2">
              <div>Ana kategori: </div>
              <select class="form-select" size="13" placeholder="Ana Kategori" value={props.selectedCategory} name="anakategori" id="anakategori" onChange={props.handleMainCategoryChange}>
                {props.categories.filter(category => category.parent === 0).map((e, key) => {
                  return <option key={key} value={e.id}>{e.title}</option>;
                })}
              </select>
            </div>
            <div className="col-md-3 m-2">
              <div>Kategori: </div>
              <select class="form-select" size="13" placeholder="Kategori" value={props.selectedCategory} name="kategori" id="kategori" onChange={props.handleCategoryChange}>
                <option>--Kategori Seçin--</option>
                
              </select>
            </div>
            <div className="col-md-3 m-2">
              <div>Alt Kategori: </div>
              <select class="form-select" size="13" placeholder="Alt Kategori" value={props.selectedCategory} name="altkategori" id="altkategori" onChange={props.handleSubCategoryChange}>
                <option>--Alt Kategori Seçin--</option>
              </select>
            </div>
            <div id="0" className="col-md-2  m-2 hidden">

            </div>
          </div>
        </form>
				</div>
    </>
  );
};

export default Step1;
