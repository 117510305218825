import React from "react";
//import { FormGroup, Label, Input, Button } from "reactstrap";
import ImageUploadMultiple from './imageuploadmultiple.js'
const Step4 = props => {
  if (props.currentStep !== 4) {
    return null;
  }
  return (
    <>
      <ImageUploadMultiple fileHandleChange={props.fileHandleChange}/>
    </>
  );
};

export default Step4;
