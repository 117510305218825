import React, {Component} from 'react';
import Header from '../parts/header';
import FooterSection from '../parts/footersection';
import TopBanner from '../parts/topbanner';

export class AboutUs extends Component {
    render() {
        document.title="Karşıkomşum- Hakkımızda"
        return (
          <div className="App">
          <TopBanner />
          <Header apiurl={this.props.apiurl}/> 
          <div className="container bg-white shadow-lg mb-4 mt-4 pt-3 rounded">
                <div className="row text-left">
                    <div className="col-sm-12">
                    <div className="contact-detail-box row m-1">
                      <i className="fa fa-th fa-2x text-colored pr-1"></i>
                      <h4>Hakkımızda</h4>
                    </div>
                    <div className="row m-1">
                      <p><strong>karşıkomşum</strong>, hizmet sektöründe faaliyet göstermek üzere, <a target="_blank" href="http://www.cobee.com.tr">Cobee bilişim teknolojileri A.Ş</a> tarafından Ağustos 2021’de kurulmuştur.</p>
                      <p><a target="_blank" href="http://www.cobee.com.tr">Cobee bilişim teknolojileri A.Ş</a>’nin toplamda 45 yılı aşan teknoloji ve inovasyon konusundaki tecrübesi, bilgi birikimi ,global tecrübesi ve gücü Karşıkomşum'u oluşturmuştur.</p>
                      <p><a target="_blank" href="http://www.karsikomsum.com">karsikomsum.com</a>; apartmanınızdan başlayarak mahallenize,  semtinizden, ilçenize tüm çevrenizle etkileşime geçmenizi sağlar.</p>
                      <p>Siz de bölgenizden haberdar olabilir, paylaşımda bulunabilir, yerel ve global işletmelerden tek tıkla hizmet alabilir, yardımlaşma ve mahalle ruhunu yaşatabilir yahut <a target="_blank" href="http://www.karsikomsum.com">karsikomsum.com</a>’da, avantaj dolu alışverişin keyfini çıkartabilirsiniz</p>
                    </div>
                    <div className="text-center p-5">
                      <h2>Siz de karşıkomşum üyesi olmak için tıklayın</h2>
                      <span className="text-center"><a href="kayitol" className="btn btn-lg btn-dark">Üye Ol</a></span>
                    </div>
                    </div>
                    
              </div>
          </div>
          <FooterSection />
        </div>
        )
    }
}

export default AboutUs;