import React, { Component } from 'react';

class Result extends Component {
 
    render() {
        return (
            
                <a className='' href={this.props.result}>
                    <div className='singleresult border-bottom border-success p-2' >{this.props.result}
                    </div>                    
                </a>

        )
    }
}

export default Result;