import React, { Component } from 'react';
import './leftmenu.css';

class LeftMenu extends Component {
 
    render() {
        return (
            <div className="leftmenu position-sticky pt-1">
                <ul className="leftmenu-items flex-column justify-content-left">
                    <li className="btn btn-light col-md-12 text-left"> <a href="/"><span className="m-2"><i class="fas fa-street-view"></i></span> Semtim</a></li>
                    <li className="btn btn-light col-md-12 text-left"> <a href="/"><span className="m-2"><i class="fas fa-user-friends"></i></span> Komşularım</a></li>
                    <li className="btn btn-light col-md-12 text-left"> <a href="/"><span className="m-2"><i class="fas fa-newspaper"></i></span>Haber akışı</a></li>
                    <hr/>
                    <li className="text-muted ">Bölgesel</li>
                    <hr/>
                    <li className="btn btn-light col-md-12 text-left"> <a href="/"><span className="m-2"><i class="fas fa-people-carry"></i></span><span className="">Dayanışma</span></a></li>
                    <li className="btn btn-light col-md-12 text-left"> <a href="/"><span className="m-2"><i class="fas fa-building"></i></span> İşletmeler</a></li>
                    <li className="btn btn-light col-md-12 text-left"> <a href="/"><span className="m-2"><i class="fas fa-glass-cheers"></i></span> Etkinlikler</a></li>
                    <li className="btn btn-light col-md-12 text-left"> <a href="/"><span className="m-2"><i class="far fa-handshake"></i></span> İkinci El</a></li>
                    <li className="btn btn-light col-md-12 text-left"> <a href="/"><span className="m-2"><i class="fas fa-tags"></i></span> Kampanyalar</a></li>
                    <li className="btn btn-light col-md-12 text-left"> <a href="/"><span className="m-2"><i class="fas fa-sign"></i></span> Emlak</a></li>
                    <li className="btn btn-light col-md-12 text-left"> <a href="/"><span className="m-2"><i class="fas fa-user-shield"></i></span> Güvenlik</a></li>
                    <li className="btn btn-light col-md-12 text-left"> <a href="/"><span className="m-2"><i class="fas fa-dog"></i></span> Kayıplar</a></li>
                    <li className="btn btn-light col-md-12 text-left"> <a href="/"><span className="m-2"><i class="fas fa-map-marked-alt"></i></span> Harita</a></li>
                    <hr/>
                    <li>Gruplarınız</li>
                    <hr/>
                    <li>Fenerliler</li>
                </ul>
            </div>
        )
    }
}

export default LeftMenu;