import React, {Component} from 'react';
import Header from '../parts/header';
import FooterSection from '../parts/footersection';
import TopBanner from '../parts/topbanner';

export class Contracts extends Component {
  constructor(props) {
    super(props);
    this.expImg = React.createRef();
  }
  state = {
    url: this.props.apiurl + '/api/utils_site_settings',
    baslik: this.baslikAt(this.props.activetab),
    uyelikactive: (this.props.activetab==='uyeliksozlesmesi' ? "nav-item nav-link has-icon nav-link-faded active" : "nav-item nav-link has-icon nav-link-faded"),
    uyelikicerik: (this.props.activetab==='uyeliksozlesmesi' ? "tab-pane  active" : "tab-pane"),
    mesafeliactive: (this.props.activetab==='mesafelisatissozlesmesi' ? "nav-item nav-link has-icon nav-link-faded active" : "nav-item nav-link has-icon nav-link-faded"),
    mesafeliicerik: (this.props.activetab==='mesafelisatissozlesmesi' ? "tab-pane  active" : "tab-pane"),
    gizlilikactive: (this.props.activetab==='gizliliksozlesmesi' ? "nav-item nav-link has-icon nav-link-faded active" : "nav-item nav-link has-icon nav-link-faded"),
    gizlilikicerik: (this.props.activetab==='gizliliksozlesmesi' ? "tab-pane  active" : "tab-pane"),
    kvkkactive:  (this.props.activetab==='kvkkvecerezler' ? "nav-item nav-link has-icon nav-link-faded active" : "nav-item nav-link has-icon nav-link-faded"),
    kvkkicerik: (this.props.activetab==='kvkkvecerezler' ? "tab-pane  active" : "tab-pane"),
    caymaiptaliadeactive:  (this.props.activetab==='caymaiptaliade' ? "nav-item nav-link has-icon nav-link-faded active" : "nav-item nav-link has-icon nav-link-faded"),
    caymaiptaliadeicerik: (this.props.activetab==='caymaiptaliade' ? "tab-pane  active" : "tab-pane"),
  }

  baslikAt(param) {
    switch(param) {
      case 'uyeliksozlesmesi':
        return 'Üyelik Sözleşmesi';
      case 'mesafelisatissozlesmesi':
          return 'Mesafeli Satış Sözleşmesi';
      case 'gizliliksozlesmesi':
          return 'Gizlilik Sözleşmesi';
      case 'kvkkvecerezler':
          return 'Kişisel Verilerin Korunması ve Çerez Politikası';
      case 'caymaiptaliade':
          return 'Tüketici Hakları – Cayma, İptal ve İade Koşulları';
      default:
        return '';
    }
  }
  //this.props.activetab=="uyeliksozlesmesi" ? this.setState({uyelikactive:"nav-item nav-link has-icon nav-link-faded active"}): this.setState({uyelikactive:"nav-item nav-link has-icon nav-link-faded"}) ;
    render() {
        document.title="Karşıkomşum- Sözleşmeler"
        return (
          <div className="App">
          <TopBanner />
          <Header apiurl={this.props.apiurl}/> 
          <div className="container bg-white shadow-lg mb-4 mt-4 pt-3 pb-3 rounded text-left">
              
                <nav aria-label="breadcrumb" className="main-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Anasayfa</a></li>
                    <li className="breadcrumb-item"><a href="/sozlesmeler">Sözleşmeler</a></li>
                    <li className="breadcrumb-item active" aria-current="page">{this.state.baslik}</li>
                  </ol>
                </nav>


                <div className="row gutters-sm">
                  <div className="col-md-4 d-none d-md-block">
                    <div className="card">
                      <div className="card-body">
                        <nav className="nav flex-column nav-pills nav-gap-y-1">
                          <a href="/sozlesmeler/uyeliksozlesmesi" data-toggle="tab"  className={this.state.uyelikactive}>
                            Üyelik Sözleşmesi
                          </a>
                          <a href="/sozlesmeler/mesafelisatissozlesmesi" data-toggle="tab" className={this.state.mesafeliactive}>
                            Mesafeli Satış Sözleşmesi
                          </a>
                          <a href="/sozlesmeler/gizliliksozlesmesi" data-toggle="tab" className={this.state.gizlilikactive}>
                            Gizlilik Sözleşmesi
                          </a>
                          <a href="/sozlesmeler/kvkkvecerezler" data-toggle="tab" className={this.state.kvkkactive}>
                            Kişisel Verilerin Korunması
                          </a>
                          <a href="/sozlesmeler/caymaiptaliade" data-toggle="tab" className={this.state.caymaiptaliadeactive}>
                          Tüketici Hakları – Cayma, İptal ve İade Koşulları
                          </a>

                        </nav>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="card">

                      <div className="card-body tab-content sozlesme">
                        <div className={this.state.uyelikicerik} id="uyeliksozlesmesi">

                          <h4>{this.state.baslik}</h4>
                          <hr/>

                          <h3>SİTE KULLANIM ŞARTLARI</h3>
          <p>Lütfen sitemizi kullanmadan evvel bu ‘site kullanım şartları’nı dikkatlice okuyunuz.
          Bu alışveriş sitesini kullanan ve alışveriş yapan üyeler aşağıdaki şartları kabul etmiş sayılır.</p>
          <p>
          Sitemizdeki web sayfaları ve ona bağlı tüm sayfalar www.karsikomsum.com (‘site’)  <strong>COBEE BİLİŞİM ve
          MÜHENDİSLİK ARACILIK HİZMETLERİ A.Ş</strong> firmasının (ARACI) malıdır ve onun tarafından işletilir. ARACI,
          Alıcı Üye (‘Alıcı Üye Kullanıcı’) ve Satıcı Üye (Satıcı Üye Kullanıcı) (ÜYELER) sitede sunulan tüm
          hizmetleri kullanırken aşağıdaki şartlara tabi olduğunu, sitedeki hizmetten yararlanmakla ve kullanmaya
          devam etmekle; Bağlı olunan yasalara göre sözleşme imzalama hakkına, yetkisine ve hukuki ehliyetine
          sahip ve 16 yaşın üzerinde olduğunu, bu sözleşmeyi okuduğunu ve anladığını kabul ve beyan eder.
          İşbu sözleşme, taraflara sözleşme konusu site ile ilgili hak ve yükümlülükler yükler ve taraflar işbu
          sözleşmeyi kabul ettiklerinde yazan şartlarla bağlı olduğunu kabul etmiş sayılır.Bahsi
          geçen hak ve yükümlülükleri eksiksiz, doğru, zamanında, işbu sözleşmede talep edilen şartlar
          dâhilinde yerine getireceklerini beyan ederler.</p>

          <h3>1. SORUMLULUKLAR</h3>
          <p><strong>a.</strong> ARACI, fiyatlar ve sunulan ürün ve hizmetler üzerinde değişiklik yapma hakkını her zaman saklı
          tutar.</p>
          <p><strong>b.</strong> ARACI, üyelerin sözleşme konusu hizmetlerden, teknik arızalar dışında yararlandırılacağını
          kabul ve taahhüt eder.</p>
          <p><strong>c.</strong> Üyelerin, sitenin kullanımında tersine mühendislik yapmayacağını ya da bunların kaynak
          kodunu bulmak veya elde etmek amacına yönelik herhangi bir başka işlemde bulunmayacağını,
          aksi halde tespit edileceğini ve 3. Kişiler nezdinde doğacak zararlardan sorumlu olacağını,
          hakkında hukuki ve cezai işlem yapılacağını peşinen kabul eder.</p>
          <p><strong>d.</strong> Üyeler, site içindeki faaliyetlerinde, sitenin herhangi bir bölümünde veya iletişimlerinde genel
          ahlaka ve adaba aykırı, kanuna aykırı, 3. Kişilerin haklarını zedeleyen, yanıltıcı, saldırgan,
          müstehcen, pornografik, kişilik haklarını zedeleyen, telif haklarına aykırı, yasa dışı faaliyetleri
          teşvik eden içerikler üretmeyeceğini, paylaşmayacağını kabul eder. Aksi halde oluşacak zarardan
          tamamen kendisi sorumludur ve bu durumda ‘Site’ yetkilileri, bu tür hesapları askıya alabilir, sona
          erdirebilir, yasal süreç başlatma hakkını saklı tutar. Bu sebeple yargı mercilerinden etkinlik veya
          kullanıcı hesapları ile ilgili bilgi talepleri gelirse paylaşma hakkını saklı tutar.</p>
          <p><strong>e.</strong> Sitenin üyelerinin birbirleri veya üçüncü şahıslarla olan ilişkileri kendi sorumluluğundadır.</p>

          <h3>2. FİKRİ MÜLKİYET HAKLARI</h3>
          <p><strong>2.1.</strong> İşbu Site’de yer alan ünvan, işletme adı, marka, patent, logo, tasarım, bilgi ve yöntem gibi
          tescilli veya tescilsiz tüm fikri mülkiyet hakları site işletmesi ve/veya sahibi, aracı veya belirtilen ilgilisine
          ait olup, ulusal ve uluslararası hukukun koruması altındadır. İşbu Site’nin ziyaret edilmesi veya bu
          Site’deki hizmetlerden yararlanılması söz konusu fikri mülkiyet hakları konusunda hiçbir hak
          vermez.</p>
          <p><strong>2.2.</strong> Site’de yer alan bilgiler hiçbir şekilde çoğaltılamaz, yayınlanamaz, kopyalanamaz, sunulamaz
          ve/veya aktarılamaz. Site’nin bütünü veya bir kısmı diğer bir internet sitesinde izinsiz olarak
          kullanılamaz.</p>

          <h3>3. GİZLİ BİLGİ</h3>
          <p><strong>3.1.</strong> ARACI, site üzerinden kullanıcıların ilettiği kişisel bilgileri 3. Kişilere açıklamayacaktır. Bu
          kişisel bilgiler; kişi adı-soyadı, adresi, telefon numarası, cep telefonu, e-posta adresi , kredi kartı
          bilgileri gibi Kullanıcı’yı tanımlamaya yönelik her türlü diğer bilgiyi içermekte olup, kısaca ‘Gizli
          Bilgiler’ olarak anılacaktır.</p>
          <p><strong>3.2.</strong> İş bu üyelik sözleşmesi uyarınca, üyelerinin kendisinde kayıtlı elektronik posta adreslerine
          bilgilendirme mailleri ve cep telefonlarına bilgilendirme SMS'leri gönderme yetkisine sahip
          olmakla beraber, üye, işbu üyelik sözleşmesini onaylamasıyla beraber bilgilendirme
          maillerinin elektronik posta adresine ve bilgilendirme SMS'lerinin cep telefonuna
          gönderilmesini kabul etmiş sayılacaktır.</p>
          <p><strong>3.3.</strong> Aracı (site), sadece tanıtım, reklam, kampanya, promosyon, duyuru vb. pazarlama
          faaliyetleri kapsamında kullanılması ile sınırlı olmak üzere, Site’nin sahibi olan firmanın
          kendisine ait iletişim, portföy durumu ve demografik bilgilerini iştirakleri ya da bağlı bulunduğu
          grup şirketleri ile paylaşmasına muvafakat ettiğini kabul ve beyan eder. Bu kişisel bilgiler firma
          bünyesinde müşteri profili belirlemek, müşteri profiline uygun promosyon ve kampanyalar
          sunmak ve istatistiksel çalışmalar yapmak amacıyla kullanılabilecektir.</p>
          <p><strong>3.3.</strong> Gizli Bilgiler, ancak resmi makamlarca usulü dairelerinde bu bilgilerin talep edilmesi halinde ve
          yürürlükteki emredici mevzuat hükümleri gereğince resmi makamlara açıklama yapılmasının
          zorunlu olduğu durumlarda resmi makamlara açıklanabilecektir.</p>

          <h3>4. GARANTİ VERMEME</h3>
          <p>İŞBU SÖZLEŞME MADDESİ UYGULANABİLİR KANUNUN İZİN VERDİĞİ
          AZAMİ ÖLÇÜDE GEÇERLİ OLACAKTIR. FİRMA TARAFINDAN SUNULAN HİZMETLER
          "OLDUĞU GİBİ” VE "MÜMKÜN OLDUĞU” TEMELDE SUNULMAKTA VE PAZARLANABİLİRLİK,
          BELİRLİ BİR AMACA UYGUNLUK VEYA İHLAL ETMEME KONUSUNDA TÜM ZIMNİ
          GARANTİLER DE DÂHİL OLMAK ÜZERE HİZMETLER VEYA UYGULAMA İLE İLGİLİ OLARAK
          (BUNLARDA YER ALAN TÜM BİLGİLER DÂHİL) SARİH VEYA ZIMNİ, KANUNİ VEYA BAŞKA
          BİR NİTELİKTE HİÇBİR GARANTİDE BULUNMAMAKTADIR.</p>

          <h3>5. ÜRÜN TESLİMİ</h3>
          <p>Alıcı Üye’nin satın almış olduğu ürün/ürünler de Oluşabilecek Hasar/Arıza/Eksiklik Ve sorunlarda
          sorumluluk Satıcı Üye’ye Aittir. Bu gibi durumlarda ARACI Sorumlu Tutulamaz.</p>

          <h3>6. ÜRÜN YÖNETİMİ</h3>
          <p>ARACI Sitesinde Satışı sunulan ürün/ürünlerin doğruluğu ve gerçekliği Satıcı Üye’nin
          sorumuluğundadır.Satıcı Üye bilgileri Doğru ve Eksiksiz bildirmeli ve satış sonrası beyan edilen
          hallerde ürünün teslimini ARACI’nın Anlaşmalı Kargo şirketi aracılığı ile gerçekleştirmelidir.
          ARACI’nın aracılık hizmetleri dışında herhangi bir sorumluluğu yoktur. Üyeler arasında oluşacak
          ithilaflarda çözüm sağlanmaması durumunda yetkili mahkemelerde açılacak dava/davalarda
          ‘ARACI’ taraf tutulamaz.</p>

          <h3>7. ÜRÜN İADESİ</h3>
          <p>Alıcı tarafından ödemesi yapılmış ve adresine ulaştırılmış ürün/ürünlerin 14 (ondört) iş günü
          içerisinde iade sebebi belirtilerek ARACI’nın Anlaşmalı olduğu Kargo Şirketi ile Satıcı üyenin
          adresine iadesi yapılmalıdır. Bu durumların dışında yapılan iade işlemlerinin geçerliliği yoktur.</p>

          <h3>8. ÜRÜN SORUMLULUĞU</h3>
          <p>Satışı yapılan ürün/ürünlerin her türlü kayıp/çalıntı ve şüpheli işlemlerinde sorumluluk Alıcı Üye,
          Satıcı Üye ve Tutanak belgesi ibrazı şartıyla Anlaşmalı Kargo firmasındadır.
          Bu ve buna benzer durumlarda oluşacak zarar tazmini Başvurusu ARACI’dan Talep Edilemez.</p>

          <h3>9. ÖDEMELER</h3>
          ARACI üzerinden satışı gerçekleşen Ürün/ürünler ve hizmet bedelinin Satıcı Üye’ye Aktarımı şu
          şekillerde gerçekleşir.
          <p>9.1 Alıcı üye tarafından ödeme sonrası Ürün/ürünler ve hizmet Alıcı Üye’ye teslim edildikten
          sonra onay verilmesi durumunda,</p>
          <p>9.2 Ürün/ürünler ve hizmetin Alıcı Üye Tarafından onayı gelmemesi durumunda ürün teslimi
          sonrası 1 iş günü içerisinde otomatik onay sonrası,</p>
          <p>9.3 Ürün/ürünler ve hizmetin onayı sonrasında 1 ila 7 iş günü arasında satıcının beyan ettiği
          doğru İban Numarasına transferi gerçekleşir.</p>
          <p>9.4 Ürün/ürünler ve hizmetin iadesi söz konusu olduğunda ürün bedeli 9.1/9.2 maddeleri baz
          alınarak alıcı üye’ye akratılır.</p>

          <h3>10. SİPARİŞ, KAYIT ve GÜVENLİK</h3>
          <p>Alıcı üye,satış sonrası sepetine yansıtılan ürünün ödemesini 1 iş günü içerisinde yapmalıdır.
          Güncel kayıt bilgilerini, adres, Tc kimlik no, iletişim bilgileri gibi üyelik bilgilerini doğru, eksiksiz ve
          açık olarak vermek zorundadır. Aksi halde bu Sözleşme ihlal edilmiş sayılacak ve Kullanıcı
          bilgilendirilmeksizin üyelik kapatılabilecektir.</p>

          <p>10.1. Satıcı üye, satışa sunduğu ürünün bilgilerini ve açıklamalarını eksiksiz ve tam olarak,net
          çekilmiş resimlerle ihtilafa mahal vermeksizin sunmalıdır. Satıcı üye tarafından satışa sunulan
          ürünlerden doğacak ihtilaf ve anlaşmazlıklardan ARACI sorumlu tutulamaz.</p>
          <p>10.2. ARACI, Alıcı üye tarafından ödemesi yapılan ve alınan ürünlerin Alıcı Üye'nin ARACI'ya
          eksiksiz beyanı ile sorun ve problem arz edilmesi durumunda Satıcı üye'ye İhtar çekme ya da
          Satıcı üye'nin üyeliğini kapatma hakkı vardır.</p>
          <p>10.3. ARACI, Satıcı üyenin satışa sunduğu ürünü reddetme hakkına sahiptir.</p>
          <p>10.4. ARACI, Satıcı üyenin satışa sunduğu ürünü fiziksel olarak depo adresine ARACI'nın
          anlaşmalı olduğu kargo firması ile peşin ödemeli olarak gönderilmesini isteme hakkına sahiptir.
          Gönderimi sağlanan ürünün kargo aşamasında hasar görmesi durumunda sorumluluk Satıcı
          üye'ye Aittir.</p>

          <h3>11. MÜCBİR SEBEP</h3>
          <p>Tarafların kontrolünde olmayan; tabii afetler, yangın, patlamalar, iç savaşlar, savaşlar,
          ayaklanmalar, halk hareketlenmeleri, seferberlik ilanı, grev, lokavt ve salgın hastalıklar, altyapı ve
          internet arızaları, elektrik kesintisi gibi sebeplerden (aşağıda birlikte "Mücbir Sebep” olarak
          anılacaktır.) dolayı sözleşmeden doğan yükümlülükler taraflarca ifa edilemez hale gelirse, taraflar
          bundan sorumlu değildir. Bu sürede Taraflar’ın işbu Sözleşme’den doğan hak ve yükümlülükleri
          askıya alınır.</p>

          <h3>12. SÖZLEŞMENİN BÜTÜNLÜĞÜ VE UYGULANABİLİRLİK</h3>
          <p>İşbu sözleşme şartlarından biri, kısmen veya tamamen geçersiz hale gelirse, sözleşmenin geri
          kalanı geçerliliğini korumaya devam eder.</p>

          <h3>13. SÖZLEŞMEDE YAPILACAK DEĞİŞİKLİKLER</h3>
          <p>ARACI, dilediği zaman sitede sunulan hizmetleri ve işbu sözleşme şartlarını kısmen veya
          tamamen değiştirebilir. Değişiklikler sitede yayınlandığı tarihten itibaren geçerli olacaktır.
          Değişiklikleri takip etmek ÜYELERİN’in sorumluluğundadır. ÜYELER, sunulan hizmetlerden
          yararlanmaya devam etmekle bu değişiklikleri de kabul etmiş sayılır.</p>

          <h3>14. TEBLİGAT</h3>
          <p>İşbu Sözleşme ile ilgili taraflara gönderilecek olan tüm bildirimler, ÜYELER’in bilinen e-posta
          adresi ve kullanıcının üyelik formunda belirttiği e-posta ve gsm adresi vasıtasıyla yapılacaktır.
          üyeler, üye olurken belirttiği adresin geçerli tebligat adresi olduğunu, değişmesi durumunda 5 gün
          içinde yazılı olarak diğer tarafa bildireceğini, aksi halde bu adrese yapılacak tebligatların geçerli
          sayılacağını kabul eder.</p>

          <h3>15. DELİL SÖZLEŞMESİ</h3>
          <p>Taraflar arasında işbu sözleşme ile ilgili işlemler için çıkabilecek her türlü uyuşmazlıklarda
          Taraflar’ın defter, kayıt ve belgeleri ile ve bilgisayar kayıtları ve faks kayıtları 6100 sayılı Hukuk
          Muhakemeleri Kanunu uyarınca delil olarak kabul edilecek olup, kullanıcı bu kayıtlara itiraz
          etmeyeceğini kabul eder.</p>

          <h3>16. UYUŞMAZLIKLARIN ÇÖZÜMÜ</h3>
          <p>İşbu Sözleşme’nin uygulanmasından veya yorumlanmasından doğacak her türlü uyuşmazlığın
          çözümünde Ankara (Merkez) Adliyesi Mahkemeleri ve İcra Daireleri tam yetkilidir.</p>

          <h3>17. SÖZLEŞME FESHİ</h3>
          <p>İşbu sözleşme üyenin üyeliğini iptal etmesi veya Aracı tarafından üyeliğinin iptal edilmesine kadar
          yürürlükte kalacaktır. ARACI , Satıcı/Alıcı üyenin üyelik sözleşmesinin herhangi bir hükmünü ihlal
          etmesi durumunda üyenin üyeliğini iptal ederek sözleşmeyi tek taraflı olarak feshedebilecektir.</p>

                        </div>
                        <div className={this.state.mesafeliicerik} id="mesafelisatissozlesmesi">
                          <h4>{this.state.baslik}</h4>
                          <hr/>
                          
          <h3>1.TARAFLAR</h3>
          İşbu Sözleşme aşağıdaki taraflar arasında aşağıda belirtilen hüküm ve şartlar çerçevesinde imzalanmıştır.
          A. COBEE BİLİŞİM VE ve MÜHENDİSLİK ARACILIK HİZMETLERİ A.Ş /www.karsikomsum.com ;
          (sözleşmede bundan sonra "ARACI" olarak anılacaktır)
          B.‘ALICI ÜYE’ ; (sözleşmede bundan sonra "ALICI" olarak anılacaktır)
          C.‘SATICI ÜYE’ ; (sözleşmede bundan sonra "SATICI" olarak anılacaktır)
          AD- SOYAD:
          ADRES:
          İş bu sözleşmeyi kabul etmekle ALICI ve SATICI, sözleşme konusu siparişi onayladığı takdirde sipariş konusu
          bedeli ve varsa kargo ücreti, vergi gibi belirtilen ek ücretleri ödeme yükümlülüğü altına gireceğini ve bu konuda
          bilgilendirildiğini peşinen kabul eder.
          <h3>2.TANIMLAR</h3>
          İşbu sözleşmenin uygulanmasında ve yorumlanmasında aşağıda yazılı terimler karşılarındaki yazılı açıklamaları
          ifade edeceklerdir.
          BAKAN: Gümrük ve Ticaret Bakanı’nı,
          BAKANLIK: Gümrük ve Ticaret Bakanlığı’nı,
          KANUN: 6502 sayılı Tüketicinin Korunması Hakkında Kanun’u,
          YÖNETMELİK: Mesafeli Sözleşmeler Yönetmeliği’ni (RG:27.11.2014/29188)
          HİZMET: Bir ücret veya menfaat karşılığında yapılan ya da yapılması taahhüt edilen mal sağlama dışındaki her
          türlü tüketici işleminin konusunu ,
          SATICI: Ticari veya mesleki faaliyetleri kapsamında tüketiciye mal sunan veya mal sunan adına veya hesabına
          hareket eden şirketi veya yararlanan gerçek ya da tüzel kişiyi
          ALICI: Bir mal veya hizmeti ticari veya mesleki olmayan amaçlarla edinen, kullanan veya yararlanan gerçek ya da
          tüzel kişiyi,
          SİTE: ARACI’ ya ait internet sitesini,
          SİPARİŞ VEREN: Bir mal veya hizmeti ARACI’ ya ait internet sitesi üzerinden talep eden gerçek ya da tüzel kişiyi,
          TARAFLAR: ARACI, SATICI ve ALICI’yı,
          SÖZLEŞME: ARACI, SATICI ve ALICI arasında akdedilen işbu sözleşmeyi,
          MAL: Alışverişe konu olan taşınır eşyayı ve elektronik ortamda kullanılmak üzere hazırlanan yazılım, ses, görüntü
          ve benzeri gayri maddi malları ifade eder.
          <h3>3.KONU</h3>
          İşbu Sözleşme, SATICI'nın Yükümlü olduğu, ALICI’nın, ARACI’ ya ait internet sitesi üzerinden elektronik
          ortamda siparişini verdiği aşağıda nitelikleri ve satış fiyatı belirtilen ürünün satışı ve teslimi ile ilgili olarak 6502
          sayılı Tüketicinin Korunması Hakkında Kanun ve Mesafeli Sözleşmelere Dair Yönetmelik hükümleri gereğince
          tarafların hak ve yükümlülüklerini düzenler.
          Listelenen ve sitede ilan edilen fiyatlar satış fiyatıdır. İlan edilen fiyatlar ve vaatler güncelleme yapılana ve
          değiştirilene kadar geçerlidir. Süreli olarak ilan edilen fiyatlar ise belirtilen süre sonuna kadar geçerlidir.

          <h3>4. ARACI BİLGİLERİ</h3>
          Ünvanı : COBEE BİLİŞİM VE ve MÜHENDİSLİK ARACILIK HİZMETLERİ A.Ş /www.karsikomsum.com
          Adres : Beytepe Mah. 1796.Cad. 14/1 06800 Bilkent Çankaya/ANKARA
          Telefon : +90 850 840 494
          E-posta satis@karsikomsum.com
          5.
          ALICI BİLGİLERİ
          Teslim edilecek kişi :
          Teslimat Adresi
          :
          Telefon
          :
          Eposta/kullanıcı adı :

          5-A. SATICI BİLGİLERİ
          Ünvanı
          :
          Telefonu
          :
          Adresi
          :
          E-posta/kullanıcı adı :

          <h3>7.SÖZLEŞME KONUSU ÜRÜN/ÜRÜNLER BİLGİLERİ</h3>
          1. Malın /Ürün/Ürünlerin/ Hizmetin temel özelliklerini (türü, miktarı, marka/modeli, rengi, adedi)
          ARACI’ya ait internet sitesinde yayınlanmaktadır. Satıcı tarafından kampanya düzenlenmiş ise ilgili ürünün
          temel özelliklerini kampanya süresince inceleyebilirsiniz. Kampanya tarihine kadar geçerlidir.
          7.2. Listelenen ve sitede ilan edilen fiyatlar satış fiyatıdır. İlan edilen fiyatlar ve vaatler güncelleme yapılana ve
          değiştirilene kadar geçerlidir. Süreli olarak ilan edilen fiyatlar ise belirtilen süre sonuna kadar geçerlidir. 7.3.
          Sözleşme konusu mal ya da hizmetin tüm vergiler dâhil satış fiyatı aşağıda gösterilmiştir.
          Ürün AçıklamasıAdet Birim FiyatıAra Toplam
          (KDV Dahil)
          Kargo Tutarı
          Toplam :
          Ödeme Şekli ve Planı
          Teslimat Adresi
          Teslim Edilecek kişi
          Fatura Adresi
          Sipariş Tarihi
          Teslimat tarihi
          Teslim şekli
          7.4. Ürün sevkiyat masrafı olan kargo ücreti aksi belirtilmediği sürece ALICI tarafından ödenecektir.
          <h3>8. FATURA BİLGİLERİ</h3>
          Ad/Soyad/Unvan
          Adres
          Telefon
          Faks
          Eposta/kullanıcı adı
          Fatura teslim : Fatura E-arşiv olarak ALICI'nın belirtmekle yükümlü olduğu güncel e-mail adresine ya da
          sipariş teslimatı sırasında fatura adresine sipariş ile birlikte teslim edilecektir.

          <h3>9. GENEL HÜKÜMLER</h3>
          9.1. ALICI, ARACI’ya ait internet sitesinde sözleşme konusu ürünün temel nitelikleri, satış fiyatı ve ödeme
          şekli ile teslimata ilişkin ön bilgileri okuyup, bilgi sahibi olduğunu, elektronik ortamda gerekli teyidi verdiğini
          kabul, beyan ve taahhüt eder. ALICI’nın; Ön Bilgilendirmeyi elektronik ortamda teyit etmesi, mesafeli satış
          sözleşmesinin kurulmasından evvel, SATICI tarafından ALICI' ya verilmesi gereken adresi, siparişi verilen
          ürünlere ait temel özellikleri, ürünlerin vergiler dâhil fiyatını, ödeme ve teslimat bilgilerini de doğru ve
          eksiksiz olarak edindiğini kabul, beyan ve taahhüt eder.
          9.2. Sözleşme konusu her bir ürün, 30 günlük yasal süreyi aşmamak kaydı ile ALICI' nın yerleşim yeri
          uzaklığına bağlı olarak internet sitesindeki ön bilgiler kısmında belirtilen süre zarfında ALICI veya ALICI’nın
          gösterdiği adresteki kişi ve/veya kuruluşa teslim edilir. Bu süre içinde ürünün ALICI’ya teslim edilememesi
          durumunda, ALICI’nın sözleşmeyi feshetme hakkı saklıdır.
          9.3. SATICI, Sözleşme konusu ürünü eksiksiz, siparişte belirtilen niteliklere uygun ve varsa garanti belgeleri,
          kullanım kılavuzları işin gereği olan bilgi ve belgeler ile teslim etmeyi, her türlü ayıptan arî olarak yasal
          mevzuat gereklerine göre sağlam, standartlara uygun bir şekilde işi doğruluk ve dürüstlük esasları dâhilinde
          ifa etmeyi, hizmet kalitesini koruyup yükseltmeyi, işin ifası sırasında gerekli dikkat ve özeni göstermeyi,
          ihtiyat ve öngörü ile hareket etmeyi kabul, beyan ve taahhüt eder.
          9.4. SATICI, sözleşmeden doğan ifa yükümlülüğünün süresi dolmadan ALICI’yı bilgilendirmek ve açıkça
          onayını almak suretiyle eşit kalite ve fiyatta farklı bir ürün tedarik edebilir.
          9.5. ARACI, sipariş konusu ürün veya hizmetin yerine getirilmesinin imkânsızlaşması halinde sözleşme
          konusu yükümlülüklerini yerine getiremezse, bu durumu, öğrendiği tarihten itibaren 3 gün içinde yazılı
          olarak tüketiciye bildireceğini, 14 günlük süre içinde toplam bedeli ALICI’ya iade edeceğini kabul, beyan ve
          taahhüt eder.
          9.6. ALICI, Sözleşme konusu ürünün teslimatı için işbu Sözleşme’yi elektronik ortamda teyit edeceğini,
          herhangi bir nedenle sözleşme konusu ürün bedelinin ödenmemesi ve/veya banka kayıtlarında iptal edilmesi
          halinde, SATICI’nın sözleşme konusu ürünü teslim yükümlülüğünün sona ereceğini kabul, beyan ve taahhüt
          eder.
          9.7. ALICI, Sözleşme konusu ürünün ALICI veya ALICI’nın gösterdiği adresteki kişi ve/veya kuruluşa
          tesliminden sonra ALICI'ya ait kredi kartının yetkisiz kişilerce haksız kullanılması sonucunda sözleşme
          konusu ürün bedelinin ilgili banka veya finans kuruluşu tarafından SATICI'ya ödenmemesi halinde, ALICI
          Sözleşme konusu ürünü 3 gün içerisinde nakliye gideri SATICI’ya ait olacak şekilde SATICI’ya iade edeceğini
          kabul, beyan ve taahhüt eder.
          9.8. SATICI, tarafların iradesi dışında gelişen, önceden öngörülemeyen ve tarafların borçlarını yerine
          getirmesini engelleyici ve/veya geciktirici hallerin oluşması gibi mücbir sebepler halleri nedeni ile sözleşme
          konusu ürünü süresi içinde teslim edemez ise, durumu ARACI ve ALICI'ya bildireceğini kabul, beyan ve
          taahhüt eder. ALICI da siparişin iptal edilmesini, sözleşme konusu ürünün varsa emsali ile değiştirilmesini
          ve/veya teslimat süresinin engelleyici durumun ortadan kalkmasına kadar ertelenmesini ARACI ya da
          SATICI’dan talep etme hakkını haizdir. ALICI tarafından siparişin iptal edilmesi halinde ALICI’nın nakit ile
          yaptığı ödemelerde, ürün tutarı 14 gün içinde kendisine nakden ve defaten ödenir. ALICI’nın kredi kartı ile
          yaptığı ödemelerde ise, ürün tutarı, siparişin ALICI tarafından iptal edilmesinden sonra 14 gün içerisinde
          ilgili bankaya iade edilir. ALICI, ARACI tarafından kredi kartına iade edilen tutarın banka tarafından ALICI
          hesabına yansıtılmasına ilişkin ortalama sürecin 2 ile 3 haftayı bulabileceğini, bu tutarın bankaya iadesinden
          sonra ALICI’nın hesaplarına yansıması halinin tamamen banka işlem süreci ile ilgili olduğundan, ALICI, olası
          gecikmeler için ARACI’yı sorumlu tutamayacağını kabul, beyan ve taahhüt eder.
          9.9. ARACININ, ALICI tarafından siteye kayıt formunda belirtilen veya daha sonra kendisi tarafından
          güncellenen adresi, e-posta adresi, sabit ve mobil telefon hatları ve diğer iletişim bilgileri üzerinden mektup,
          e-posta, SMS, telefon görüşmesi ve diğer yollarla iletişim, pazarlama, bildirim ve diğer amaçlarla ALICI’ya
          ulaşma hakkı bulunmaktadır. ALICI, işbu sözleşmeyi kabul etmekle ARACI’nın kendisine yönelik yukarıda
          belirtilen iletişim faaliyetlerinde bulunabileceğini kabul ve beyan etmektedir.

          9.10. ALICI, sözleşme konusu mal/hizmeti teslim almadan önce muayene edecek; ezik, kırık, ambalajı yırtılmış
          vb. hasarlı ve ayıplı mal/hizmeti kargo şirketinden teslim almayacaktır. Teslim alınan mal/hizmetin hasarsız ve
          sağlam olduğu kabul edilecektir. Teslimden sonra mal/hizmetin özenle korunması borcu, ALICI’ya aittir. Cayma
          hakkı kullanılacaksa mal/hizmet kullanılmamalıdır. Fatura iade edilmelidir.
          9.11. ALICI ile sipariş esnasında kullanılan kredi kartı hamilinin aynı kişi olmaması veya ürünün ALICI’ya
          tesliminden evvel, siparişte kullanılan kredi kartına ilişkin güvenlik açığı tespit edilmesi halinde, ARACI, kredi
          kartı hamiline ilişkin kimlik ve iletişim bilgilerini, siparişte kullanılan kredi kartının bir önceki aya ait ekstresini
          yahut kart hamilinin bankasından kredi kartının kendisine ait olduğuna ilişkin yazıyı ibraz etmesini ALICI’dan
          talep edebilir. ALICI’nın talebe konu bilgi/belgeleri temin etmesine kadar geçecek sürede sipariş dondurulacak
          olup, mezkur taleplerin 24 saat içerisinde karşılanmaması halinde ise ARACI, siparişi iptal etme hakkını haizdir.
          9.12. ALICI, SATICI’ya ait internet sitesine üye olurken verdiği kişisel ve diğer sair bilgilerin gerçeğe uygun
          olduğunu, SATICI’nın bu bilgilerin gerçeğe aykırılığı nedeniyle uğrayacağı tüm zararları, SATICI’nın ilk bildirimi
          üzerine derhal, nakden ve defaten tazmin edeceğini beyan ve taahhüt eder.
          9.13. ALICI, ARACI’ya ait internet sitesini kullanırken yasal mevzuat hükümlerine riayet etmeyi ve bunları ihlal
          etmemeyi baştan kabul ve taahhüt eder. Aksi takdirde, doğacak tüm hukuki ve cezai yükümlülükler tamamen ve
          münhasıran ALICI’yı bağlayacaktır.
          9.14. ALICI, ARACI’ya ait internet sitesini hiçbir şekilde kamu düzenini bozucu, genel ahlaka aykırı, başkalarını
          rahatsız ve taciz edici şekilde, yasalara aykırı bir amaç için, başkalarının maddi ve manevi haklarına tecavüz
          edecek şekilde kullanamaz. Ayrıca, üye başkalarının hizmetleri kullanmasını önleyici veya zorlaştırıcı faaliyet
          (spam, virus, truva atı, vb.) işlemlerde bulunamaz.AKSİ TAKTİRDE ÜYELİĞİ SONLANDIRILIR.
          9.15. ARACI’ya ait internet sitesinin üzerinden, SATICI’nın kendi kontrolünde olmayan ve/veya başkaca üçüncü
          kişilerin sahip olduğu ve/veya işlettiği başka web sitelerine ve/veya başka içeriklere link verilebilir. Bu linkler
          ALICI’ya yönlenme kolaylığı sağlamak amacıyla konmuş olup herhangi bir web sitesini veya o siteyi işleten kişiyi
          desteklememekte ve Link verilen web sitesinin içerdiği bilgilere yönelik herhangi bir garanti niteliği
          taşımamaktadır.
          9.16. İşbu sözleşme içerisinde sayılan maddelerden bir ya da birkaçını ihlal eden üye işbu ihlal nedeniyle cezai ve
          hukuki olarak şahsen sorumlu olup, ARACI’yı bu ihlallerin hukuki ve cezai sonuçlarından ari tutacaktır. Ayrıca;
          işbu ihlal nedeniyle, olayın hukuk alanına intikal ettirilmesi halinde, ARACI’nın üyeye karşı üyelik sözleşmesine
          uyulmamasından dolayı tazminat talebinde bulunma hakkı saklıdır.
          <h3>10. CAYMA HAKKI</h3>
          10.1. ALICI; mesafeli sözleşmenin mal satışına ilişkin olması durumunda, ürünün kendisine veya gösterdiği
          adresteki kişi/kuruluşa teslim tarihinden itibaren 14 (on dört) gün içerisinde, ARACI ve SATICI’ya bildirmek
          şartıyla hiçbir hukuki ve cezai sorumluluk üstlenmeksizin ve hiçbir gerekçe göstermeksizin malı reddederek
          sözleşmeden cayma hakkını kullanabilir. Hizmet sunumuna ilişkin mesafeli sözleşmelerde ise, bu süre
          sözleşmenin imzalandığı tarihten itibaren başlar. Cayma hakkı süresi sona ermeden önce, tüketicinin onayı ile
          hizmetin ifasına başlanan hizmet sözleşmelerinde cayma hakkı kullanılamaz. Cayma hakkının kullanımından
          kaynaklanan masraflar SATICI’ ya aittir. ALICI, iş bu sözleşmeyi kabul etmekle, cayma hakkı konusunda
          bilgilendirildiğini peşinen kabul eder.
          10.2. Cayma hakkının kullanılması için 14 (ondört) günlük süre içinde ARACI' ya iadeli taahhütlü posta, faks veya
          eposta ile yazılı bildirimde bulunulması ve ürünün işbu sözleşmede düzenlenen "Cayma Hakkı Kullanılamayacak
          Ürünler" hükümleri çerçevesinde kullanılmamış olması şarttır. Bu hakkın kullanılması halinde,
          a) 3. kişiye veya ALICI’ ya teslim edilen ürünün faturası, (İade edilmek istenen ürünün faturası kurumsal ise, iade
          ederken kurumun düzenlemiş olduğu iade faturası ile birlikte gönderilmesi gerekmektedir. Faturası kurumlar
          adına düzenlenen sipariş iadeleri İADE FATURASI kesilmediği takdirde tamamlanamayacaktır.)
          b) İade formu,
          c) İade edilecek ürünlerin kutusu, ambalajı, varsa standart aksesuarları ile birlikte eksiksiz ve hasarsız olarak
          teslim edilmesi gerekmektedir.
          d) ARACI, cayma bildiriminin kendisine ulaşmasından itibaren en geç 20 günlük süre içerisinde toplam bedeli ve
          ALICI’yı borç altına sokan belgeleri ALICI’ ya iade etmek ve 20 günlük süre içerisinde malı iade almakla
          yükümlüdür.

          e) ALICI’ nın kusurundan kaynaklanan bir nedenle malın değerinde bir azalma olursa veya iade imkânsızlaşırsa
          ALICI kusuru oranında ARACI ve SATICI’ nın zararlarını tazmin etmekle yükümlüdür. Ancak cayma hakkı
          süresi içinde ürünün usulüne uygun kullanılması şartıyla meydana gelecek değişiklik ve bozulmalardan ARACI
          Sorumlu değildir.
          f) Cayma hakkının kullanılması nedeniyle ARACI ve SATICI tarafından düzenlenen kampanya limit tutarının
          altına düşülmesi halinde kampanya kapsamında faydalanılan indirim miktarı iptal edilir.
          <h3>11. CAYMA HAKKI KULLANILAMAYACAK ÜRÜNLER</h3>
          ALICI’nın isteği veya açıkça kişisel ihtiyaçları doğrultusunda hazırlanan ve geri gönderilmeye müsait olmayan,
          iç giyim alt parçaları, mayo ve bikini altları, makyaj malzemeleri, tek kullanımlık ürünler, çabuk bozulma
          tehlikesi olan veya son kullanma tarihi geçme ihtimali olan mallar, ALICI’ya teslim edilmesinin ardından ALICI
          tarafından ambalajı açıldığı takdirde iade edilmesi sağlık ve hijyen açısından uygun olmayan ürünler, t eslim
          edildikten sonra başka ürünlerle karışan ve doğası gereği ayrıştırılması mümkün olmayan ürünler, Abonelik
          sözleşmesi kapsamında sağlananlar dışında, gazete ve dergi gibi süreli yayınlara ilişkin mallar, Elektronik
          ortamda anında ifa edilen hizmetler veya tüketiciye anında teslim edilen gayrimaddi mallar, ile ses veya görüntü
          kayıtlarının, kitap, dijital içerik, yazılım programlarının, veri kaydedebilme ve veri depolama cihazlarının,
          bilgisayar sarf malzemelerinin, ambalajının ALICI tarafından açılmış olması halinde iadesi Yönetmelik gereği
          mümkün değildir. Ayrıca Cayma hakkı süresi sona ermeden önce, tüketicinin onayı ile i fasına başlanan
          hizmetlere ilişkin cayma hakkının kullanılması da Yönetmelik gereği mümkün değildir. Kozmetik ve kişisel
          bakım ürünleri, iç giyim ürünleri, mayo, bikini, kitap, kopyalanabilir yazılım ve programlar, DVD, VCD, CD ve
          kasetler ile kırtasiye sarf malzemeleri (toner, kartuş, şerit vb.) iade edilebilmesi için ambalajlarının açılmamış,
          denenmemiş, bozulmamış ve kullanılmamış olmaları gerekir.
          <h3>12. TEMERRÜT HALİ VE HUKUKİ SONUÇLARI</h3>
          ALICI, ödeme işlemlerini kredi kartı ile yaptığı durumda temerrüde düştüğü takdirde, kart sahibi banka ile
          arasındaki kredi kartı sözleşmesi çerçevesinde faiz ödeyeceğini ve bankaya karşı sorumlu olacağını kabul, beyan
          ve taahhüt eder. Bu durumda ilgili banka hukuki yollara başvurabilir; doğacak masrafları ve vekâlet ücretini
          ALICI’dan talep edebilir ve her koşulda ALICI’nın borcundan dolayı temerrüde düşmesi halinde, ALICI,
          borcun gecikmeli ifasından dolayı ARACI’nın uğradığı zarar ve ziyanını ödeyeceğini kabul, beyan ve taahhüt
          eder
          <h3>13. YETKİLİ MAHKEME</h3>

          İşbu sözleşmeden doğan uyuşmazlıklarda şikayet ve itirazlar, aşağıdaki kanunda belirtilen parasal sınırlar
          dâhilinde Ankara tüketici Hakem Heyeti/ Ankara tüketici Mahkemeleri'ne yapılacaktır. Parasal sınıra ilişkin
          bilgiler aşağıdadır:
          01/01/2017 tarihinden itibaren geçerli olmak üzere, 2020 yılı için tüketici hakem heyetlerine yapılacak
          başvurularda değeri:
          a) 2.400 (iki bin dört yüz) Türk Lirasının altında bulunan uyuşmazlıklarda ilçe tüketici hakem heyetleri,
          b) Büyükşehir statüsünde olan illerde 2.400 (iki bin dört yüz) Türk Lirası ile 3.610 (üç bin altı yüz on) Türk
          Lirası arasındaki uyuşmazlıklarda il tüketici hakem heyetleri,
          c) Büyükşehir statüsünde olmayan illerin merkezlerinde 3.610 (üç bin altı yüz on) Türk Lirasının altında
          bulunan uyuşmazlıklarda il tüketici hakem heyetleri,
          ç) Büyükşehir statüsünde olmayan illere bağlı ilçelerde 2.400 (iki bin dört yüz) Türk Lirası ile 3.610 (üç bin altı
          yüz on) Türk Lirası arasındaki uyuşmazlıklarda il tüketici hakem heyetleri görevli kılınmışlardır.
          İşbu Sözleşme ticari amaçlarla yapılmaktadır.
          <h3>14. YÜRÜRLÜK</h3>
          ALICI, Site üzerinden verdiği siparişe ait ödemeyi gerçekleştirdiğinde işbu sözleşmenin tüm şartlarını kabul
          etmiş sayılır. ARACI, siparişin gerçekleşmesi öncesinde işbu sözleşmenin sitede ALICI tarafından okunup
          kabul edildiğine dair onay alacak şekilde gerekli yazılımsal düzenlemeleri yapmakla yükümlüdür.

                          
                        </div>
                        <div className={this.state.gizlilikicerik} id="gizliliksozlesmesi">
                          <h4>{this.state.baslik}</h4>
                          <hr/>
                          <h3>İŞBU GİZLİLİK SÖZLEŞMESİ</h3>
          <p>“Sözleşme” ÜYELİK tarihinde yürürlüğe girecek (yürürlük tarihi) ve Üyelik süresi boyunca aşağıda yer alan
          taraflar arasında akdedilerek hükümlerini doğurmaya başlamıştır.</p>
          <h3>TARAFLAR</h3>
          <p>Bir tarafta, Ankara’da kurulmuş ve tescilli merkezi Ankara’da bulunan bir şirket olan COBEE BİLİŞİM VE ve
          MÜHENDİSLİK ARACILIK HİZMETLERİ A.Ş /www.karsikomsum.com (bundan böyle ŞİRKET olarak anılacaktır.)</p>
          <p>Diğer tarafta, www.karsikomsum.com’da Üyeliği Bulunan SATICI ÜYE (bundan böyle ÜYE 1 olarak anılacaktır.)</p>
          <p>Diğer tarafta, www.karsikomsum.com’da Üyeliği Bulunan ALICI ÜYE (bundan böyle ÜYE 2 olarak anılacaktır.)</p>
          <h3>GİRİŞ</h3>
          <p>ŞİRKET , ÜYE 1 ve ÜYE 2'nin KİŞİSEL VERİLERİN KULLANILMASI VE İŞLENMESİ amaçlarına yönelik olarak
          teknik ve tescilli bilgilerin paylaşılmasını kabul etmişlerdir.</p>
          <p>Taraflar birbirileri ile olan temasları sırasında birbirlerine Madde 1’de açıklanan ve kamuya açıklanmasını, umumi
          bilgi haline gelmesini, üçüncü kişilere ifşa edilmesini ve kullanımına izin verilmesini istemedikleri “Gizli Bilgi”yi
          açıklayabilirler ve; Bu sebeple, işbu sözleşmenin ayrılmaz bir parçasını teşkil eden yukarıdaki giriş bölümü
          uyarınca, Taraflar aşağıdaki hususlarda mutabakata varmışlardır:</p>
          <h3>GİZLİ BİLGİ</h3>
          <p>1. Gizli Bilgi kamuya açıklanmayan ve Taraflara da aralarında gerçekleştirecekleri bir temas ya da anlaşma gereği
          açıklanacak olan, tüm veri, örnek, teknik bilgi, ticarileştirme, araştırma stratejisi, ticari sırlar ve know-how da dahil
          tüm bilgiler olarak tanımlanmaktadır.</p>
          <p>1.1. Gizli Bilgi herhangi bir sınırlama olmaksızın aşağıdakileri kapsamaktadır:Amaçla bağlantılı
          olarak diğer Tarafa açıklanan yazılı ve sözlü tüm bilgi, fikir, tahminler;</p>
          – Taraflara arasındaki konuşmalar, tartışmalar, görüşmeler ya da toplantılar ve yazışmalar ile sözlü olarak mübadele
          edilen tüm bilgiler;<br/>
          – Her iki tarafça hazırlanmış tüm analiz, derleme, çalışma, teklif, ve diğer belgeler;<br/>
          – Tüm ticari anlaşmalar veya taraflar arasında akdedilen anlaşmalar, gizli bilgi alışverişini içeren sözleşmeler<br/>
          – Şirket satış ve şirket ortaklık sözleşmeleri ile diğer ilgili sözleşmeler<br/><br/>
          <p>1.2. Bununla birlikte, Taraflardan her biri aşağıdaki hallerde Gizli Bilgiyi tamamen ya da kısmen ifşa edebilir ya da
          kullanabilir:</p>
          – Gizli bilginin işbu sözleşmenin ihlali veya kusur dışındaki bir sebebe binaen kamunun bilgisine dahil olması;<br/>
          – Gizli bilgiyi alan tarafın söz konusu bilgiye diğer tarafça ifşa edilmeden önce sahip olduğunu kanıtlaması
          durumunda;<br/>
          – Bilginin; alan tarafın diğer taraftan alınan söz konusu Gizli Bilgiye doğrudan ya da dolaylı olarak erişme yetkisi
          olmayan çalışanları veya temsilcileri tarafından bağımsız olarak geliştirilmesi durumunda;<br/>
          – Taraflardan birinin, yetkili mahkemenin veya resmi ya da idari makamın kararı, yürürlükte bulunan kanun veya
          yönetmelik gereği ifşa etmekle yükümlü olması durumunda; bunun için, bu tür bir ifşanın önlenmesi amacıyla gerekli
          tüm yasal ve makul önlemlerin alınmış olması ve bilginin lehine tescil edildiği Tarafa ifşa ile yükümlü olan tarafın
          ifşasından önce uygun bir koruyucu ihtiyati tedbire başvurmasına imkan verecek kadar yeterli bir süre içinde ihbarda
          bulunulması gerekmektedir.<br/><br/>

          <h3>GİZLİLİĞE İLİŞKİN YÜKÜMLÜLÜKLER</h3>
          <p>2. İşbu Sözleşmenin imzalanması ile Taraflardan her biri Gizli nitelikteki tüm bilgileri kesinlikle özel ve gizli tutmayı,
          bunu bir sır saklama yükümü olarak addetmeyi ve gizliliğin sağlanması ve sürdürülmesi, Gizli Bilginin veya herhangi bir
          kısmının kamu alanına girmesini veya ifşa eden tarafın yazlı muvafakatini gerektiren bilgiyi alan tarafın çalışanları hariç
          üçüncü bir kişiye ifşasını önlemek için gerekli tüm tedbirleri almayı ve gerekli özeni göstermeyi taahhüt etmişlerdir.</p>
          <p>2.1. Bununla birlikte, Taraflardan her biri işbu Sözleşme ile açıkça:</p>
          <p>a) Gizli Bilgiyi her ne sebeple olursa olsun, doğrudan ya da dolaylı olarak kendisinin ya da herhangi bir üçüncü kişinin
          yararına kullanmamayı, ifşa eden Tarafça izin verilen amaç dışındaki bir amaç için kullanılmasına izin vermemeyi,</p>
          <p>b) Hiçbir Gizli Bilgiyi, herhangi bir üçüncü kişiye, firmaya, acentaya veya kuruma açıklamamayı, rapor etmemeyi,
          yayınlamamayı veya ifşa etmemeyi veyahut işbu maddenin hangi şekilde olursa olsun böyle bir ifşadan kaçınmak için (c)
          fıkrasında belirtilenler hariç gerekli tüm hukuki ya da diğer tedbirleri alma</p>
          <p>c) Gizli Bilgiyi katı bir “bilinmesi gereklilik arz etme” temelinde ancak ifşa eden Tarafın açık yazılı muvafakati üzerine
          çalışanlara, vekil veya temsilcilere, bunların da en az işbu Sözleşmede yer alanlardan daha az katı olmayan yükümlülüklerle
          bağlı olmaları şartıyla açıklamayı taahhüt etmektedir.</p>
          <p>2.2. ÜYE veya onunla doğrudan ya da dolaylı olarak bağlantılı şirket veya kuruluşlar İşbu Sözleşmeyle:</p>
          <p>a) Hiçbir şekilde, ALICI/SATICI ÜYE’de çalışan veya geçmişte çalışmış veya İşbu Sözleşmenin hükümlerinin yürürlükte
          kaldığı süre içerisinde istifa eden veya işten çıkartılan işçileri kendisine transfer etmeyeceğini</p>
          <p>b) Hiçbir durumda ŞİRKET’in yazılı izni olmaksızın teknoloji transfer etmeyeceğini
          kabul ve taahhüt eder.</p>
          <h3>GİZLİ BİLGİNİN KOPYALANMASI</h3>
          <p>Taraflardan her biri, açıkça, Gizli Bilginin ilgili tarafın önceden vereceği yazılı muvafakati olmaksızın tamamen veya kısmen
          kopyalanmayacağını taahhüt eder.</p>
          <h3>GİZLİ BİLGİNİN İADE EDİLMESİ</h3>
          <p>4. Taraflardan her biri, Sözleşmenin sona erdiği durumlarda, diğer yükümlülüklere halel gelmeksizin İşbu Sözleşme gereği
          aşağıdaki yükümlülüklerle bağlı olduğunu kabul eder:</p>
          <p>4.1.Gizli Bilgi içeren tüm belgeler İfşa eden tarafa ya da ifşa eden tarafça belirlenen diğer kişilere iade edilecektir.</p>
          <p>Bu tür belgelerin suretleri, ve ifşa eden tarafça veya onun adına ya da temsilcileri veya paragraf 2.2’de belirtilen kendilerine
          Gizli Bilgi açıklanan kişiler tarafından hazırlanan rapor, derleme, analiz, yorumlar imha edilecektir.</p>
          <p>Gizli Bilginin kaydedildiği bilgisayarda bulunan ve gizli bilgiyi alan taraf ya da temsilcileri ya da yukarıda bahsi geçen
          paragraf 2.2’de belirtilen kişiler tarafından bulundurulan suretleri silinecektir.</p>
          <h3>TAZMİN</h3>
          <p>5. Taraflardan her biri, işbu Sözleşme tarafından kendilerine yüklenen yükümlülüklerden herhangi birinin ihlali halinde,
          Gizli Bilginin iade edilmesine rağmen diğer tarafın sırf yukarıda bahsedilen yükümlülüklerin ihlal edilmesi sebebiyle önemli
          bir zarara uğrayabileceğini kabul eder. Bu sebeple, taraflardan her biri diğer tarafın uğradığı böyle bir zararı tamamen
          tazmin edeceğini taahhüt eder.</p>
          <p>5.1. Taraflardan her biri, Gizliliğe yönelik her tür tehdidi önleme veya devam eden gizliliğin bilgiyi alan tarafça ihlalini
          hukuki yollarla durdurma hakkına sahip olduklarını ve ihlalde bulunan taraf aleyhine karar elde edilmesi durumunda, söz
          konusu taraf diğer tarafın avuktlık ücreti de dahil olmak üzere yargılama masraf ve giderlerini tazmin edeceğini kabul eder</p>
          <h3>GİZLİLİĞE GİRMEYEN HÜKÜMLER</h3>
          <p>6. İş bu sözleşmesi uyarınca, üyelerinin kendisinde kayıtlı elektronik posta adreslerine bilgilendirme mailleri ve cep
          telefonlarına bilgilendirme SMS'leri gönderme yetkisine sahip olmakla beraber, üye işbu üyelik sözleşmesini onaylamasıyla
          beraber bilgilendirme maillerinin elektronik posta adresine ve bilgilendirme SMS'lerinin cep telefonuna gönderilmesini
          kabul etmiş sayılacaktır.</p>
          <p>6.1.Şirket, sadece tanıtım, reklam, kampanya, promosyon, duyuru vb. pazarlama faaliyetleri kapsamında kullanılması ile
          sınırlı olmak üzere, Site’nin sahibi olan firmanın kendisine ait iletişim, portföy durumu ve demografik bilgilerini iştirakleri
          ya da bağlı bulunduğu grup şirketleri ile paylaşmasına muvafakat ettiğini kabul ve beyan eder. Bu kişisel bilgiler firma
          bünyesinde müşteri profili belirlemek, müşteri profiline uygun promosyon ve kampanyalar sunmak ve istatistiksel
          çalışmalar yapmak amacıyla kullanılabilecektir.</p>

          <h3>SÖZLEŞME, İŞLEM VE GÖRÜŞMELERİN GİZLİLİĞİ</h3>
          <p>7. İşlemler ve işbu Sözleşme hükümleri ve gerçekleşecek görüşmelerin içerikleri kesinlikle gizli tutulacaktır.</p>
          <p>7.1. Umuma yapılacak ilanlara yönelik olarak ilgili Taraflar karşılıklı olarak karar verecektir.</p>
          <h3>SÜRE</h3>
          <p>8. İşbu Sözleşme yukarıda belirtilen imza (ÜYELİK) tarihinde yürürlüğe girecek (yürürlük tarihi) ve Üyelik süresi
          boyunca yürürlükte kalacaktır.</p>
          <p>8.1. İşbu Sözleşmenin sona ermesine rağmen, gizlilik yükümlülükleri, alan tarafa açıklanan Gizli Bilgi gizlilik özelliğini
          kaybedene kadar devam edecektir.</p>
          <h3>UYGULANACAK HUKUK</h3>
          <p>Yetkili mahkeme</p>
          <p>9. İşbu Sözleşmeden doğan tüm uyuşmazlık, iddia ve çatışmalara yönelik olarak Ankara Mahkemeleri ve icra daireleri
          yetkilidir.</p>
          <h3>İHBARLAR</h3>
          <p>10. İşbu Sözleşme uyarınca yapılacak tüm ihbarlar yazılı olarak aşağıdaki usullerden birine göre yapılacaktır:</p>
          <p>a) Kurye veya iadeli taahhütlü mektup ile tarafların aşağıda belirtilen ihbar adreslerine gönderilecek;</p>
          <p>b) Fax, elektronik posta yolu ile gönderilerek, kurye veya iadeli taahhütlü mektup ile teyid edilecektir. Bu durumda ihbarlar
          nihai olarak adreste bulunan tarafından imzalanmış posta alındısının üstünde yer alan tarihten daha geç olmayan bir tarihte
          alınmış kabul edilecektir.</p>
          <h3>ÇEŞİTLİ HÜKÜMLER</h3>
          <p>11. Taraflardan her biri, işbu Sözleşmeyle birbirlerine açıkladıkları bilginin tam ve doğru olduğunu beyan ve taahhüt
          ederler. İşbu paragrafta belirtilen yükümlülüğü ihlal eden Taraf, diğer tarafa verdiği zarar ve ziyanı tazmin edecektir.</p>
          <p>11.1. İşbu Sözleşmenin hükümlerine yazılı bir sözleşme olmaksızın atıfta veya ilavede bulunulamaz.</p>
          <p>11.2. İşbu Sözleşmede yar alan taahhütler Tarafların doğrudan ya da dolaylı kontrolü altında bulunan ya da kontrolü altında
          bulunduğu şirket ve gruplar ve bunların vekil ve halefleri için de bağlayıcıdır.</p>


                        </div>
                        <div className={this.state.kvkkicerik}  id="khkkvecerezler">
                          <h4>{this.state.baslik}</h4>
                          <hr/>
                          Kişisel verilerin korunma ve Çerez Politikası
          <strong>COBEE BİLİŞİM VE VE MÜHENDİSLİK ARACILIK HİZMETLERİ A.Ş (karsikomsum)</strong> olarak, kullanıcılarımızın hizmetlerimizden güvenli ve eksiksiz şekilde faydalanmalarını sağlamak amacıyla sitemizi kullanan üyelerimizin gizliliğini korumak için çalışıyoruz. Bu doğrultuda, işbu meatvar Gizlilik Politikası (“Politika”), üyelerimizin kişisel verilerinin 6698 sayılı Kişisel Verilerin Korunması Kanunu (“Kanun”) ile tamamen uyumlu bir şekilde işlenmesi ve kullanıcılarımızı bu bağlamda bilgilendirmek amacıyla hazırlanmıştır. karsikomsum.com çerez politikası İşbu Politika’nın ayrılmaz parçasıdır.
          İşbu Politika’nın amacı, Karşıkomşumtarafından işletilmekte olan www.karsikomsum.com internet sitesi ile mobil uygulamanın (hepsi birlikte “Platform” olarak anılacaktır) işletilmesi sırasında Platform üyeleri/ziyaretçileri/kullanıcıları (hepsi birlikte “Veri Sahibi” olarak anılacaktır) tarafından Karşıkomşumile paylaşılan veya karsikomsum ’ın, Veri Sahibi’nin Platform’u kullanımı sırasında ürettiği kişisel verilerin kullanımına ilişkin koşul ve şartları tespit etmektir.
          <h3>Hangi Veriler İşlenmektedir?</h3>
          <p>Aşağıda Karşıkomşumtarafından işlenen ve Kanun uyarınca kişisel veri sayılan verilerin hangileri olduğu sıralanmıştır. Aksi açıkça belirtilmedikçe, işbu Politika kapsamında arz edilen hüküm ve koşullar kapsamında “kişisel veri” ifadesi aşağıda yer alan bilgileri kapsayacaktır.</p>
          <ul>
            <li>Kimlik Bilgisi</li>
            <li>İletişim Bilgisi</li>
            <li>Kullanıcı Bilgisi</li>
            <li>Kullanıcı İşlem Bilgisi</li>
            <li>İşlem Güvenliği Bilgisi</li>
            <li>Finansal Bilgi</li>
            <li>Pazarlama Bilgisi</li>
            <li>Talep/Şikayet Yönetimi Bilgisi</li>

          </ul>

          <p>Kişisel Verilerin Korunması Kanunu’nun 3. ve 7. maddeleri dairesince, geri döndürülemeyecek şekilde anonim hale getirilen veriler, anılan kanun hükümleri uyarınca kişisel veri olarak kabul edilmeyecek ve bu verilere ilişkin işleme faaliyetleri işbu Politika hükümleri ile bağlı olmaksızın gerçekleştirecektir.</p>
          <h3>Kişisel Veri İşleme Amaçları</h3>
          <p>karsikomsum , Veri Sahibi tarafından sağlanan kişisel verileri, üyelik kaydı ve hesabının oluşturulması ve buna ilişkin kayıtların tutulması, Veri Sahibi’nin Platform üzerinden sağlanan hizmetlerden faydalandırılması sistem hatalarının tespit edilerek performans takibinin yapılması ve Platform’un işleyişinin iyileştirilmesi, bakım ve destek hizmetleri ile yedekleme hizmetlerinin sunulması amaçları dahil olmak üzere Karşıkomşumtarafından sunulan ürün ve hizmetlerden ilgili kişileri faydalandırmak için gerekli çalışmaların iş birimleri tarafından yapılması ve ilgili iş süreçlerinin yürütülmesi ile bu ürün ve hizmetlerin ilgili kişilerin beğeni, kullanım alışkanlıkları ve ihtiyaçlarına göre özelleştirilerek ilgili kişilere önerilmesi ve tanıtılması için gerekli olan aktivitelerin planlanması ve icrası, Karşıkomşumtarafından yürütülen ticari faaliyetlerin gerçekleştirilmesi için ilgili iş birimleri tarafından gerekli çalışmaların yapılması ve buna bağlı iş süreçlerinin yürütülmesi, Karşıkomşumve iş ilişkisi içerisinde bulunduğu kişilerin hukuki, teknik ve ticari-iş güvenliğinin temini ile karsikomsum ’ın ticari ve/veya iş stratejilerinin planlanması ve icrası amaçlarıyla işlenebilecektir.
          Veri Sahiplerinin Açık Rızası Doğrultusunda İşlenecek Kişisel Veriler ve İşleme Amaçları
          Veri Sahibi’nin açık rızası kapsamında, karsikomsum , Veri Sahipleri’nin Platform üzerindeki hareketlerini takip ederek kullanıcı deneyiminin artırılması, istatistik oluşturulması, profilleme yapılması, doğrudan pazarlama ve yeniden pazarlama, Veri Sahibi’ne özel promosyon önerilerinin oluşturulması ve Veri Sahibi’ne iletilmesi ve bu kapsamda elde edilen verilerin her türlü reklam ve materyal içeriğinde kullanılması amacıyla veri işleyebilecek ve aşağıda anılan taraflarla bu verileri paylaşabilecektir.
          </p>
          <h3>Kişisel Verilerin Aktarımı:</h3>
          <p>
          karsikomsum, Veri Sahibi’ne ait kişisel verileri ve bu kişisel verileri kullanılarak elde ettiği yeni verileri, işbu Gizlilik Politikası ile belirlenen amaçların gerçekleştirilebilmesi için karsikomsum’ın hizmetlerinden faydalandığı üçüncü kişilere, söz konusu hizmetlerin temini amacıyla sınırlı olmak üzere aktarılabilecektir. karsikomsum, Veri Sahibi deneyiminin geliştirilmesi (iyileştirme ve kişiselleştirme dâhil), Veri Sahibi’nin güvenliğini sağlamak, hileli ya da izinsiz kullanımları tespit etmek, operasyonel değerlendirme araştırılması, Platform hizmetlerine ilişkin hataların giderilmesi ve işbu Gizlilik Politikası’nda yer alan amaçlardan herhangi birisini gerçekleştirebilmek için SMS gönderimi yapanlar da dahil olmak üzere dış kaynak hizmet sağlayıcıları, barındırma hizmet sağlayıcıları (hosting
          servisleri), hukuk büroları, araştırma şirketleri, çağrı merkezleri gibi üçüncü kişiler ile paylaşabilecektir.
          Kişisel veriler, Kanun’un 8. ve 9. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları çerçevesinde Cobee bilişim ve ve mühendislik aracılık hizmetleri a.ş. ve Cobee bilişim ve mühendislik aracılık hizmetleri a.ş ve Cobee Bilişim A.Ş Grup Şirketleri Şirket yetkilileri, Cobee Bilişim A.Ş ve hissedarları, iş ortaklarımız, tedarikçilerimiz, kanunen yetkili kamu kurum ve kuruluşları ile kanunen yetkili özel kurumlar ile paylaşılabilecek, bu amaçlarla sınırlı olarak Kanun m.9’da işaret edilen usul esaslar ile Kişisel Verileri Koruma Kurulu kararları çerçevesinde yurt dışına aktarılabilecektir.
          </p>
          <h3>Kişisel Verilerin Toplanma Yöntemi ve Hukuki Sebebi</h3>
          <p>Kişisel veriler, Platform üzerinden ve elektronik ortamda toplanmaktadır. Yukarıda belirtilen hukuki sebeplerle toplanan kişisel veriler 6698 sayılı Kanun’un 5. ve 6. maddelerinde ve bu Gizlilik Politikası’nda belirtilen amaçlarla işlenebilmekte ve aktarılabilmektedir.</p>

          <h3>Kişisel Veri Sahibinin Hakları</h3>
          Kanun’un 11. maddesi uyarınca veri sahipleri,
          <ul>
            <li>Kendileri ile ilgili kişisel veri işlenip işlenmediğini öğrenme, kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</li>
            <li>Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme, yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</li>
            <li>Kişisel verilerin eksik veya yanlış işlenmiş olması halinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
            <li>Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması halinde kişisel verilerin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
            <li>İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme ve kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması halinde zararın giderilmesini talep etme haklarına sahiptir.</li>
          </ul>


          Söz konusu hakların kullanımına ilişkin talepler, kişisel veri sahipleri tarafından www. karsikomsum.com adresinde yer alan 6698 sayılı Kanun Kapsamında Karşıkomşumtarafından hazırlanan Kişisel Verilerin İşlenmesi ve Korunmasına ilişkin Politika’da belirtilen yöntemlerle iletilebilecektir. karsikomsum, söz konusu talepleri otuz gün içerisinde sonuçlandıracaktır. karsikomsum’ın taleplere ilişkin olarak Kişisel Verileri Koruma Kurulu tarafından belirlenen (varsa) ücret tarifesi üzerinden ücret talep etme hakkı saklıdır.

          <h3>Çerez Politikası:</h3>
          <strong>COBEE BİLİŞİM VE VE MÜHENDİSLİK ARACILIK HİZMETLERİ A.Ş. (“karsikomsum”)</strong> olarak, kullanıcılarımızın hizmetlerimizden güvenli ve eksiksiz şekilde faydalanmalarını sağlamak amacıyla sitemizi kullanan kişilerin gizliliğini korumak için çalışıyoruz.
          Çoğu web sitesinde olduğu gibi, karsikomsum.com (“Site”) ile mobil uygulamanın (hepsi birlikte “Platform” olarak anılacaktır) ziyaretçilere kişisel içerik ve reklamlar göstermek, site içinde analitik faaliyetler gerçekleştirmek ve ziyaretçi kullanım alışkanlıklarını takip etmek amacıyla Çerezler kullanılmaktadır.
          İşbu Çerez Politakası karsikomsum.com Gizlilik Politikası’nın ayrılmaz bir parçasıdır.
          karsikomsum, bu Çerez Politikası’nı (“Politika”) Site’de hangi Çerezlerin kullanıldığını ve kullanıcıların bu konudaki tercihlerini nasıl yönetebileceğini açıklamak amacıyla hazırlamıştır. Karşıkomşumtarafından kişisel verilerinizin işlenmesine ilişkin daha detaylı bilgi için karsikomsum.com Gizlilik Politikası’nı incelemenizi tavsiye ederiz.
          <h3>Çerez (“Cookie”) Nedir?</h3>
          Çerezler, ziyaret ettiğiniz internet siteleri tarafından tarayıcılar aracılığıyla cihazınıza veya ağ sunucusuna depolanan küçük metin dosyalarıdır. Çerezler, ziyaret ettiğiniz web sitesiyle ilişkili sunucular tarafından oluşturulurlar. Böylelikle ziyaretçi aynı siteyi ziyaret ettiğinde sunucu bunu anlayabilir.
          Çerezler, ziyaretçilere ilişkin isim, cinsiyet veya adres gibi kişisel verileri içermezler. Çerezler konusunda daha detaylı bilgi için www.aboutcookies.org ve www.allaboutcookies.org adreslerini ziyaret edebilirisiniz.
          <h3>Hangi Çerezler Kullanılmaktadır?</h3>
          Çerezler, sahipleri, kullanım ömürleri ve kullanım amaçları açısında kategorize edilebilir:
          <ul>
            <li>Çerezi yerleştiren tarafa göre, Platform çerezleri ve üçüncü taraf Çerezler kullanılmaktadır. Platform çerezleri, Karşıkomşumtarafından oluşturulurken, üçüncü taraf çerezlerini Karşıkomşumile iş birlikteliği olan farklı firmalar yönetmektedir.</li>
            <li>Aktif olduğu süreye göre, oturum çerezleri ve kalıcı çerezler kullanılmaktadır. Oturum çerezleri ziyaretçinin Platform’u terk etmesiyle birlikte silinirken, kalıcı çerezler ise kullanım alanına bağlı olarak çeşitli sürelerle ziyaretçilerin cihazlarında kalabilmektedir.</li>
            <li>Kullanım amaçlarına göre, Platform’da teknik çerezler, doğrulama çerezleri, hedefleme/reklam çerezleri, kişiselleştirme çerezleri ve analitik çerezler kullanılmaktadır.</li>
          </ul>

          <h3>Neden Çerezler Kullanılmaktadır?</h3>
          Platform’da, Çerezler aşağıdaki amaçlar kapsamında kullanılmaktadır:
          <ul>
            <li>Platform’un çalışması için gerekli temel fonksiyonları gerçekleştirmek. Örneğin, Karşıkomşumüyelerinin, ürün sepetindeki ürünlerin ziyaretleri süresince kaybolmaması. Oturum açan üyelerin Platform’da farklı sayfaları ziyaret ederken tekrar şifre girmelerine gerek kalmaması.</li>
            <li>Platform’u analiz etmek ve Platform’un performansını arttırmak. Örneğin, Platform’un üzerinde çalıştığı farklı sunucuların entegrasyonu, Platform’u ziyaret edenlerin sayısının tespit edilmesi ve buna göre performans ayarlarının yapılması ya da ziyaretçilerin aradıklarını bulmalarının kolaylaştırılması.</li>
            <li>Platform’un işlevselliğini arttırmak ve kullanım kolaylığı sağlamak. Örneğin, Platform üzerinden üçüncü taraf sosyal medya mecralarına paylaşımda bulunmak, Platform’u ziyaret eden ziyaretçinin daha sonraki ziyaretinde kullanıcı adı bilgisinin ya da arama sorgularının hatırlanması</li>
            <li>Kişiselleştirme, hedefleme ve reklamcılık faaliyeti gerçekleştirmek. Örneğin, ziyaretçilerin görüntüledikleri sayfa ve ürünler üzerinden ziyaretçilerin ilgi alanlarıyla bağlantılı reklam gösterilmesi.</li>
          </ul>


          <h3>Çerez Tercihlerinizi Nasıl Yönetebilirsiniz?</h3>
          karsikomsum, kullanıcıların kendilerine ait kişisel veriler üzerindeki tercihlerini kullanabilmelerini son derece önemsemektedir. Bununla birlikte, Site’nin çalışması için zorunlu olan bazı Çerezler konusunda tercih yönetimi mümkün olmamaktadır. Ayrıca bazı Çerezlerin kapatılması halinde Site’nin çeşitli fonksiyonlarının çalışmayabileceğini hatırlatma isteriz.
          Platform’da kullanılan Çerezlere dair tercihlerin ne şekilde yönetebileceğine dair bilgiler aşağıdaki gibidir:
          <ul>
            <li>Ziyaretçiler, Platform’u görüntüledikleri tarayıcı ayarlarını değiştirerek çerezlere ilişkin tercihlerini kişiselleştirme imkanına sahiptir. Eğer kullanılmakta olan tarayıcı bu imkânı sunmaktaysa, tarayıcı ayarları üzerinden Çerezlere ilişkin tercihleri değiştirmek mümkündür. Böylelikle, tarayıcının sunmuş olduğu imkanlara göre farklılık gösterebilmekle birlikte, veri sahiplerinin çerezlerin kullanılmasını engelleme, çerez kullanılmadan önce uyarı almayı tercih etme veya sadece bazı Çerezleri devre dışı bırakma ya da silme imkanları bulunmaktadır. Bu konudaki tercihler kullanılan tarayıcıya göre değişiklik göstermekle birlikte genel açıklamaya https://www.aboutcookies.org/ adresinden ulaşmak mümkündür. Çerezlere ilişkin tercihlerin, ziyaretçinin Platform’a erişim sağladığı her bir cihaz özelinde ayrı ayrı yapılması gerekebilecektir.</li>
            <li>Google Analytics tarafından yönetilen Çerezleri kapatmak için tıklayınız.</li>
            <li>Google tarafından sağlanan kişiselleştirilmiş reklam deneyimini yönetmek için tıklayınız.</li>
            <li>Birçok firmanın reklam faaliyetleri için kullandığı çerezler bakımından tercihler Your Online Choices üzerinden yönetilebilir.</li>
            <li>Mobil cihazlar üzerinden Çerezleri yönetmek için mobil cihaza ait ayarlar menüsü kullanılabilir.</li>
          </ul>
          
          <h3>Hangi Haklara Sahipsiniz?</h3>
          <p>6698 Sayılı Kişisel Verilerin Korunması Kanunu’nun 11. maddesi uyarınca ziyaretçiler, karsikomsum’a başvurarak, kendileriyle ilgili,
            <ul>
              <li>Kişisel veri işlenip işlenmediğini öğrenme,</li>
              <li>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</li>
              <li>Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
              <li>Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</li>
              <li>Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
              <li>Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
              <li>İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,</li>
              <li>Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme
          haklarına sahiptir</li>
            </ul>

          </p>
          <p>
          Söz konusu haklar, kişisel veri sahipleri tarafından 6698 sayılı Kanun Kapsamında Karşıkomşumtarafından hazırlanan Kişisel Verilerin İşlenmesi ve Korunmasına ilişkin Politika’da belirtilen yöntemlerle iletildiğinde her hâlükârda 30 (otuz) gün içerisinde değerlendirilerek sonuçlandırılacaktır. Taleplere ilişkin olarak herhangi bir ücret talep edilmemesi esas olmakla birlikte, karsikomsum, Kişisel Verileri Koruma Kurulu tarafından belirlenen ücret tarifesi üzerinden ücret talep etme hakkını saklı tutar.</p>
          <h3>Rıza ve Gizlilik Politikası’ndaki Değişiklikler</h3>
          <p>karsikomsum, Gizlilik Politikası (“Politika”) ile kullanıcılarına Çerez kullanımının kapsamı ve amaçları hakkında detaylı açıklama sunmayı ve Çerez tercihleri konusunda kullanıcılarını bilgilendirmeyi hedeflemiştir. Bu bakımdan, Platform’da yer alan Çerez bilgilendirme uyarısının kapatılması ve Site’nin kullanılmaya devam edilmesi halinde Çerez kullanımına rıza verildiği kabul edilmektedir. Kullanıcıların Çerez tercihlerini değiştirme imkânı her zaman saklıdır.
          karsikomsum, Politika hükümlerini dilediği zaman değiştirebilir. Güncel Politika Platform’da yayınlandığı tarihte yürürlük kazanır.
          </p>
                        </div>
                        <div className={this.state.caymaiptaliadeicerik} id="caymaiptaliade">
                          <h4>{this.state.baslik}</h4>
                          <hr/>
          <h3>GENEL:</h3>
          <p>Kullanmakta olduğunuz web sitesi üzerinden elektronik ortamda sipariş verdiğiniz takdirde, size sunulan ön bilgilendirme formunu ve mesafeli satış sözleşmesini kabul etmiş sayılırsınız.</p>
          <p>Alıcılar, satın aldıkları ürünün satış ve teslimi ile ilgili olarak 6502 sayılı Tüketicinin Korunması Hakkında Kanun ve Mesafeli Sözleşmeler Yönetmeliği (RG:27.11.2014/29188) hükümleri ile yürürlükteki diğer yasalara tabidir.</p>
          <p>Ürün sevkiyat masrafı olan kargo ücretleri alıcılar tarafından ödenecektir.</p>
          <p>Satın alınan her bir ürün, 14 günlük yasal süreyi aşmamak kaydı ile alıcının gösterdiği adresteki kişi ve/veya kuruluşa teslim edilir. Bu süre içinde ürün teslim edilmez ise, Alıcılar sözleşmeyi sona erdirebilir.</p>
          <p>Satın alınan ürün, eksiksiz ve siparişte belirtilen niteliklere uygun ve varsa garanti belgesi, kullanım klavuzu gibi belgelerle teslim edilmek zorundadır.</p>
          <p>Satın alınan ürünün satılmasının imkansızlaşması durumunda, satıcı bu durumu öğrendiğinden itibaren 3 gün içinde yazılı olarak alıcıya bu durumu bildirmek zorundadır. 14 gün içinde de toplam bedel Alıcı’ya iade edilmek zorundadır.</p>

          <h3>SATIN ALINAN ÜRÜN BEDELİ ÖDENMEZ İSE:</h3>
          <p>Alıcı, satın aldığı ürün bedelini ödemez veya banka kayıtlarında iptal ederse, Satıcının ürünü teslim yükümlülüğü sona erer.</p>
          <h3>KREDİ KARTININ YETKİSİZ KULLANIMI İLE YAPILAN ALIŞVERİŞLER:</h3>
          <p>Ürün teslim edildikten sonra, alıcının ödeme yaptığı kredi kartının yetkisiz kişiler tarafından haksız olarak kullanıldığı tespit edilirse ve satılan ürün bedeli ilgili banka veya finans kuruluşu tarafından Satıcı’ya ödenmez ise, Alıcı, sözleşme konusu ürünü 3 gün içerisinde nakliye gideri ALICI’ya ait olacak şekilde SATICI’ya iade etmek zorundadır.</p>
          <h3>ÖNGÖRÜLEMEYEN SEBEPLERLE ÜRÜN SÜRESİNDE TESLİM EDİLEMEZ İSE:</h3>
          <p>Satıcı’nın öngöremeyeceği mücbir sebepler oluşursa ve ürün süresinde teslim edilemez ise, durum Alıcı’ya bildirilir. Alıcı, siparişin iptalini, ürünün benzeri ile değiştirilmesini veya engel ortadan kalkana dek teslimatın ertelenmesini talep edebilir. Alıcı siparişi iptal ederse; ödemeyi nakit ile yapmış ise iptalinden itibaren 14 gün içinde kendisine nakden bu ücret ödenir. Alıcı, ödemeyi kredi kartı ile yapmış ise ve iptal ederse, bu iptalden itibaren yine 14 gün içinde ürün bedeli bankaya iade edilir, ancak bankanın alıcının hesabına 2-3 hafta içerisinde aktarması olasıdır.</p>
          <h3>ALICININ ÜRÜNÜ KONTROL ETME YÜKÜMLÜLÜĞÜ:</h3>
          <p>Alıcı, sözleşme konusu mal/hizmeti teslim almadan önce muayene edecek; ezik, kırık, ambalajı yırtılmış vb. Hasarlı ve ayıplı mal/hizmeti kargo şirketinden teslim almayacaktır. Teslim alınan mal/hizmetin hasarsız ve sağlam olduğu kabul edilecektir. ALICI , Teslimden sonra mal/hizmeti özenle korunmak zorundadır. Cayma hakkı kullanılacaksa mal/hizmet kullanılmamalıdır. Ürünle birlikte Fatura da iade edilmelidir.</p>
          <h3>CAYMA HAKKI:</h3>
          <p>ALICI; satın aldığı ürünün kendisine veya gösterdiği adresteki kişi/kuruluşa teslim tarihinden itibaren 14 (on dört) gün içerisinde, SATICI’ya aşağıdaki iletişim bilgileri üzerinden bildirmek şartıyla hiçbir hukuki ve cezai sorumluluk üstlenmeksizin ve hiçbir gerekçe göstermeksizin malı reddederek sözleşmeden cayma hakkını kullanabilir.</p>
          <p>SATICININ CAYMA HAKKI BİLDİRİMİ YAPILACAK İLETİŞİM BİLGİLERİ:</p>
          ŞİRKET<br/>
          ADI/UNVANI:<br/>
          ADRES:<br/>
          EPOSTA:<br/>
          TEL:<br/>
          FAKS:<br/>
          <br/>
          <h3>CAYMA HAKKININ SÜRESİ:</h3>
          <p>Alıcı, satın aldığı eğer bir hizmet ise, bu 14 günlük süre sözleşmenin imzalandığı tarihten itibaren başlar. Cayma hakkı süresi sona ermeden önce, tüketicinin onayı ile hizmetin ifasına başlanan hizmet sözleşmelerinde cayma hakkı kullanılamaz.</p>
          <p>Cayma hakkının kullanımından kaynaklanan masraflar SATICI’ ya aittir.</p>
          <p>Cayma hakkının kullanılması için 14 (ondört) günlük süre içinde SATICI’ ya iadeli taahhütlü posta, faks veya eposta ile yazılı bildirimde bulunulması ve ürünün işbu sözleşmede düzenlenen “Cayma Hakkı Kullanılamayacak Ürünler” hükümleri çerçevesinde kullanılmamış olması şarttır.</p>
          <h3>CAYMA HAKKININ KULLANIMI:</h3>
          <p>3. kişiye veya ALICI’ ya teslim edilen ürünün faturası, (İade edilmek istenen ürünün faturası kurumsal ise, iade ederken kurumun düzenlemiş olduğu iade faturası ile birlikte gönderilmesi gerekmektedir. Faturası kurumlar adına düzenlenen sipariş iadeleri İADE FATURASI kesilmediği takdirde tamamlanamayacaktır.)</p>
          <p>İade formu, İade edilecek ürünlerin kutusu, ambalajı, varsa standart aksesuarları ile birlikte eksiksiz ve hasarsız olarak teslim edilmesi gerekmektedir.</p>
          <h3>İADE KOŞULLARI:</h3>
          <p>SATICI, cayma bildiriminin kendisine ulaşmasından itibaren en geç 10 günlük süre içerisinde toplam bedeli ve ALICI’yı borç altına sokan belgeleri ALICI’ ya iade etmek ve 20 günlük süre içerisinde malı iade almakla yükümlüdür.</p>
          <p>ALICI’ nın kusurundan kaynaklanan bir nedenle malın değerinde bir azalma olursa veya iade imkânsızlaşırsa ALICI kusuru oranında SATICI’ nın zararlarını tazmin etmekle yükümlüdür. Ancak cayma hakkı süresi içinde malın veya ürünün usulüne uygun kullanılması sebebiyle meydana gelen değişiklik ve bozulmalardan ALICI sorumlu değildir.</p>
          <p>Cayma hakkının kullanılması nedeniyle SATICI tarafından düzenlenen kampanya limit tutarının altına düşülmesi halinde kampanya kapsamında faydalanılan indirim miktarı iptal edilir.</p>
          <h3>CAYMA HAKKI KULLANILAMAYACAK ÜRÜNLER:</h3>
          <p>ALICI’nın isteği veya açıkça kişisel ihtiyaçları doğrultusunda hazırlanan ve geri gönderilmeye müsait olmayan, iç giyim alt parçaları, mayo ve bikini altları, makyaj malzemeleri, tek kullanımlık ürünler, çabuk bozulma tehlikesi olan veya son kullanma tarihi geçme ihtimali olan mallar kişiye özel üretilen ürünler vb, ALICI’ya teslim edilmesinin ardından ALICI tarafından ambalajı açıldığı takdirde iade edilmesi sağlık ve hijyen açısından uygun olmayan ürünler, teslim edildikten sonra başka ürünlerle karışan ve doğası gereği ayrıştırılması mümkün olmayan ürünler, Abonelik sözleşmesi kapsamında sağlananlar dışında, gazete ve dergi gibi süreli yayınlara ilişkin mallar, Elektronik ortamda anında ifa edilen hizmetler veya tüketiciye anında teslim edilen gayrimaddi mallar, ile ses veya görüntü kayıtlarının, kitap, dijital içerik, yazılım programlarının, veri kaydedebilme ve veri depolama cihazlarının, bilgisayar sarf malzemelerinin, ambalajının ALICI tarafından açılmış olması halinde iadesi Yönetmelik gereği mümkün değildir. Ayrıca Cayma hakkı süresi sona ermeden önce, tüketicinin onayı ile ifasına başlanan hizmetlere ilişkin cayma hakkının kullanılması da Yönetmelik gereği mümkün değildir.</p>
          <p>Kozmetik ve kişisel bakım ürünleri, iç giyim ürünleri, mayo, bikini, kitap, kopyalanabilir yazılım ve programlar, DVD, VCD, CD ve kasetler ile kırtasiye sarf malzemeleri (toner, kartuş, şerit vb.) iade edilebilmesi için ambalajlarının açılmamış, denenmemiş, bozulmamış ve kullanılmamış olmaları gerekir.</p>
          <h3>TEMERRÜT HALİ VE HUKUKİ SONUÇLARI</h3>
          <p>ALICI, ödeme işlemlerini kredi kartı ile yaptığı durumda temerrüde düştüğü takdirde, kart sahibi banka ile arasındaki kredi kartı sözleşmesi çerçevesinde faiz ödeyeceğini ve bankaya karşı sorumlu olacağını kabul, beyan ve taahhüt eder. Bu durumda ilgili banka hukuki yollara başvurabilir; doğacak masrafları ve vekâlet ücretini ALICI’dan talep edebilir ve her koşulda ALICI’nın borcundan dolayı temerrüde düşmesi halinde, ALICI, borcun gecikmeli ifasından dolayı SATICI’nın uğradığı zarar ve ziyanını ödeyeceğini kabul eder.</p>
          <h3>ÖDEME VE TESLİMAT</h3>
          <p>Banka Havalesi veya EFT (Elektronik Fon Transferi) yaparak banka hesaplarımız sayfamızdaki hesaplarımızdan (TL) herhangi birine yapabilirsiniz.</p>
          <p>Sitemiz üzerinden kredi kartlarınız ile, Her türlü kredi kartınıza online tek ödeme ya da online taksit imkânlarından yararlanabilirsiniz. Online ödemelerinizde siparişiniz sonunda kredi kartınızdan tutar çekim işlemi gerçekleşecektir.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                    
              </div>
    <FooterSection />
    </div>
        )
    }
}

export default Contracts;