import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import {showToast} from '../utils';
import Linkify from 'react-linkify';
import ShowMoreText from "react-show-more-text";
import Slider from "../slider/Slider.js";

class Post extends Component {
    constructor(props) {
        super(props);
        this.mycomment = React.createRef();
        this.focusTextInput = this.focusTextInput.bind(this);
        this.state = {
            comment: ''
        };
      }
      static propTypes = {
        cookies: instanceOf(Cookies).isRequired
      };
      formatDate = (dateString) => {
        const options = { hour: '2-digit', minute: '2-digit', second: '2-digit' }
        return new Date(dateString).toLocaleDateString(undefined, options)
      }
      focusTextInput(e) {
        // DOM API kullanarak text input'a odaklanın
        // Not : DOM düğümünü getirmek için "current"ı kullanırız.
        e.preventDefault();
        this.mycomment.current.focus();
      }
      handleChange = (event) => {
        this.setState({comment: event.target.value});
      }

      handleSubmit = event => {
        event.preventDefault();
        var retype = 'btn-green';
        const { cookies } = this.props;
        var formData = new FormData()

        formData.append('comment_content',  this.state.comment)
        formData.append('post_id',  this.props.id)

        fetch(this.props.apiurl + '/api/addComment', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + cookies.get('access-token') ,
            },
            body: formData
          })
          .then((response) => {
            if(!response.ok) {
              retype = 'bg-danger';
              return response.json();
            }
            else{
              retype = 'btn-green';
              //document.getElementById('livemessage').value="";
              return response.json();
            } 
          })
          .then((responseData) => {
            if (responseData.message==='Unauthenticated.') {
              showToast("Mesaj gönderebilmek için üye girişi yapmanız gerekiyor", retype);
            }            
          })
    
          .catch((error) => {
            showToast(error, "bg-danger");
            //console.log('error: ' + error);
          });
      };

    render() {
        return (
                <div>
                <div className="card social-timeline-card m-3">
                    <div className="card-header">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="mr-2">
                                    <img className="rounded-circle" width="45" src="https://bootdey.com/img/Content/avatar/avatar1.png" alt=""/>
                                </div>
                                
                        <div className="ml-2">
                        <div className="h5 m-0 text-blue normaltext"><a href={'profil/' + this.props.slug}>{this.props.sender.first_name + ' ' + this.props.sender.last_name}</a></div>
                        <div className="h7 text-muted smalltext"> <i className="far fa-clock"></i> {this.props.created_at && this.formatDate(this.props.created_at)}</div>
                        </div>                            
                                
                            </div>
                            <div>
                                <div className="dropdown">
                                    <button className="btn btn-link dropdown-toggle" type="button" id="gedf-drop2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fa fa-ellipsis-h"></i>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="gedf-drop2">
                                        <div className="h6 dropdown-header">...</div>
                                        <a className="dropdown-item" href="#">Kaydet</a>
                                        <a className="dropdown-item" href="#">Gizle</a>
                                        <a className="dropdown-item" href="#">Bildir</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body normaltext">
                    <p className="card-text normaltext">
                    <ShowMoreText
                          /* Default options */
                          lines={5}
                          more="Daha fazla göster"
                          less="Daha az göster"
                          className="content-css"
                          anchorClass="my-anchor-css-class"
                          onClick={this.executeOnClick}
                          expanded={false}
                          truncatedEndingComponent={"... "}
                      >
                      <Linkify properties={{target: '_blank'}}>
                        {this.props.content}
                      </Linkify>
                      </ShowMoreText>
                    </p>
                    {this.props.video!=null && ReactPlayer.canPlay(this.props.video) && <ReactPlayer className='col-12 mt-2' url={this.props.video} />}

                    {this.props.images.length>0 && <Slider imageList={this.props.images} withDots={true} apiurl={this.props.apiurl} />}
                                                  
                    </div>
                    <div className="card-footer">
                        <div className="col-sm-12 d-flex justify-content-between">
                          <div className="col-sm-4">
                            <button className="btn btn-sm btn-light col-sm-12">
                            <i class="far fa-thumbs-up"></i> Beğen
                            </button>
                          </div>
                          <div className="col-sm-4">
                            <button className="btn btn-sm btn-light col-sm-12" onClick={this.focusTextInput}>
                              <i className="fa fa-comment"></i> Yorum Yap
                              </button>
                          </div>
                          <div className="col-sm-4">
                            <button className="btn btn-sm btn-light col-sm-12">
                              <i class="fas fa-share"></i> Paylaş
                            </button>
                          </div>
                          <hr/>
                        </div>
                        
                        {this.props.comments && this.props.comments.map((comment, index) =>
                        <div className="card-text">
                        <img className="rounded-circle m-2" width="32" src="https://bootdey.com/img/Content/avatar/avatar3.png" alt=""/>
                        
                      
                      {comment.user_title + ' : ' + comment.comment_content}

                      

                        <p className="pl-5"> 
                        <a href="#" className="card-link"><i class="far fa-thumbs-up"></i> Beğen</a>
                        <a href="#" className="card-link"><i className="fa fa-comment"></i> Yorum Yap</a>
                        <a href="#" className="card-link"><i class="fas fa-share"></i> Paylaş</a>
                        </p>
                        <hr/>
                        </div>
                        )}

                        
                        <div className="m-1 text-right">
                        <textarea ref={this.mycomment}  className="form-control m-1" id={"comment" + this.props.id} name={"comment" + this.props.id} rows="1" onChange={this.handleChange} placeholder="Yorum yap"></textarea>
                        <button className="btn btn-sm btn-primary" onClick={this.handleSubmit}>Yorum Yap</button>
                        </div>

                    </div>
                    

                    </div>
                </div>
        )
    }
}

export default withCookies(Post);