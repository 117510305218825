import React, { Component } from 'react';
import Post from './post';

class PostList extends Component {
    constructor(props) {
        super(props);
      }
    state = {
        url: this.props.apiurl + '/api/posts',
        isLoading: false,
        postJson:[]
    }
    componentDidMount() {
        this.setState({ isLoading: true });
        fetch(this.state.url, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            }
          })
        .then(res => res.json())
        .then((data) => {
            if (data instanceof Array) {
            this.setState({postJson: data, isLoading: false })
            }
        })
        .catch(err => {
            console.log(err);
            //this.myToast("Teknik bir problemimiz var.<br/> Mezat detayı alınamadı oluşturulamadı.<br/> Problem detayı:" + err);
        }) 
    }
    render() {
        return (
                <div>

                {this.state.postJson.map((postInd) => (
                        <Post apiurl={this.props.apiurl} id={postInd.id} created_at={postInd.created_at} sender={postInd.sender} comments={postInd.post_comment} slug={postInd.slug} content={postInd.post_content} images={postInd.post_image} video={postInd.post_url}/>
                ))}



                    
                </div>
                
        )
    }
}

export default PostList;