import React, {Component} from 'react';


import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import NotAuthorized from './notauthorized';
import Header from '../parts/header';
import FooterSection from '../parts/footersection';
import TopBanner from '../parts/topbanner';
import MainPageNotLoggedIn from '../parts/mainpagenotloggedin';
import NewsFeed from '../parts/newsfeed';
//import background from url("/assets/images/bgmainpage.jpeg");

export class MainPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
        authCheckUrl: this.props.apiurl + '/api/user',
        isLoggedIn: false,
        user:''
      }
  }
  componentDidMount() {
    const { cookies } = this.props;
   // alert(cookies.get('access-token'))
    //setInterval(this.getSiteSettingsData(), 1000);
    fetch(this.state.authCheckUrl, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookies.get('access-token') ,
        }
      })
      .then((response) => {
        if(!response.ok) {
          throw new Error(response.status)
        }
        else{
          return response.json();
        } 
      })
      .then((responseData) => {
         if (responseData.message==='Unauthenticated.') {
             this.setState({isLoggedIn:false});
         } else {
            this.setState({isLoggedIn:true, user: responseData.user});
         }
        })

      .catch((error) => {
        console.log('error: ' + error);
        this.setState({isLoggedIn:false});
      });
  }
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
    render() {
        var strTitle="Karşıkomşum- Komşularınızdan haberiniz olsun";
        document.title=strTitle;
        if (this.state.isLoggedIn) {
          return (
            <div className="App">
            <TopBanner />
            <Header apiurl={this.props.apiurl} user={this.state.user} isLoggedIn={this.state.isLoggedIn}/> 
            <NewsFeed apiurl={this.props.apiurl}/>
            <FooterSection />
            </div>
          )
        } else {
          return (
            <div className="App">
            <TopBanner />
            <Header apiurl={this.props.apiurl} isLoggedIn={this.state.isLoggedIn}/> 
            <MainPageNotLoggedIn/>
            <FooterSection />
            </div>
          )
        }
        
    }
}

export default withCookies(MainPage);