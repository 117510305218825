import React, {Component} from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Product from '../parts/product';
export class CardCarousel extends Component {
    render(){
        return(      
        <div className="">
            <Carousel className='carousel-cards bg-dark'>
            <Carousel.Item interval={2000}>
            <div className="row table d-flex justify-content-center">
                <div className="col-md-3 p-3 table-cell">
                    <Product productid='1' productname='HP LAPTOP' salelabel='FIRSAT' badgetype='bg-danger' seller='Cello Bilişim' rating='2' currentprice='2000 ₺' openingprice='YOK' image1='./assets/images/anakart.jpeg' image2='./assets/images/anakart2.jpeg' endtime='2021-04-25 15:30:00'></Product>
                </div>
                <div className="col-md-3 p-3 table-cell">
                    <Product productid='2' productname='DENEME METNİ' salelabel='FIRSAT' badgetype='bg-danger' seller='Cello Bilişim' rating='4' currentprice='1500 ₺' openingprice='100 ₺' image1='./assets/images/anakart.jpeg' image2='./assets/images/anakart2.jpeg' endtime='2021-04-28 09:00:00'></Product>
                </div>
                <div className="col-md-3 p-3 table-cell">
                        <Product productid='3' productname='ASUS BOARD' salelabel='FIRSAT' badgetype='bg-danger' seller='Cello Bilişim' rating='5' currentprice='Teklif yok'  openingprice='700 ₺' image1='./assets/images/anakart.jpeg' image2='./assets/images/anakart2.jpeg' endtime='2021-04-26 11:00:00'></Product>
                </div>

            </div>
            <Carousel.Caption>
                
            </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
                <div className="row table d-flex justify-content-center">
                    <div className="col-md-3 p-3 table-cell">
                        <Product productid='7' salelabel='' seller='Hasan Bilişim' rating='2.5' currentprice='200 ₺' openingprice='100 ₺' image1='./assets/images/anakart.jpeg' image2='./assets/images/anakart2.jpeg' endtime='2021-03-19 19:24:15'></Product>
                    </div>
                    <div className="col-md-3 p-3  table-cell">
                        <Product productid='8' salelabel='' seller='Mahmut Bilişim' rating='3' currentprice='100 ₺' openingprice='100 ₺' image1='./assets/images/anakart.jpeg' image2='./assets/images/anakart2.jpeg' endtime='2021-03-26 15:35:00'></Product>
                    </div>
                    <div className="col-md-3 p-3  table-cell">
                        <Product productid='9' salelabel='' seller='Mahmut Bilişim' rating='3' currentprice='200 ₺' openingprice='100 ₺' image1='./assets/images/anakart.jpeg' image2='./assets/images/anakart2.jpeg' endtime='2021-03-27 19:00:00'></Product>
                    </div>
                </div>
            <Carousel.Caption>
            </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
                <div className="row table d-flex justify-content-center">
                    <div className="col-md-3 p-3 table-cell">
                        <Product productid='5' productname='Lenovo IdeaPad 3 Intel Core i7 1065G7 8GB 512GB SSD Freedos 14 FHD Taşınabilir Bilgisayar' salelabel='' seller='Cello Bilişim' rating='5' currentprice='200 ₺' openingprice='100 ₺' image1='./assets/images/anakart.jpeg' image2='./assets/images/anakart2.jpeg' endtime='2021-03-24 09:00:00'></Product>
                    </div>
                    <div className="col-md-3 p-3  table-cell">
                        <Product productid='6' salelabel='' seller='Cello Bilişim' rating='4' currentprice='200 ₺' openingprice='100 ₺' image1='./assets/images/anakart.jpeg' image2='./assets/images/anakart2.jpeg' endtime='2021-03-21 05:30:00'></Product>
                    </div>
                    <div className="col-md-3 p-3  table-cell">
                        <Product productid='4' productname='LENOVO IDEAPAD uzun metin denemesi' salelabel='YENİ' seller='Cello Bilişim' rating='2' badgetype='bg-warning' currentprice='200 ₺' openingprice='100 ₺' image1='./assets/images/anakart.jpeg' image2='./assets/images/anakart2.jpeg' endtime='2021-03-21 18:00:00'></Product>
                    </div>
                </div>
            <Carousel.Caption>

            </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
      </div>  
        )
    }
}

export default CardCarousel;