import React, {Component} from 'react';
import Header from '../parts/header';
import FooterSection from '../parts/footersection';
import TopBanner from '../parts/topbanner';

export class NoMatch extends Component {
    render() {
        document.title="Karşıkomşum- 404 aradığınız sayfa bulunamadı"
        return (
        <div className="App">
                <TopBanner />
                <Header apiurl={this.props.apiurl}/> 
                    <div className="page-404">
                        <div className="outer">
                            <div className="middle">
                                <div className="inner">

                                    <div className="inner-circle"><a href="/"><i className="fa fa-home"></i><span>404</span></a></div>
                                    <span className="inner-status">Bir sorunumuz var kaptan</span>
                                    <span className="inner-detail">
                                        Aradığın sayfa bulunamadı
                                        <a href="/" className="btn btn-info mtl m-3"><i className="fa fa-home"></i>&nbsp;
                                            Anasayfa'ya dön
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
        <FooterSection />
        </div>

        )
    }
}

export default NoMatch;