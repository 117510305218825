import React, {Component} from 'react';
import Header from '../parts/header';
import FooterSection from '../parts/footersection';
import TopBanner from '../parts/topbanner';

export class RedirectPage extends Component {
    render() {
        document.title="Karşıkomşum- 404 aradığınız sayfa bulunamadı"
        return (
        <div className="App">
                <TopBanner />
                <Header apiurl={this.props.apiurl}/> 
                    <div>
                        <div className="outer">

                                    <div className="inner-circle"><a href="/"><i className="fa fa-link"></i><span>Yönlendirme</span></a></div>
                                    
                                    <span className="inner-detail">
                                        <p> Karşı Komşu harici bir siteye yönlendiriliyorsunuz.</p> 
                                        <a href="/" className="btn btn-info mtl m-3"><i className="fa fa-home"></i>&nbsp;
                                            Anasayfa'ya dön
                                        </a>
                                        <a href="/" className="btn btn-info mtl m-3"><i className="fa fa-home"></i>&nbsp;
                                            Sayfaya git
                                        </a>
                                    </span>
                                
                            
                        </div>
                    </div>
        <FooterSection />
        </div>

        )
    }
}

export default RedirectPage;