import React, {Component} from 'react';
import ProductDetailsChild from './productdetailschild';

export class ProductDetails extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
      }
        state = {
          url: this.props.apiurl + '/api/auctions/' + this.props.productslug,
          isLoading: false,
          err: false,
          productDetails:[]
        }

        componentDidMount() {

            this.setState({ isLoading: true });
            fetch(this.state.url)
            .then(res => res.json())
            .then((data) => {
              this.setState({ productDetails: data, isLoading: false })
            })
            .catch(err => {
                this.setState({err:true})
                console.log(err);
                //this.myToast("Teknik bir problemimiz var.<br/> Mezat detayı alınamadı oluşturulamadı.<br/> Problem detayı:" + err);
            }) 
          }


    render() {

        return (
          !this.state.isLoading && <ProductDetailsChild veri={this.state.productDetails} apiurl={this.props.apiurl}></ProductDetailsChild>
        )
        }
    }
    
export default ProductDetails;