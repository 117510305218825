import React, {Component} from 'react';
import CookieConsent, { Cookies } from "react-cookie-consent";
import ScrollToTop from '../components/scrolltotop';

export class FooterSection extends Component {

    render(){
    return (
    <footer className="footer text-center text-white yesillink">
    <div id="snackbar"></div>
    <div className="container-fluid pt-3 pb-0">
      <section className="">
    
        <div className="row">
    
          <div className="col-md-3 mb-4 mb-md-0  text-left">
            <h5 className="text-uppercase">Karşıkomşum</h5>
            <ul className="list-unstyled mb-0  text-left">
            <li>
              <a href="/hakkimizda" className="text-white">Hakkımızda</a>
              </li>
              <li>
              <a href="/iletisim" className="text-white">İletişim</a>
              </li>
              <li>
              <a href="/sozlesmeler/uyeliksozlesmesi" className="text-white">Üyelik Sözleşmesi</a>
              </li>
              <li>
              <a href="/sozlesmeler/mesafelisatissozlesmesi" className="text-white">Mesafeli Satış Sözleşmesi</a>
              </li>
              <li>
              <a href="/sozlesmeler/gizliliksozlesmesi" className="text-white">Gizlilik Sözleşmesi</a>
              </li>
              <li>
              <a href="/sozlesmeler/kvkkvecerezler" className="text-white">Kişisel Verilerin Korunması</a>
              </li>
              <li>
              <a href="/sozlesmeler/caymaiptaliade" className="text-white">Cayma, İptal ve İade Koşulları</a>
              </li>


            </ul>
          </div>
    
   
          <div className="col-md-3 mb-4 mb-md-0 text-left">
            <h5 className="text-uppercase">Yardım</h5>
            
            <ul className="list-unstyled mb-0  text-left">
              <li>
              <a href="#!" className="text-white">Nasıl Adres Onayı Alabilirim?</a>
              </li>
              <li>
              <a href="#!" className="text-white">Nasıl Satış Yapabilirim?</a>
              </li>
              <li>
              <a href="#!" className="text-white">Şirketimin Tanıtımını Nasıl yaparım?</a>
              </li>
              <li>
              <a href="#!" className="text-white">Adres Bul</a>
              </li>
              </ul>
          </div>

          <div className="col-md-3 mb-4 mb-md-0 text-left">
            <h5 className="text-uppercase">İşletmeler</h5>
            <div className="row">
            
            <div className="col-12">
            <ul className="list-unstyled mb-0  text-left">
              <li>
              <a href="#!" className="text-white">Yerel İşletmeler</a>
              </li>
              <li>
              <a href="#!" className="text-white">Kurumsal Firmalar</a>
              </li>
              <li>
              <a href="#!" className="text-white">Emlak ve Gayrımenkul</a>
              </li>
            </ul>
            </div>
            </div>
            
          </div>

          <div className="col-md-3 mb-4 mb-md-0">
   
            <ul className="list-unstyled mb-0">

              <li>
                  <section className="mb-4">
                  
                  <a className="btn btn-outline-light btn-floating m-1" href="https://www.facebook.com/karsikomsum.com/" role="button" target='_blank' rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>


                  <a className="btn btn-outline-light btn-floating m-1" href="#!" role="button"
                    ><i className="fab fa-twitter"></i></a>


                  <a className="btn btn-outline-light btn-floating m-1" rel="noopener noreferrer" href="https://www.instagram.com/karsikomsum.com/" role="button" target='_blank'><i className="fab fa-instagram"></i></a>


                  <a className="btn btn-outline-light btn-floating m-1" rel="noopener noreferrer" href="https://www.youtube.com/channel/" role="button" target='_blank'><i className="fab fa-youtube"></i></a>
                  
                  </section>
                </li>
                <li>
                  <a href="#!" className="text-white"></a>
                </li>
              </ul>
          </div>
                      </div>
                  
              </section>

    </div>
        <div className='bg-white'><hr/></div>
        <div className='container-fluid p-1 row no-gutters'>
          <div className='col-md-12  my-auto'>
            <p>© 2021 Copyright : <a className="text-white"  href="https://www.cobee.com.tr/" rel="noopener noreferrer" target='_blank'>Cobee Bilişim</a> Tüm hakları saklıdır. </p>
          </div>

       </div>
 
          <CookieConsent location="bottom" cookieName="karsikomsumcerezkabul" hideOnAccept="true" buttonText="Kabul Ediyorum" expires={365}>
            Çoğu web sitesinde olduğu gibi, karsikomsum.com ziyaretçilere kişisel içerik ve reklamlar 
            göstermek, site içinde analitik faaliyetler gerçekleştirmek ve 
            ziyaretçi kullanım alışkanlıklarını takip etmek amacıyla Çerezler kullanılmaktadır. Detaylı bilgi için "<a className='text-white' href='/gizlilik'>gizlilik politikamızı</a>" inceleyebilirsiniz.
          </CookieConsent>
          <ScrollToTop></ScrollToTop>

    </footer>
        )
    }
}

export default FooterSection;