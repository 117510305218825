import React, {Component} from 'react';

import SearchBox from '../components/searchbox';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import SearchBar  from '../components/searchbar/searchbar';

export class Header extends Component {
  constructor(props) {
    super(props);
  }

  
    render(){
      if (!this.props.isLoggedIn) {
        return(
          //this.props.isLoggedIn && 
            <div className="mainpagemenu shadow-lg sticky-top">
            <div className="row container-fluid">
              <div className="col-sm-4 my-auto box">
                <a className="navbar-brand" href="/">
                  <img className="img-fluid logo pr-2" src="./assets/images/logo.png" alt="karşıkomşum"/>
                </a>
              </div>
              <div className='col-sm-6 baslik text-left my-auto mx-auto'>
                <div className="row">
                <div className="col-sm-4 pr-5"><a href="">Komşularınız</a></div>
                <div className="col-sm-4 pr-5"><a href="">Yerel Şirketler</a></div>
                <div className="col-sm-4 pr-5"><a href="">Yardım Kuruluşları</a></div>
                </div>
              </div>
              <div className="col-sm-2 my-auto mx-auto">
                <div className="row float-right mr-5">
                  <div  className=''>
                      <a className="btn btn-light float-right" href="girisyap">Giriş Yap</a>
                  </div>
                 </div>
                </div>
                </div>
              </div>
        )
      } else {
        return(
            <div className="mainpagemenu shadow-sm sticky-top d-flex justify-content-center">
            <div className="row container-fluid">
              <div className="col-sm-12 col-md-3 navbar-header">
                <a className="navbar-brand" href="/">
                  <img className="img-fluid" src="./assets/images/logo.png" alt="karşıkomşum"/>
                </a>
              </div>
              <div className='col-sm-6 my-auto mx-auto'>
                <div className="col-sm-12">
                <SearchBar/>
                </div>
              </div>
              <div className="col-sm-3 my-auto mx-auto d-flex justify-content-around align-center">
                <div className="row float-right">
                  <div  className="d-flex justify-content-around">
                      
                      <span><button className="btn btn-outline btn-light btn-sm"><img className="rounded-circle mr-2" width="32" src="https://bootdey.com/img/Content/avatar/avatar3.png" alt=""/>{this.props.user.first_name}</button></span>
                      
                  </div>
                 </div>
                </div>
                </div>

              </div>
        )
      }
       
        }
    }

export default withCookies(Header);