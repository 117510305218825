import React, {Component} from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import {tiretemizle, urltemizle, metindegistir} from '../components/utils';
import '../assets/css/productdetail.css';
import MezatSayaci from '../components/mezatsayaci';
//import Carousel from 'react-bootstrap/Carousel';
import ModalForm from '../components/modalform';
import CardCarouselWithThumb from '../components/cardcarouselwiththumb.js';
import CardCarousel from '../components/cardcarousel.js';
import Product from '../parts/product';
import {showToast} from '../components/utils';

export class ProductDetailsChild extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
      }
      state = {
        isLoading: false,
        isOpen: false,
        activeModal: null,
        activePay: null,
        pId:'',
        pName: '',
        pSlug: '',
        pSummary: '',
        pDescription: '',
        pStartTime: '',
        pEndTime:'',
        pOpeningPrice:'',
        pCurrentPrice:'',
        pBuyNowPrice:'',
        pSeller:'',
        pImages: [],
        pBids:[],
        }
      openModal = () => this.setState({ isOpen: true });
      closeModal = () => this.setState({ isOpen: false, activeModal: null });
      handleSubmit = (name) =>  this.setState({ isOpen: false });
    
    clickHandler(e, index) {
          if (index>1) {
              this.setState({activePay: index})
          } 
          this.setState({isOpen: true , activeModal: index})
    }

    myChangeHandler = (event) => {
        event.target.value = event.target.value.replace(/\D/g,'');
        this.setState({activeModal: 1, activePay: event.target.value.replace(/\D/g,'')});
      }
    numberFormat = (value) => {
      return new Intl.NumberFormat('tr-TR', {
        maximumSignificantDigits: 2
      }).format(value);
    }
    componentDidMount() {
        window.Echo.channel('auction.'+this.state.pId)
        .listen('AuctionClosed',(e) => {
            showToast('Mezat Kapandı', 'bg-primary');
          //document.getElementById('livechatcontent').innerHTML = document.getElementById('livechatcontent').innerHTML + e.message + '<div id=\'lastline\'></div>';
        })
        .listen('NewBid',(e) => {
            document.getElementById('currentprice').innerHTML = this.numberFormat(e.bid.total_amount) + ' ₺ (' + e.bidCount + ' adet teklif)'
            showToast('Yeni bir pey verildi', 'bg-success');  
        })

        document.getElementById('product-description').innerHTML = this.state.pDescription;
    }
    render() {

        this.props.veri.map((per => {
            this.state.pId=per.id;
            this.state.pName=per.name;
            this.state.pSummary=per.summary;
            this.state.pSlug = per.slug;
            this.state.pDescription=per.description;
            this.state.pEndTime=per.end_time;
            per.opening_price!=null ? this.state.pOpeningPrice=per.opening_price : this.state.pOpeningPrice=0;
            per.current_price!=null ? this.state.pCurrentPrice=per.current_price: this.state.pCurrentPrice=0;
            this.state.pBuyNowPrice=per.buynow_price;
            this.state.pImages=per.auction_image;
            this.state.pBids=per.auction_bid;
            this.state.pSeller = per.seller;
        }
        ))

        document.title="Karşıkomşum- " +  this.state.pName;

        return (
            
            <div className="container-fluid">
                <div className="row bg-dark p-2 text-white shadow-lg">
                    <div className="col-md-12" id="product-detail-status">
                        <div className="row">
                            <div className="col-md-8 my-auto hugetext text-left livecell h-100" id="auctionstatus"><i className="fas fa-gavel"></i>  Devam Eden Mezat - Henüz Teklif Vermediniz</div>
                            <div className="col-md-4 my-auto hugetext livecell h-100">
                                <div id="row">
                                    <div className="col-md-5 pt-3 my-auto mx-auto float-left" id="kalansure">Kalan Süre</div>
                                    <div className="col-md-7 my-auto mx-auto float-right"><MezatSayaci id={this.state.pId} endtime={this.state.pEndTime} placement="details"></MezatSayaci></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <div className="text-left pt-2">
                <div className="small">
                    <ul className="breadcrumb">
                        <li><a href="/">Anasayfa</a></li>
                        <li><a href="mezatlar">Mezatlar</a></li>
                        <li><a href="mezatlar/bilgisayar">Bilgisayar</a></li>
                        <li><a href="mezatlar/bilgisayar/dizustu">Dizüstü</a></li>
                        <li>{this.state.pName}  </li>
                    </ul>
                </div>
            </div>
            <div className="productdet-content product-wrap product-deatil shadow-lg">

            <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-12 m-0">

                <CardCarouselWithThumb slidesToScroll={1} slidesToShow={1}>
                    {
                    this.state.pImages.map((im)=> <img src={this.props.apiurl + im.public_url} key={im.id}/>)
                    }
                </CardCarouselWithThumb>


            </div>

            <div className="col-md-6 col-md-offset-1 col-sm-12 col-xs-12">
                <h6 className="name text-left">
                {this.state.pName}                   
                <hr/>

                <p className="small text-left">
                {this.state.pSummary} 
                </p>    
                <hr />          
                <div className="text-left">
                    <p>Açılış Fiyatı: <span className='hugetext text-success'>{(this.state.pOpeningPrice!='0' && this.state.pOpeningPrice!=null) ? this.numberFormat(this.state.pOpeningPrice) + ' ₺': 'Yok'} </span></p>
                    <p>Mevcut Fiyat: <span  id='currentprice'>{(this.state.pCurrentPrice!='0' && this.state.pCurrentPrice!=null)? this.numberFormat(this.state.pCurrentPrice) + ' ₺' + ' (' + this.state.pBids.length + ' adet teklif ) ': 'Teklif Yok'}</span></p>
                </div>                    
              
                <hr />
                <div className="row">
                    <div className="col-md-2 text-left h-100 pt-2">
                        Pey ver:
                    </div>
                    <div className="pl-3 col-md-10 text-left h-100">
                        <div className="row">
                        <a onClick={e => this.clickHandler(e, this.state.pBuyNowPrice/100 >1 ? this.state.pBuyNowPrice/100: 1 )} className="btn btn-outline-success m-1 col-md-2">{this.numberFormat(this.state.pBuyNowPrice/100)} ₺</a>
                        <a onClick={e => this.clickHandler(e, this.state.pBuyNowPrice/50 >1 ? this.state.pBuyNowPrice/50: 5)} className="btn btn-outline-success  m-1 col-md-2">{this.numberFormat(this.state.pBuyNowPrice/50)} ₺</a>
                        <a onClick={e => this.clickHandler(e, this.state.pBuyNowPrice/25  >1  ? this.state.pBuyNowPrice/25: 10)} className="btn btn-outline-success  m-1 col-md-2">{this.numberFormat(this.state.pBuyNowPrice/25)} ₺</a>
                        <a onClick={e => this.clickHandler(e, this.state.pBuyNowPrice/10  >1  ? this.state.pBuyNowPrice/10: 20)} className="btn btn-outline-success  m-1 col-md-2">{this.numberFormat(this.state.pBuyNowPrice/10)} ₺</a>
                        <a onClick={e => this.clickHandler(e, this.state.pBuyNowPrice/5  >1  ? this.state.pBuyNowPrice/5: 50)} className="btn btn-outline-success  m-1 col-md-2">{this.numberFormat(this.state.pBuyNowPrice/5)} ₺</a>
                        </div>
                        <div className="row">
                        <input type="number" min="0" id="custompay" onChange={this.myChangeHandler} className="form-control m-1 col-md-2" placeholder="Özel Pey Miktarı" required/>
                        <a onClick={e => this.clickHandler(e, 1)}  className="btn btn-blue  m-1 col-md-2">Pey Ver</a>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="pl-3">
                        <a onClick={e => this.clickHandler(e, -1)} className="btn btn-blue">Hemen Al ({this.numberFormat(this.state.pBuyNowPrice)} ₺)</a>
                    </div>
                </div>
            
                <hr/>
                         <small>Satıcı: <a href={urltemizle("/satici/ABC Bilgisayar/1")}>{this.state.pSeller.first_name + ' ' + this.state.pSeller.last_name}</a>     
                    <a onClick={e => this.clickHandler(e, 0)} className="ml-5 btn btn-white btn-sm"><i className="fa fa-envelope"></i> Satıcıya Sor</a></small>
                    {this.state.activeModal===0 && this.state.isOpen  ? 
                    <ModalForm 
                        activeModal={this.activeModal}
                        closeModal={this.closeModal} 
                        isOpen={this.state.isOpen} 
                        onHide={this.closeModal}
                        handleSubmit={this.handleSubmit}
                        aria-labelledby="contained-modal-title-vcenter"
                        title="Satıcıya Sor"
                        saveButtonCaption= "Gönder"
                        closeButtonCaption='İptal'
                   >
                    <p><strong>Satıcı:</strong> ABC Bilgisayar</p>
                    <p><input type="text" placeholder="Konu" className="col-md-12"/></p>
                    <p><textarea placeholder="Sorunuzu buraya yazın" className="col-md-12" /></p>
                   </ModalForm> 
                        : 
                        null 
                    }
                    
                    {this.state.activeModal>0 && this.state.isOpen  ? 
                    <ModalForm 
                        activeModal={this.state.activeModal}
                        closeModal={this.closeModal} 
                        isOpen={this.state.isOpen} 
                        onHide={this.closeModal}
                        handleSubmit={this.handleSubmit}
                        aria-labelledby="contained-modal-title-vcenter"
                        title="Pey Ver"
                        saveButtonCaption= "Evet"
                        closeButtonCaption='Hayır'
                   >
                  
                    
                    <p>{this.state.activePay + " TL pey vermek istediğinize emin misiniz?"} </p>
                    <p>Vereceğiniz pey sonucu teklifiniz <strong>{parseFloat(this.state.pCurrentPrice)<parseFloat(this.state.pOpeningPrice) ? this.numberFormat(this.state.pOpeningPrice + parseFloat(this.state.activePay)) : this.numberFormat(this.state.pCurrentPrice + parseFloat(this.state.activePay))} TL</strong> olacaktır</p>
                   </ModalForm> 
                        : 
                        null 
                    }

                    {this.state.activeModal===-1 && this.state.isOpen  ? 
                    <ModalForm 
                        activeModal={this.state.activeModal}
                        closeModal={this.closeModal} 
                        isOpen={this.state.isOpen} 
                        onHide={this.closeModal}
                        handleSubmit={this.handleSubmit}
                        aria-labelledby="contained-modal-title-vcenter"
                        title="Hemen Al"
                        saveButtonCaption= "Evet"
                        closeButtonCaption='Hayır'
                   >
                    <p>Ürünü {this.numberFormat(this.state.pBuyNowPrice)} TL karşılığında hemen almak istediğinize emin misiniz?</p>

                   </ModalForm> 
                        : 
                        null 
                    }
                    <i className="fa fa-star fa-2x text-primary"></i>
                    <i className="fa fa-star fa-2x text-primary"></i>
                    <i className="fa fa-star fa-2x text-primary"></i>
                    <i className="fa fa-star fa-2x text-primary"></i>
                    <i className="fa fa-star fa-2x text-muted"></i>
                    <span className="pl-2 fa fa-2x"><h5>(109 değerlendirme)</h5></span>
                    </h6>
                <hr />
 
            </div>
            <div className='col-md-12 text-left small'>


            <Tabs defaultActiveKey="1">
              <Tab eventKey="1" title="Ürün Açıklaması">
                <div id="product-description" className="tab-item-wrapper p-2">
                <p>
                    {this.state.pDescription}
                </p>


                </div>
              </Tab>

              <Tab eventKey="2" title="Özellikler">
                <div className="tab-item-wrapper p-2">

                </div>
              </Tab>

              <Tab eventKey="3" title="Soru & Cevap">
                <div className="tab-item-wrapper p-2">
                        <p className="text-center p-5 font-weight-bold">Henüz sorulmuş bir soru bulunmamaktadır</p>
                </div>
              </Tab>

              <Tab eventKey="4" title="Satıcı Yorumları">
                <div className="tab-item-wrapper">
                <br />

                            <div className="chat-body no-padding profile-message">
                                <ul>
                                    <li className="message">
                                        <img src="https://bootdey.com/img/Content/avatar/avatar1.png" className="online rounded-circle p-1" />
                                        <span className="message-text">
                                            <a href="javascript:void(0);" className="username">
                                                Ertan SAYGI
                                                <span className="badge">Ürünü Onayladı</span>
                                                <span className="pull-right">
                                                    <i className="fa fa-star fa-2x text-primary"></i>
                                                    <i className="fa fa-star fa-2x text-primary"></i>
                                                    <i className="fa fa-star fa-2x text-primary"></i>
                                                    <i className="fa fa-star fa-2x text-primary"></i>
                                                    <i className="fa fa-star fa-2x text-muted"></i>
                                                </span>
                                            </a>
                                            <br/>
                                        Satıcıdan çok memnunum. Ürünü güzelce paketlemiş kargo falan da iyiydi.                                        </span>
                                        <ul className="list-inline font-xs">
                                            <li>
                                                <a href="javascript:void(0);" className="text-info"><i className="fa fa-thumbs-up"></i> Bu bilgi yardımcı oldu (22)</a>
                                            </li>
                                            <li className="pull-right">
                                                <small className="text-muted pull-right ultra-light"> 1 ay önce </small>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="message">
                                        <img src="https://bootdey.com/img/Content/avatar/avatar2.png" className="online rounded-circle p-1" />
                                        <span className="message-text">
                                            <a href="javascript:void(0);" className="username">
                                    Müslüm Gürses                                                
                                    <span className="badge">Ürünü Onayladı</span>
                                                <span className="pull-right">
                                                    <i className="fa fa-star fa-2x text-primary"></i>
                                                    <i className="fa fa-star fa-2x text-primary"></i>
                                                    <i className="fa fa-star fa-2x text-primary"></i>
                                                    <i className="fa fa-star fa-2x text-primary"></i>
                                                    <i className="fa fa-star fa-2x text-primary"></i>
                                                </span>
                                            </a>
                                            <br/>
                                            Mükemmel teslimat, hızlı servis
                                        </span>
                                        <ul className="list-inline font-xs">
                                            <li>
                                                <a href="javascript:void(0);" className="text-info"><i className="fa fa-thumbs-up"></i> Bu bilgi yardımcı oldu  (22)</a>
                                            </li>
                                            <li className="pull-right">
                                                <small className="text-muted pull-right ultra-light"> 1 yıl önce </small>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                </div>
              </Tab>

            </Tabs>
            </div>

            </div>
            </div>
        </div> )
        }
    }
    
export default ProductDetailsChild;