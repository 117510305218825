import React, {Component} from 'react';
import {showToast} from '../components/utils';
import Header from '../parts/header';
import FooterSection from '../parts/footersection';
import TopBanner from '../parts/topbanner';

export class RemindPassword extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }
      state = {
        email:'',
      };
      handleChange(event) {
        const { name, value } = event.target;
        this.setState({
          [name]: value
        });
      }
    
      handleSubmit = event => {
        event.preventDefault();
        var formData = new FormData()
        var retype = 'bg-success';
        formData.append('email', this.state.email)

        fetch(this.props.apiurl + '/api/forgot-password', {
            method: 'POST',
            body: formData
          })
          .then((response) => {
            if(!response.ok) {
              retype = 'bg-danger';
              return response.json();
            }
            else{
              retype = 'bg-success';
              return response.json();
            } 
          })
          .then((responseData) => {
             showToast(responseData.message, retype);
           })
    
          .catch((error) => {
            showToast(error, "bg-danger");});
      };
    
      
    render() {
        document.title="Karşıkomşum- Şifremi Unuttum"
        return (
          <div className="App">
          <TopBanner />
          <Header apiurl={this.props.apiurl}/> 
            <div className="container  p-3">
              <div className="row justify-content-center text-left">
                      <div className="col-lg-8 col-md-10 card p-3">
                          <h3>Şifrenizi mi unuttunuz?</h3>
                          <p>Şifrenizi üç basit aşamada değiştirebilirsiniz.</p>
                          <ol className="list-unstyled">
                              <li><span className="text-primary text-medium">1. </span>E-posta adresinizi aşağıdaki kutucuğa girin</li>
                              <li><span className="text-primary text-medium">2. </span>E-posta adresinize geçici bir link göndereceğiz</li>
                              <li><span className="text-primary text-medium">3. </span>Şifrenizi değiştirmek için gönderilen linki takip etmeniz gerekmektedir.</li>
                          </ol>
                          <form className="card" onSubmit={this.handleSubmit}>
                              <div className="card-body">
                                  <div className="form-group">
                                      <label for="email">E-posta adresiniz</label>
                                      <input className="form-control" type="text" name="email" id="email" onChange={this.handleChange} required /><small className="form-text text-muted">Üyelik kaydı için kullandığınız e-posta adresinizi girin. 'Yeni Şifre Al' butonuna bastığınızda, belirttiğiniz e-posta adresine bir kod göndereceğiz.</small>
                                  </div>
                              </div>
                              <div className="card-footer">
                                  <button className="btn btn-blue float-right" type="submit">Yeni Şifre Al</button>
                              </div>
                          </form>
                      </div>
                  </div>
          </div>
    <FooterSection />
    </div>
        )
    }
}

export default RemindPassword;