import React, {Component} from 'react';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import {showToast} from '../components/utils';
import Header from '../parts/header';
import FooterSection from '../parts/footersection';
import TopBanner from '../parts/topbanner';

export class VerifyEmail extends Component {
  constructor(props) {
    super(props);
    
    //const id = new URLSearchParams(search).get('id');
    
    this.state = {
        message:'',
      }
  }

  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
  componentDidMount() {
    const expires = this.getParameterByName('expires');
    const signature = this.getParameterByName('signature');
    const { cookies } = this.props;
    var retype = 'bg-success';
   // alert(cookies.get('access-token'))
    //setInterval(this.getSiteSettingsData(), 1000);
    fetch(this.props.apiurl + '/api/email/verify/' + this.props.id + '/' + this.props.hash + '?expires=' + expires + '&signature=' + signature, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookies.get('access-token') ,
        }
      })
      .then((response) => {
        if(!response.ok) {
          if (response.status===401)  {
            throw new Error('Çıkış yapmanız için üye girişi yapmış olmalısınız')
          }
          else {          
            throw new Error(response.status + ':' + response.statusText)
          }
        }
        else{
          return response.json();
        } 
      })
      .then((responseData) => {
        showToast(responseData.message, retype);
        })

      .catch((error) => {
        showToast('Bir hata oluştu. ' + error);
      });
  }
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

    render() {

        document.title="Karşıkomşum- Eposta Doğrulama"
        return (
          <div className="App">
          <TopBanner />
          <Header apiurl={this.props.apiurl}/> 
        <div className="container mb-4 mt-4 pt-3">
        <div className="page-404">
                <div className="outer">
                    <div className="middle">
                        <div className="inner">
                            <span className="inner-status card">{this.state.authVerifyUrl} </span>
                            <span className="inner-detail">
                                <a href="/" className="btn btn-info mtl m-3"><i className="fa fa-home"></i>&nbsp;
                                    Profil Sayfanıza gidin
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FooterSection />
        </div>
        
        )
    }
}

export default withCookies(VerifyEmail);