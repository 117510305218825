import React from 'react';
import SearchResults from './searchresults';

class SearchBox extends React.Component {
  
  state = {
    sozluk: [
      {'taşınabilir bilgisayar':'laptop', 'core':'çekirdek'}
    ],
    products: [
       'Lenovo IdeaPad 3 Intel Core i7 1065G7 8GB 512GB SSD Freedos 14 FHD Taşınabilir Bilgisayar 81WD00FFTX',
       'Asus FX505DT-HN536 AMD Ryzen 7 3750H 8GB 512GB SSD GTX1650 Freedos 15.6 FHD Taşınabilir Bilgisayar', 
       'Apple MacBook Air Intel Core i5 5350U 8GB 128GB SSD MacOS Sierra 13.3 Taşınabilir Bilgisayar MQD32TU/A', 
       'Apple MacBook Pro Touchbar Intel Core i5 8250U 8GB 128GB SSD 13.3 macOS Taşınabilir Bilgisayar MUHN2TU/A Space Grey', 
       'HomeTech Alfa 590S Intel Core i5 5257U 8GB 256 GB SSD Windows 10 Home 15.6 FHD Taşınabilir Bilgisayar', 
       'Acer Swift SF314-42 AMD Ryzen 3 4300U 8GB 128GB SSD Linux 14 FHD Taşınabilir Bilgisayar NX.HSEEY.006', 
       'Casper Excalibur G770.1030-BUJ0X Intel Core i5 10300H 16GB 240GB M2 SSD GTX1650Ti FreeDos 15.6 FHD Taşınabilir Bilgisayar', 
       'MSI GL65 Leopard 10SDR-412XTR Intel Core i7 10750H 16GB 256GB SSD GTX1660Ti Freedos 15.6 FHD Taşınabilir Bilgisayar', 
       'Samsung Galaxy Tab A 8 SM-T290 32GB Tablet Siyah', 
       'Casper Via S48 8" 32GB IPS Tablet', 
    ],
    searchTerm: '',
    hideSearch: false,
    sResult: []
  }


  editSearchBox = (e) => {
    this.setState({hideSearch: (e.target.value!=='')});
    this.setState({searchTerm: e.target.value});
  }

  dynaSearch = () => {
      const sWords = this.state.searchTerm.toLowerCase().split(' ')
      const searchPattern = new RegExp(sWords.map(result => `(?=.*${result})`).join(''), 'i')
      var countTypes= [];
      return (
        countTypes = this.state.products.filter(result => result.toLowerCase().match(searchPattern))
        //this.state.results.filter(result => result.toLowerCase().includes(this.state.searchTerm.toLowerCase()))
      )
    }

    render(){
      return (
        <div className="my-auto mx-auto">
          <form id="search-form" className="form-inline" method="get" action="arama">
              <div className="input-group col-md-12 rounded">
                  <input name="q" type="search" autoComplete="off" className="col-md-12 form-control search-form mr-sm-2" value={this.state.searchTerm} onChange={this.editSearchBox} placeholder = 'Ürün, kategori veya marka ara' required/>
                  <span className="">
                    <button type="submit" className="btn btn-blue search-btn" data-target="#search-form"><i className="fa fa-search"></i>
                    </button>
                  </span>
              </div>
          </form>
          {this.state.hideSearch && <SearchResults results = {this.dynaSearch()}/>} 
        </div>
      );
    }
}

export default SearchBox;