import React, {Component} from 'react';
export class TopBanner extends Component {
    /*className="d-none"*/
        render(){
            return(
                <div id="bannerdiv" className="d-none">
                    <img className="card-img-top img-fluid" src="./assets/images/banner.jpg" alt=""/>
                </div>
        )
    }
}

export default TopBanner;