import React, {Component} from 'react';
import SalesFormMaster from "../components/salesformmaster.js";
export class SalesPage extends Component {
        
     state = {
          url: this.props.apiurl + '/api/categories',
          isShown: false,
          activedivid: 0,
          categories: []
      }


      componentDidMount() {
          fetch(this.state.url)
          .then(res => res.json())
          .then((data) => {
            this.setState({ categories: data })
          })

          .catch(err => {
              console.log(err);
              //this.myToast("Teknik bir problemimiz var.<br/> Veri alınamadı.<br/> Problem detayı:" + err);
          }) 
        }

      goster(e, divtoshow) {
          this.setState({isShown:e});
          this.setState({activedivid:divtoshow});
      }

    render() {
        document.title="Karşıkomşum- Satış Yap"
        return (
    <div className="container bg-white shadow-lg mb-4 mt-4 pt-3 rounded">
              <SalesFormMaster categories={this.state.categories} apiurl={this.props.apiurl} />
    </div>
        )
    }
}

export default SalesPage;