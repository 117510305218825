import React, {Component} from 'react';
class Stars extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
      }

    yildizla() {
        const stars = [];
        const doluyildiz = "far fa-star";
        const bosyildiz = "fas fa-star";
        for (var i = 1; i <= 5; i++) {
            (i > this.props.rating) ? stars.push(doluyildiz) : stars.push(bosyildiz);
         }
        return stars;
    }

    render() {
        return (
            <div>{this.yildizla().map(resultingStar => <li className={resultingStar}></li>) }</div>
        )
    }

}


export default Stars;
