import React, {Component} from 'react';
import MezatSayaci from '../components/mezatsayaci';
import Stars from '../components/stars';
import {urltemizle} from '../components/utils';

class Product extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
      }

      numberFormat = (value) => {
        return new Intl.NumberFormat('tr-TR', {
          maximumSignificantDigits: 2
        }).format(value);
      }
    render() {
        return (

<div className="product-grid h-100 rounded" id={this.props.productid}>
                <div className="product-image">
                    <a href={urltemizle("mezat/" + this.props.productslug)} className="image">
                    
                    {this.props.images && this.props.images.map((image, index) =>
                        <img className={"pic-" + index}  src={this.props.apiurl + image.public_url} alt={this.props.productname}/>
                    )}

                    </a>
                    {this.props.salelabel!=='' &&
                    (<span className= {"product-sale-label " + this.props.badgetype}>{this.props.salelabel}</span>)
                    }                                      
                <ul className="social">
                    <li><a href={urltemizle("mezat/" + this.props.productslug)} data-tip="İncele"><i className="fa fa-eye"></i></a></li>
                </ul>
                <div className="product-rating">
                    <Stars rating={this.props.rating}></Stars>
                    <p className="text-sm">{this.props.seller}</p>
                    <div id={"product-pay" + this.props.productid}>
                            <div className="product-pay p-1">
                                <div className="">
                                Pey Ver: 
                                    
                                        <a className="btn btn-success" href="#">20 ₺ </a>
                                        <a className="btn btn-success" href="#">50 ₺ </a>
                                        <a className="btn btn-success" href="#">70 ₺ </a>
                                        <a className="btn btn-success" href="#">100 ₺ </a>
                                    
                                </div>
                                
                            </div>
                        <div>
                             <div>
                                <a href="#" className="btn  btn-sm btn-block">Hemen Al ({this.numberFormat(this.props.buynowprice) + ' ₺'})</a>
                            </div>
                        </div>
                    </div>
                </div>           
                
                </div>

                <div className="product-content">
                    <h3 className=""><a href={urltemizle("mezat/" + this.props.productslug)}>{this.props.productname}</a></h3>

                    <div className="row mx-auto p-3">
                        <div className="text-center mx-auto"><div>Mevcut Fiyat</div><div>{this.numberFormat(this.props.currentprice) + ' ₺'}</div></div>
                        
                        <div className="text-center mx-auto"><div>Açılış Fiyatı</div><div>
                        {(this.props.openingprice!='0' && this.props.openingprice!=null) ? this.numberFormat(this.props.openingprice) + ' ₺': 'Yok'}
                        </div></div>
                    </div>
                    <MezatSayaci id={this.props.productid} endtime={this.props.endtime} placement="card"></MezatSayaci>
                </div>
            </div>
        )

    }
}

export default Product;
