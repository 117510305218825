import React, {Component} from 'react';

import Slider from "react-slick";


export class CardCarouselWithThumb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }
      render(){
  
      const settings = {
        dots: false,
        infinite: true,
        centerMode: true,
        speed: 500,
        swipeToSlide: true,
        slidesToShow: this.props.slidesToShow,
        slidesToScroll: this.props.slidesToScroll,
        autoplay: true,
        autoplaySpeed: 5000
      };
        return(      
        <div>
            <Slider asNavFor={this.state.nav2}
          ref={slider => (this.slider1 = slider)} {...settings}>
              {this.props.children}
            </Slider>
            <Slider
                  asNavFor={this.state.nav1}
                  ref={slider => (this.slider2 = slider)}
                  slidesToShow={3}
                  swipeToSlide={true}
                  focusOnSelect={true}
                >
                  {this.props.children}
                </Slider>
        </div>        

        
        )
    }
}

export default CardCarouselWithThumb;

