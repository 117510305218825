import React, { Component } from 'react';
import './searchbar.css';

class SearchBar extends Component {
 
    render() {
        return (
            <div className="searchbar">
                <div className="searchIcon"><i class="fas fa-search"></i></div><input name="q" placeholder="Kişi ya da konu arayın" className="searchInput"/>
            </div>
        )
    }
}

export default SearchBar;