import React, {Component} from 'react';
import Header from '../parts/header';
import FooterSection from '../parts/footersection';
import TopBanner from '../parts/topbanner';

export class NotAuthorized extends Component {
    
    render() {
        return (
        <div className="App">
          <TopBanner />
          <Header apiurl={this.props.apiurl}/> 
            <div className="container bg-white shadow-lg mb-2 mt-2 rounded">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="">
                                <div className="card-body cart">
                                    <div className="col-sm-12 empty-cart-cls text-center"> 
                                        <h2><i className="text-danger fas fa-lock"></i></h2>
                                        <h5><strong>Giriş yapmalısınız</strong></h5>
                                        <h3>Erişmek istediğiniz sayfaya ulaşmak için giriş yapmanız gerekmektedir</h3> 
                                        <a href="/girisyap" className="btn btn-green cart-btn-transform m-3" data-abc="true">Giriş Yap</a><a href="/kayitol" className="btn btn-blue m-3">Kayıt Ol</a>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            <FooterSection />
        </div>
        )
    }
}

export default NotAuthorized;