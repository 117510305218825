import React, {Component} from 'react';
import '../assets/css/registration.css';
import ModalForm from '../components/modalform';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import {showToast} from '../components/utils';
import Header from '../parts/header';
import FooterSection from '../parts/footersection';
import TopBanner from '../parts/topbanner';

export class Registration extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  
  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {
      passwordShown: false,
      password2Shown: false,
      isOpen: false,
      activeModal: null,
      ad: cookies.get('ad') || null,
      soyad: cookies.get('soyad') || null,
      adres: cookies.get('adres') || null,
      username: "",
      email: "",
      password: "",
      password_confirmation: "",
      isLoading: "",
      msg: "",
    }
    
  }
  
  componentDidMount(){
    if ((this.state.ad===null) || (this.state.soyad===null) || (this.state.adres===null)) {
      window.location="/";
    }
  }
  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value});
  }
  
  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false, activeModal: null });
  handleSubmit = (name) =>  this.setState({ isOpen: false });
 
  clickHandler(e, index) {
        this.setState({isOpen: true , activeModal: index})
  }

  togglePasswordVisibility = () => {
    this.state.passwordShown ? this.setState({passwordShown:false}) : this.setState({passwordShown:true}) 
  }

  togglePassword2Visibility = () => {
    this.state.password2Shown ? this.setState({password2Shown:false}) : this.setState({password2Shown:true}) 
  }

  handleFormSubmit = (event) => {
    event.preventDefault();
    var retype = 'bg-success';
    const url = new URL(document.referrer)
    this.setState({ isLoading: true });
    const { cookies } = this.props;
    
    if (document.getElementById("password").value==document.getElementById("password_confirmation").value) {
      if ((document.getElementById("reg_accept_membership").checked==true) && (document.getElementById("reg_accept_privacy").checked==true) ){
        
        fetch(this.props.apiurl + '/api/register', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          
          body: JSON.stringify({
            "username": this.state.username,
            "email": this.state.email,
            "first_name": this.state.ad,
            "last_name": this.state.soyad,          
            "address": this.state.adres,
            "password": this.state.password,
            "password_confirmation": this.state.password_confirmation,
          })
        })
        .then((response) => {
          if(!response.ok) {
            retype = 'bg-danger';
            return response.json();
          }
          else{
            retype = 'bg-success';
            return response.json();
          } 
        })
        .then((responseData) => {
          cookies.set('access-token', responseData.token, { path: '/' });
           showToast(responseData.message, retype);
          })
  
        .catch((error) => {
          console.log('error: ' + error);
        });
        
        this.setState({ isLoading: false });
      
      } else{
        showToast("Kayıt yapabilmeniz için üyelik ve gizlilik sözleşmelerini kabul etmeniz gerekiyor", "bg-danger");
      }
    } else {
      showToast("Şifre ve Şifre tekrar alanları birbirine eşit değil", "bg-danger");
    }
  }
    render() {
        document.title="Karşıkomşum- Yeni Üyelik Kaydı"
        
        return (
          <div className="App">
          <TopBanner />
          <Header apiurl={this.props.apiurl}/> 
        <div className="container mb-4 mt-4"> 

          <div className="row text-left">

              <div className="col-md-4"></div>
              <div className="col-md-5">
                <div className="card cardbox">
                  <div className="card-header text-center"><h2>Kayıt Ol</h2></div>
                  <div className="card-body">
                
                    
                    <div className="form-group">
                      
                      <form id="login-nav" method="post" role="form" className="form" onSubmit={this.handleFormSubmit} accept-charset="UTF-8">

                        <div className="form-group">
                          <label className="sr-only">Kullanıcı Adınız</label>
                          <input type="text" id="username" name="username" className="form-control"
                          placeholder="Kullanıcı Adınız" onChange={this.handleChange} required/>
                        </div>
                        
                        
                        <div className="form-group">
                          
                          
                          <label className="sr-only">Şifre</label>
                          
                          <div className="input-group">
                            
                            <input type={this.state.passwordShown ? "text" : "password"} id="password" name="password" className="form-control" placeholder="Şifre" onChange={this.handleChange} required/>

                            <div className="input-group-append">
                              <button className="btn btn-outline-secondary" type="button" id="button-append1" onClick={this.togglePasswordVisibility}>
                                <i className="fa fa-eye" aria-hidden="true"></i>
                              </button>
                            </div>
                          </div>
                          
                          <div className="progress mt-1" id="reg-password-strength">
                              <div id="password-strength" className="progress-bar progress-bar-success" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100">
                              </div>
                            </div>
                          
                          <div className="help-block text-right">
                            <small><a href="/sifremiunuttum">Şifremi Unuttum</a></small>
                            <span id="reg-password-quality" className="hide pull-left block-help">
                            <small>Şifre <span id="reg-password-quality-result"></span></small>
                            </span>
                          </div>       
                          
                          <div id="reg_passwordrules" className="hide password-rule mt-2"><small>

                            <ul className="list-unstyled">
                              <li className="">
                                <span className="eight-character"><i className="fa fa-check-circle" aria-hidden="true"></i></span>
                                &nbsp; en az 8 karakter</li>
                              <li className="">
                                <span className="low-upper-case"><i className="fa fa-check-circle" aria-hidden="true"></i></span>
                                &nbsp; en az 1 büyük & 1 küçük harf</li>
                              <li className="">
                                <span className="one-number"><i className="fa fa-check-circle" aria-hidden="true"></i></span>
                                &nbsp; en az 1 rakam</li>
                            </ul>
                            </small></div>

                        </div>
                        
                        
                        <div className="form-group">

                          
                          <label className="sr-only">Şifre (Tekrar)</label>
                          
                          <div className="input-group">
                            <input type={this.state.password2Shown ? "text" : "password"} name="password_confirmation" id="password_confirmation" className="form-control" placeholder="Şifre (Tekrar)" onChange={this.handleChange}  required/>

                            <div className="input-group-append">
                              <button className="btn btn-outline-secondary" type="button" id="button-append2" onClick={this.togglePassword2Visibility}>
                                <i className="fa fa-eye" aria-hidden="true"></i>
                              </button>
                            </div>
                            
                          </div>
                          
                          <div className="help-block text-right">
                            <small><span id="error-confirmpassword" className="hide pull-right block-help">
                            <i className="fa fa-info-circle text-danger" aria-hidden="true"></i>Şifre ve şifre tekrar aynı değil</span></small>
                          </div>

                        </div>
                    
                  
                        <div className="form-group">
                          <label className="sr-only">E-posta Adresiniz</label>
                          <input type="email" id="email" name="email" className="form-control" onChange={this.handleChange} placeholder="xxx@xxx.com" required/>
                        </div>


                        <div className="form-group">
                          <input type="submit" id="reg_submit" name="submit" value="Kayıt Ol" className="btn btn-block btn-blue"/>
                        </div>
                      


                        <div className="form-check">
                          <input type="checkbox" id="reg_accept_membership" name="user_accept_membership" className="form-check-input" value="2"/>
                          <label className="form-check-label" for="reg_accept_membership"><a href="javascript:void(0);" onClick={e => this.clickHandler(e, 0)}>Üyelik Sözleşmesini</a> okudum ve kabul ediyorum</label>
                        </div>
                        <div className="form-check">
                          <input type="checkbox" id="reg_accept_privacy" name="user_accept_privacy" className="form-check-input" value="3"/>
                          <label className="form-check-label" for="reg_accept_privacy"><a href="javascript:void(0);" onClick={e => this.clickHandler(e, 1)}>Gizlilik Sözleşmesini</a> okudum ve kabul ediyorum</label>
                        </div>
                        {this.state.activeModal===0 && this.state.isOpen  ? 
                              <ModalForm 
                                  activeModal={this.activeModal}
                                  closeModal={this.closeModal} 
                                  isOpen={this.state.isOpen} 
                                  onHide={this.closeModal}
                                  handleSubmit={this.handleSubmit}
                                  aria-labelledby="contained-modal-title-vcenter"
                                  title="Üyelik Sözleşmesi"
                                  saveButtonCaption= "Okudum ve Kabul Ediyorum"
                                  closeButtonCaption='Kapat'
                            >
                          <h3>SİTE KULLANIM ŞARTLARI</h3>
          <p>Lütfen sitemizi kullanmadan evvel bu ‘site kullanım şartları’nı dikkatlice okuyunuz.
          Bu alışveriş sitesini kullanan ve alışveriş yapan üyeler aşağıdaki şartları kabul etmiş sayılır.</p>
          <p>
          Sitemizdeki web sayfaları ve ona bağlı tüm sayfalar www.karsikomsum.com (‘site’)  <strong>COBEE BİLİŞİM ve
          MÜHENDİSLİK ARACILIK HİZMETLERİ A.Ş</strong> firmasının (ARACI) malıdır ve onun tarafından işletilir. ARACI,
          Alıcı Üye (‘Alıcı Üye Kullanıcı’) ve Satıcı Üye (Satıcı Üye Kullanıcı) (ÜYELER) sitede sunulan tüm
          hizmetleri kullanırken aşağıdaki şartlara tabi olduğunu, sitedeki hizmetten yararlanmakla ve kullanmaya
          devam etmekle; Bağlı olunan yasalara göre sözleşme imzalama hakkına, yetkisine ve hukuki ehliyetine
          sahip ve 16 yaşın üzerinde olduğunu, bu sözleşmeyi okuduğunu ve anladığını kabul ve beyan eder.
          İşbu sözleşme, taraflara sözleşme konusu site ile ilgili hak ve yükümlülükler yükler ve taraflar işbu
          sözleşmeyi kabul ettiklerinde yazan şartlarla bağlı olduğunu kabul etmiş sayılır.Bahsi
          geçen hak ve yükümlülükleri eksiksiz, doğru, zamanında, işbu sözleşmede talep edilen şartlar
          dâhilinde yerine getireceklerini beyan ederler.</p>

          <h3>1. SORUMLULUKLAR</h3>
          <p><strong>a.</strong> ARACI, fiyatlar ve sunulan ürün ve hizmetler üzerinde değişiklik yapma hakkını her zaman saklı
          tutar.</p>
          <p><strong>b.</strong> ARACI, üyelerin sözleşme konusu hizmetlerden, teknik arızalar dışında yararlandırılacağını
          kabul ve taahhüt eder.</p>
          <p><strong>c.</strong> Üyelerin, sitenin kullanımında tersine mühendislik yapmayacağını ya da bunların kaynak
          kodunu bulmak veya elde etmek amacına yönelik herhangi bir başka işlemde bulunmayacağını,
          aksi halde tespit edileceğini ve 3. Kişiler nezdinde doğacak zararlardan sorumlu olacağını,
          hakkında hukuki ve cezai işlem yapılacağını peşinen kabul eder.</p>
          <p><strong>d.</strong> Üyeler, site içindeki faaliyetlerinde, sitenin herhangi bir bölümünde veya iletişimlerinde genel
          ahlaka ve adaba aykırı, kanuna aykırı, 3. Kişilerin haklarını zedeleyen, yanıltıcı, saldırgan,
          müstehcen, pornografik, kişilik haklarını zedeleyen, telif haklarına aykırı, yasa dışı faaliyetleri
          teşvik eden içerikler üretmeyeceğini, paylaşmayacağını kabul eder. Aksi halde oluşacak zarardan
          tamamen kendisi sorumludur ve bu durumda ‘Site’ yetkilileri, bu tür hesapları askıya alabilir, sona
          erdirebilir, yasal süreç başlatma hakkını saklı tutar. Bu sebeple yargı mercilerinden etkinlik veya
          kullanıcı hesapları ile ilgili bilgi talepleri gelirse paylaşma hakkını saklı tutar.</p>
          <p><strong>e.</strong> Sitenin üyelerinin birbirleri veya üçüncü şahıslarla olan ilişkileri kendi sorumluluğundadır.</p>

          <h3>2. FİKRİ MÜLKİYET HAKLARI</h3>
          <p><strong>2.1.</strong> İşbu Site’de yer alan ünvan, işletme adı, marka, patent, logo, tasarım, bilgi ve yöntem gibi
          tescilli veya tescilsiz tüm fikri mülkiyet hakları site işletmesi ve/veya sahibi, aracı veya belirtilen ilgilisine
          ait olup, ulusal ve uluslararası hukukun koruması altındadır. İşbu Site’nin ziyaret edilmesi veya bu
          Site’deki hizmetlerden yararlanılması söz konusu fikri mülkiyet hakları konusunda hiçbir hak
          vermez.</p>
          <p><strong>2.2.</strong> Site’de yer alan bilgiler hiçbir şekilde çoğaltılamaz, yayınlanamaz, kopyalanamaz, sunulamaz
          ve/veya aktarılamaz. Site’nin bütünü veya bir kısmı diğer bir internet sitesinde izinsiz olarak
          kullanılamaz.</p>

          <h3>3. GİZLİ BİLGİ</h3>
          <p><strong>3.1.</strong> ARACI, site üzerinden kullanıcıların ilettiği kişisel bilgileri 3. Kişilere açıklamayacaktır. Bu
          kişisel bilgiler; kişi adı-soyadı, adresi, telefon numarası, cep telefonu, e-posta adresi , kredi kartı
          bilgileri gibi Kullanıcı’yı tanımlamaya yönelik her türlü diğer bilgiyi içermekte olup, kısaca ‘Gizli
          Bilgiler’ olarak anılacaktır.</p>
          <p><strong>3.2.</strong> İş bu üyelik sözleşmesi uyarınca, üyelerinin kendisinde kayıtlı elektronik posta adreslerine
          bilgilendirme mailleri ve cep telefonlarına bilgilendirme SMS'leri gönderme yetkisine sahip
          olmakla beraber, üye, işbu üyelik sözleşmesini onaylamasıyla beraber bilgilendirme
          maillerinin elektronik posta adresine ve bilgilendirme SMS'lerinin cep telefonuna
          gönderilmesini kabul etmiş sayılacaktır.</p>
          <p><strong>3.3.</strong> Aracı (site), sadece tanıtım, reklam, kampanya, promosyon, duyuru vb. pazarlama
          faaliyetleri kapsamında kullanılması ile sınırlı olmak üzere, Site’nin sahibi olan firmanın
          kendisine ait iletişim, portföy durumu ve demografik bilgilerini iştirakleri ya da bağlı bulunduğu
          grup şirketleri ile paylaşmasına muvafakat ettiğini kabul ve beyan eder. Bu kişisel bilgiler firma
          bünyesinde müşteri profili belirlemek, müşteri profiline uygun promosyon ve kampanyalar
          sunmak ve istatistiksel çalışmalar yapmak amacıyla kullanılabilecektir.</p>
          <p><strong>3.3.</strong> Gizli Bilgiler, ancak resmi makamlarca usulü dairelerinde bu bilgilerin talep edilmesi halinde ve
          yürürlükteki emredici mevzuat hükümleri gereğince resmi makamlara açıklama yapılmasının
          zorunlu olduğu durumlarda resmi makamlara açıklanabilecektir.</p>

          <h3>4. GARANTİ VERMEME</h3>
          <p>İŞBU SÖZLEŞME MADDESİ UYGULANABİLİR KANUNUN İZİN VERDİĞİ
          AZAMİ ÖLÇÜDE GEÇERLİ OLACAKTIR. FİRMA TARAFINDAN SUNULAN HİZMETLER
          "OLDUĞU GİBİ” VE "MÜMKÜN OLDUĞU” TEMELDE SUNULMAKTA VE PAZARLANABİLİRLİK,
          BELİRLİ BİR AMACA UYGUNLUK VEYA İHLAL ETMEME KONUSUNDA TÜM ZIMNİ
          GARANTİLER DE DÂHİL OLMAK ÜZERE HİZMETLER VEYA UYGULAMA İLE İLGİLİ OLARAK
          (BUNLARDA YER ALAN TÜM BİLGİLER DÂHİL) SARİH VEYA ZIMNİ, KANUNİ VEYA BAŞKA
          BİR NİTELİKTE HİÇBİR GARANTİDE BULUNMAMAKTADIR.</p>

          <h3>5. ÜRÜN TESLİMİ</h3>
          <p>Alıcı Üye’nin satın almış olduğu ürün/ürünler de Oluşabilecek Hasar/Arıza/Eksiklik Ve sorunlarda
          sorumluluk Satıcı Üye’ye Aittir. Bu gibi durumlarda ARACI Sorumlu Tutulamaz.</p>

          <h3>6. ÜRÜN YÖNETİMİ</h3>
          <p>ARACI Sitesinde Satışı sunulan ürün/ürünlerin doğruluğu ve gerçekliği Satıcı Üye’nin
          sorumuluğundadır.Satıcı Üye bilgileri Doğru ve Eksiksiz bildirmeli ve satış sonrası beyan edilen
          hallerde ürünün teslimini ARACI’nın Anlaşmalı Kargo şirketi aracılığı ile gerçekleştirmelidir.
          ARACI’nın aracılık hizmetleri dışında herhangi bir sorumluluğu yoktur. Üyeler arasında oluşacak
          ithilaflarda çözüm sağlanmaması durumunda yetkili mahkemelerde açılacak dava/davalarda
          ‘ARACI’ taraf tutulamaz.</p>

          <h3>7. ÜRÜN İADESİ</h3>
          <p>Alıcı tarafından ödemesi yapılmış ve adresine ulaştırılmış ürün/ürünlerin 14 (ondört) iş günü
          içerisinde iade sebebi belirtilerek ARACI’nın Anlaşmalı olduğu Kargo Şirketi ile Satıcı üyenin
          adresine iadesi yapılmalıdır. Bu durumların dışında yapılan iade işlemlerinin geçerliliği yoktur.</p>

          <h3>8. ÜRÜN SORUMLULUĞU</h3>
          <p>Satışı yapılan ürün/ürünlerin her türlü kayıp/çalıntı ve şüpheli işlemlerinde sorumluluk Alıcı Üye,
          Satıcı Üye ve Tutanak belgesi ibrazı şartıyla Anlaşmalı Kargo firmasındadır.
          Bu ve buna benzer durumlarda oluşacak zarar tazmini Başvurusu ARACI’dan Talep Edilemez.</p>

          <h3>9. ÖDEMELER</h3>
          ARACI üzerinden satışı gerçekleşen Ürün/ürünler ve hizmet bedelinin Satıcı Üye’ye Aktarımı şu
          şekillerde gerçekleşir.
          <p>9.1 Alıcı üye tarafından ödeme sonrası Ürün/ürünler ve hizmet Alıcı Üye’ye teslim edildikten
          sonra onay verilmesi durumunda,</p>
          <p>9.2 Ürün/ürünler ve hizmetin Alıcı Üye Tarafından onayı gelmemesi durumunda ürün teslimi
          sonrası 1 iş günü içerisinde otomatik onay sonrası,</p>
          <p>9.3 Ürün/ürünler ve hizmetin onayı sonrasında 1 ila 7 iş günü arasında satıcının beyan ettiği
          doğru İban Numarasına transferi gerçekleşir.</p>
          <p>9.4 Ürün/ürünler ve hizmetin iadesi söz konusu olduğunda ürün bedeli 9.1/9.2 maddeleri baz
          alınarak alıcı üye’ye akratılır.</p>

          <h3>10. SİPARİŞ, KAYIT ve GÜVENLİK</h3>
          <p>Alıcı üye,satış sonrası sepetine yansıtılan ürünün ödemesini 1 iş günü içerisinde yapmalıdır.
          Güncel kayıt bilgilerini, adres, Tc kimlik no, iletişim bilgileri gibi üyelik bilgilerini doğru, eksiksiz ve
          açık olarak vermek zorundadır. Aksi halde bu Sözleşme ihlal edilmiş sayılacak ve Kullanıcı
          bilgilendirilmeksizin üyelik kapatılabilecektir.</p>

          <p>10.1. Satıcı üye, satışa sunduğu ürünün bilgilerini ve açıklamalarını eksiksiz ve tam olarak,net
          çekilmiş resimlerle ihtilafa mahal vermeksizin sunmalıdır. Satıcı üye tarafından satışa sunulan
          ürünlerden doğacak ihtilaf ve anlaşmazlıklardan ARACI sorumlu tutulamaz.</p>
          <p>10.2. ARACI, Alıcı üye tarafından ödemesi yapılan ve alınan ürünlerin Alıcı Üye'nin ARACI'ya
          eksiksiz beyanı ile sorun ve problem arz edilmesi durumunda Satıcı üye'ye İhtar çekme ya da
          Satıcı üye'nin üyeliğini kapatma hakkı vardır.</p>
          <p>10.3. ARACI, Satıcı üyenin satışa sunduğu ürünü reddetme hakkına sahiptir.</p>
          <p>10.4. ARACI, Satıcı üyenin satışa sunduğu ürünü fiziksel olarak depo adresine ARACI'nın
          anlaşmalı olduğu kargo firması ile peşin ödemeli olarak gönderilmesini isteme hakkına sahiptir.
          Gönderimi sağlanan ürünün kargo aşamasında hasar görmesi durumunda sorumluluk Satıcı
          üye'ye Aittir.</p>

          <h3>11. MÜCBİR SEBEP</h3>
          <p>Tarafların kontrolünde olmayan; tabii afetler, yangın, patlamalar, iç savaşlar, savaşlar,
          ayaklanmalar, halk hareketlenmeleri, seferberlik ilanı, grev, lokavt ve salgın hastalıklar, altyapı ve
          internet arızaları, elektrik kesintisi gibi sebeplerden (aşağıda birlikte "Mücbir Sebep” olarak
          anılacaktır.) dolayı sözleşmeden doğan yükümlülükler taraflarca ifa edilemez hale gelirse, taraflar
          bundan sorumlu değildir. Bu sürede Taraflar’ın işbu Sözleşme’den doğan hak ve yükümlülükleri
          askıya alınır.</p>

          <h3>12. SÖZLEŞMENİN BÜTÜNLÜĞÜ VE UYGULANABİLİRLİK</h3>
          <p>İşbu sözleşme şartlarından biri, kısmen veya tamamen geçersiz hale gelirse, sözleşmenin geri
          kalanı geçerliliğini korumaya devam eder.</p>

          <h3>13. SÖZLEŞMEDE YAPILACAK DEĞİŞİKLİKLER</h3>
          <p>ARACI, dilediği zaman sitede sunulan hizmetleri ve işbu sözleşme şartlarını kısmen veya
          tamamen değiştirebilir. Değişiklikler sitede yayınlandığı tarihten itibaren geçerli olacaktır.
          Değişiklikleri takip etmek ÜYELERİN’in sorumluluğundadır. ÜYELER, sunulan hizmetlerden
          yararlanmaya devam etmekle bu değişiklikleri de kabul etmiş sayılır.</p>

          <h3>14. TEBLİGAT</h3>
          <p>İşbu Sözleşme ile ilgili taraflara gönderilecek olan tüm bildirimler, ÜYELER’in bilinen e-posta
          adresi ve kullanıcının üyelik formunda belirttiği e-posta ve gsm adresi vasıtasıyla yapılacaktır.
          üyeler, üye olurken belirttiği adresin geçerli tebligat adresi olduğunu, değişmesi durumunda 5 gün
          içinde yazılı olarak diğer tarafa bildireceğini, aksi halde bu adrese yapılacak tebligatların geçerli
          sayılacağını kabul eder.</p>

          <h3>15. DELİL SÖZLEŞMESİ</h3>
          <p>Taraflar arasında işbu sözleşme ile ilgili işlemler için çıkabilecek her türlü uyuşmazlıklarda
          Taraflar’ın defter, kayıt ve belgeleri ile ve bilgisayar kayıtları ve faks kayıtları 6100 sayılı Hukuk
          Muhakemeleri Kanunu uyarınca delil olarak kabul edilecek olup, kullanıcı bu kayıtlara itiraz
          etmeyeceğini kabul eder.</p>

          <h3>16. UYUŞMAZLIKLARIN ÇÖZÜMÜ</h3>
          <p>İşbu Sözleşme’nin uygulanmasından veya yorumlanmasından doğacak her türlü uyuşmazlığın
          çözümünde Ankara (Merkez) Adliyesi Mahkemeleri ve İcra Daireleri tam yetkilidir.</p>

          <h3>17. SÖZLEŞME FESHİ</h3>
          <p>İşbu sözleşme üyenin üyeliğini iptal etmesi veya Aracı tarafından üyeliğinin iptal edilmesine kadar
          yürürlükte kalacaktır. ARACI , Satıcı/Alıcı üyenin üyelik sözleşmesinin herhangi bir hükmünü ihlal
          etmesi durumunda üyenin üyeliğini iptal ederek sözleşmeyi tek taraflı olarak feshedebilecektir.</p>

                            </ModalForm> 
                                  : 
                                  null 
                              }
                        {this.state.activeModal===1 && this.state.isOpen  ? 
                              <ModalForm 
                                  activeModal={this.activeModal}
                                  closeModal={this.closeModal} 
                                  isOpen={this.state.isOpen} 
                                  onHide={this.closeModal}
                                  handleSubmit={this.handleSubmit}
                                  aria-labelledby="contained-modal-title-vcenter"
                                  title="Gizlilik Sözleşmesi"
                                  saveButtonCaption= "Okudum ve Kabul Ediyorum"
                                  closeButtonCaption='Kapat'
                            >

          <h3>İŞBU GİZLİLİK SÖZLEŞMESİ</h3>
          <p>“Sözleşme” ÜYELİK tarihinde yürürlüğe girecek (yürürlük tarihi) ve Üyelik süresi boyunca aşağıda yer alan
          taraflar arasında akdedilerek hükümlerini doğurmaya başlamıştır.</p>
          <h3>TARAFLAR</h3>
          <p>Bir tarafta, Ankara’da kurulmuş ve tescilli merkezi Ankara’da bulunan bir şirket olan COBEE BİLİŞİM VE ve
          MÜHENDİSLİK ARACILIK HİZMETLERİ A.Ş /www.karsikomsum.com (bundan böyle ŞİRKET olarak anılacaktır.)</p>
          <p>Diğer tarafta, www.karsikomsum.com’da Üyeliği Bulunan SATICI ÜYE (bundan böyle ÜYE 1 olarak anılacaktır.)</p>
          <p>Diğer tarafta, www.karsikomsum.com’da Üyeliği Bulunan ALICI ÜYE (bundan böyle ÜYE 2 olarak anılacaktır.)</p>
          <h3>GİRİŞ</h3>
          <p>ŞİRKET , ÜYE 1 ve ÜYE 2'nin KİŞİSEL VERİLERİN KULLANILMASI VE İŞLENMESİ amaçlarına yönelik olarak
          teknik ve tescilli bilgilerin paylaşılmasını kabul etmişlerdir.</p>
          <p>Taraflar birbirileri ile olan temasları sırasında birbirlerine Madde 1’de açıklanan ve kamuya açıklanmasını, umumi
          bilgi haline gelmesini, üçüncü kişilere ifşa edilmesini ve kullanımına izin verilmesini istemedikleri “Gizli Bilgi”yi
          açıklayabilirler ve; Bu sebeple, işbu sözleşmenin ayrılmaz bir parçasını teşkil eden yukarıdaki giriş bölümü
          uyarınca, Taraflar aşağıdaki hususlarda mutabakata varmışlardır:</p>
          <h3>GİZLİ BİLGİ</h3>
          <p>1. Gizli Bilgi kamuya açıklanmayan ve Taraflara da aralarında gerçekleştirecekleri bir temas ya da anlaşma gereği
          açıklanacak olan, tüm veri, örnek, teknik bilgi, ticarileştirme, araştırma stratejisi, ticari sırlar ve know-how da dahil
          tüm bilgiler olarak tanımlanmaktadır.</p>
          <p>1.1. Gizli Bilgi herhangi bir sınırlama olmaksızın aşağıdakileri kapsamaktadır:Amaçla bağlantılı
          olarak diğer Tarafa açıklanan yazılı ve sözlü tüm bilgi, fikir, tahminler;</p>
          – Taraflara arasındaki konuşmalar, tartışmalar, görüşmeler ya da toplantılar ve yazışmalar ile sözlü olarak mübadele
          edilen tüm bilgiler;<br/>
          – Her iki tarafça hazırlanmış tüm analiz, derleme, çalışma, teklif, ve diğer belgeler;<br/>
          – Tüm ticari anlaşmalar veya taraflar arasında akdedilen anlaşmalar, gizli bilgi alışverişini içeren sözleşmeler<br/>
          – Şirket satış ve şirket ortaklık sözleşmeleri ile diğer ilgili sözleşmeler<br/><br/>
          <p>1.2. Bununla birlikte, Taraflardan her biri aşağıdaki hallerde Gizli Bilgiyi tamamen ya da kısmen ifşa edebilir ya da
          kullanabilir:</p>
          – Gizli bilginin işbu sözleşmenin ihlali veya kusur dışındaki bir sebebe binaen kamunun bilgisine dahil olması;<br/>
          – Gizli bilgiyi alan tarafın söz konusu bilgiye diğer tarafça ifşa edilmeden önce sahip olduğunu kanıtlaması
          durumunda;<br/>
          – Bilginin; alan tarafın diğer taraftan alınan söz konusu Gizli Bilgiye doğrudan ya da dolaylı olarak erişme yetkisi
          olmayan çalışanları veya temsilcileri tarafından bağımsız olarak geliştirilmesi durumunda;<br/>
          – Taraflardan birinin, yetkili mahkemenin veya resmi ya da idari makamın kararı, yürürlükte bulunan kanun veya
          yönetmelik gereği ifşa etmekle yükümlü olması durumunda; bunun için, bu tür bir ifşanın önlenmesi amacıyla gerekli
          tüm yasal ve makul önlemlerin alınmış olması ve bilginin lehine tescil edildiği Tarafa ifşa ile yükümlü olan tarafın
          ifşasından önce uygun bir koruyucu ihtiyati tedbire başvurmasına imkan verecek kadar yeterli bir süre içinde ihbarda
          bulunulması gerekmektedir.<br/><br/>

          <h3>GİZLİLİĞE İLİŞKİN YÜKÜMLÜLÜKLER</h3>
          <p>2. İşbu Sözleşmenin imzalanması ile Taraflardan her biri Gizli nitelikteki tüm bilgileri kesinlikle özel ve gizli tutmayı,
          bunu bir sır saklama yükümü olarak addetmeyi ve gizliliğin sağlanması ve sürdürülmesi, Gizli Bilginin veya herhangi bir
          kısmının kamu alanına girmesini veya ifşa eden tarafın yazlı muvafakatini gerektiren bilgiyi alan tarafın çalışanları hariç
          üçüncü bir kişiye ifşasını önlemek için gerekli tüm tedbirleri almayı ve gerekli özeni göstermeyi taahhüt etmişlerdir.</p>
          <p>2.1. Bununla birlikte, Taraflardan her biri işbu Sözleşme ile açıkça:</p>
          <p>a) Gizli Bilgiyi her ne sebeple olursa olsun, doğrudan ya da dolaylı olarak kendisinin ya da herhangi bir üçüncü kişinin
          yararına kullanmamayı, ifşa eden Tarafça izin verilen amaç dışındaki bir amaç için kullanılmasına izin vermemeyi,</p>
          <p>b) Hiçbir Gizli Bilgiyi, herhangi bir üçüncü kişiye, firmaya, acentaya veya kuruma açıklamamayı, rapor etmemeyi,
          yayınlamamayı veya ifşa etmemeyi veyahut işbu maddenin hangi şekilde olursa olsun böyle bir ifşadan kaçınmak için (c)
          fıkrasında belirtilenler hariç gerekli tüm hukuki ya da diğer tedbirleri alma</p>
          <p>c) Gizli Bilgiyi katı bir “bilinmesi gereklilik arz etme” temelinde ancak ifşa eden Tarafın açık yazılı muvafakati üzerine
          çalışanlara, vekil veya temsilcilere, bunların da en az işbu Sözleşmede yer alanlardan daha az katı olmayan yükümlülüklerle
          bağlı olmaları şartıyla açıklamayı taahhüt etmektedir.</p>
          <p>2.2. ÜYE veya onunla doğrudan ya da dolaylı olarak bağlantılı şirket veya kuruluşlar İşbu Sözleşmeyle:</p>
          <p>a) Hiçbir şekilde, ALICI/SATICI ÜYE’de çalışan veya geçmişte çalışmış veya İşbu Sözleşmenin hükümlerinin yürürlükte
          kaldığı süre içerisinde istifa eden veya işten çıkartılan işçileri kendisine transfer etmeyeceğini</p>
          <p>b) Hiçbir durumda ŞİRKET’in yazılı izni olmaksızın teknoloji transfer etmeyeceğini
          kabul ve taahhüt eder.</p>
          <h3>GİZLİ BİLGİNİN KOPYALANMASI</h3>
          <p>Taraflardan her biri, açıkça, Gizli Bilginin ilgili tarafın önceden vereceği yazılı muvafakati olmaksızın tamamen veya kısmen
          kopyalanmayacağını taahhüt eder.</p>
          <h3>GİZLİ BİLGİNİN İADE EDİLMESİ</h3>
          <p>4. Taraflardan her biri, Sözleşmenin sona erdiği durumlarda, diğer yükümlülüklere halel gelmeksizin İşbu Sözleşme gereği
          aşağıdaki yükümlülüklerle bağlı olduğunu kabul eder:</p>
          <p>4.1.Gizli Bilgi içeren tüm belgeler İfşa eden tarafa ya da ifşa eden tarafça belirlenen diğer kişilere iade edilecektir.</p>
          <p>Bu tür belgelerin suretleri, ve ifşa eden tarafça veya onun adına ya da temsilcileri veya paragraf 2.2’de belirtilen kendilerine
          Gizli Bilgi açıklanan kişiler tarafından hazırlanan rapor, derleme, analiz, yorumlar imha edilecektir.</p>
          <p>Gizli Bilginin kaydedildiği bilgisayarda bulunan ve gizli bilgiyi alan taraf ya da temsilcileri ya da yukarıda bahsi geçen
          paragraf 2.2’de belirtilen kişiler tarafından bulundurulan suretleri silinecektir.</p>
          <h3>TAZMİN</h3>
          <p>5. Taraflardan her biri, işbu Sözleşme tarafından kendilerine yüklenen yükümlülüklerden herhangi birinin ihlali halinde,
          Gizli Bilginin iade edilmesine rağmen diğer tarafın sırf yukarıda bahsedilen yükümlülüklerin ihlal edilmesi sebebiyle önemli
          bir zarara uğrayabileceğini kabul eder. Bu sebeple, taraflardan her biri diğer tarafın uğradığı böyle bir zararı tamamen
          tazmin edeceğini taahhüt eder.</p>
          <p>5.1. Taraflardan her biri, Gizliliğe yönelik her tür tehdidi önleme veya devam eden gizliliğin bilgiyi alan tarafça ihlalini
          hukuki yollarla durdurma hakkına sahip olduklarını ve ihlalde bulunan taraf aleyhine karar elde edilmesi durumunda, söz
          konusu taraf diğer tarafın avuktlık ücreti de dahil olmak üzere yargılama masraf ve giderlerini tazmin edeceğini kabul eder</p>
          <h3>GİZLİLİĞE GİRMEYEN HÜKÜMLER</h3>
          <p>6. İş bu sözleşmesi uyarınca, üyelerinin kendisinde kayıtlı elektronik posta adreslerine bilgilendirme mailleri ve cep
          telefonlarına bilgilendirme SMS'leri gönderme yetkisine sahip olmakla beraber, üye işbu üyelik sözleşmesini onaylamasıyla
          beraber bilgilendirme maillerinin elektronik posta adresine ve bilgilendirme SMS'lerinin cep telefonuna gönderilmesini
          kabul etmiş sayılacaktır.</p>
          <p>6.1.Şirket, sadece tanıtım, reklam, kampanya, promosyon, duyuru vb. pazarlama faaliyetleri kapsamında kullanılması ile
          sınırlı olmak üzere, Site’nin sahibi olan firmanın kendisine ait iletişim, portföy durumu ve demografik bilgilerini iştirakleri
          ya da bağlı bulunduğu grup şirketleri ile paylaşmasına muvafakat ettiğini kabul ve beyan eder. Bu kişisel bilgiler firma
          bünyesinde müşteri profili belirlemek, müşteri profiline uygun promosyon ve kampanyalar sunmak ve istatistiksel
          çalışmalar yapmak amacıyla kullanılabilecektir.</p>

          <h3>SÖZLEŞME, İŞLEM VE GÖRÜŞMELERİN GİZLİLİĞİ</h3>
          <p>7. İşlemler ve işbu Sözleşme hükümleri ve gerçekleşecek görüşmelerin içerikleri kesinlikle gizli tutulacaktır.</p>
          <p>7.1. Umuma yapılacak ilanlara yönelik olarak ilgili Taraflar karşılıklı olarak karar verecektir.</p>
          <h3>SÜRE</h3>
          <p>8. İşbu Sözleşme yukarıda belirtilen imza (ÜYELİK) tarihinde yürürlüğe girecek (yürürlük tarihi) ve Üyelik süresi
          boyunca yürürlükte kalacaktır.</p>
          <p>8.1. İşbu Sözleşmenin sona ermesine rağmen, gizlilik yükümlülükleri, alan tarafa açıklanan Gizli Bilgi gizlilik özelliğini
          kaybedene kadar devam edecektir.</p>
          <h3>UYGULANACAK HUKUK</h3>
          <p>Yetkili mahkeme</p>
          <p>9. İşbu Sözleşmeden doğan tüm uyuşmazlık, iddia ve çatışmalara yönelik olarak Ankara Mahkemeleri ve icra daireleri
          yetkilidir.</p>
          <h3>İHBARLAR</h3>
          <p>10. İşbu Sözleşme uyarınca yapılacak tüm ihbarlar yazılı olarak aşağıdaki usullerden birine göre yapılacaktır:</p>
          <p>a) Kurye veya iadeli taahhütlü mektup ile tarafların aşağıda belirtilen ihbar adreslerine gönderilecek;</p>
          <p>b) Fax, elektronik posta yolu ile gönderilerek, kurye veya iadeli taahhütlü mektup ile teyid edilecektir. Bu durumda ihbarlar
          nihai olarak adreste bulunan tarafından imzalanmış posta alındısının üstünde yer alan tarihten daha geç olmayan bir tarihte
          alınmış kabul edilecektir.</p>
          <h3>ÇEŞİTLİ HÜKÜMLER</h3>
          <p>11. Taraflardan her biri, işbu Sözleşmeyle birbirlerine açıkladıkları bilginin tam ve doğru olduğunu beyan ve taahhüt
          ederler. İşbu paragrafta belirtilen yükümlülüğü ihlal eden Taraf, diğer tarafa verdiği zarar ve ziyanı tazmin edecektir.</p>
          <p>11.1. İşbu Sözleşmenin hükümlerine yazılı bir sözleşme olmaksızın atıfta veya ilavede bulunulamaz.</p>
          <p>11.2. İşbu Sözleşmede yar alan taahhütler Tarafların doğrudan ya da dolaylı kontrolü altında bulunan ya da kontrolü altında
          bulunduğu şirket ve gruplar ve bunların vekil ve halefleri için de bağlayıcıdır.</p>




                            </ModalForm> 
                                  : 
                                  null 
                              }
                      </form>
                    </div>
                
                    <div className="login-or"><hr className="hr-or"/></div>

                    <div className="bottom text-center">
                      Zaten Kullanıcı mısınız? <a href="/girisyap"><b>Giriş Yap</b></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <FooterSection />
        </div>
        )
    }
}

export default withCookies(Registration);