import React, {Component} from 'react';

import Slider from "react-slick";


export class CardCarousel extends Component {
  constructor(props) {
    super(props);
  }
      render(){
  
      const settings = {
        customPaging: function(i) {
          return (
            <a>
              <span class="dot"></span>
            </a>
          );
        },
        dots: true,
        infinite: true,
        speed: 500,
        swipeToSlide: true,
        slidesToShow: this.props.slidesToShow,
        slidesToScroll: this.props.slidesToScroll,
        autoplay: true,
        autoplaySpeed: 5000
      };
        return(      
        <div>
            <Slider {...settings}>
              {this.props.children}
            </Slider>
        </div>
        
        )
    }
}

export default CardCarousel;

