import React, {Component} from 'react';
import '../assets/css/vurgular.css'
export class  Vurgular extends Component {
        render(){
            const BottomBtnLabel = "";//Daha fazla bilgi
            return(
                <section className="container pt-3">
                    <div className="row pt-5 mt-30">
                        <div className="col-sm-4 mb-30 pb-5">
                            <a className="actioncard h-100" href="#">
                                <div className="box-shadow bg-white rounded-circle mx-auto text-center altgolge"><i className="fa fa-street-view fa-3x head-icon"></i></div>
                                <div className="card-body text-center">
                                    <h3 className="actioncard-title pt-1">Kolay</h3>
                                    <p className="actioncard-text text-md">Komşularınızla etkileşime geçmek için en kolay yol</p><span className="text-sm text-uppercase font-weight-bold">{BottomBtnLabel}&nbsp;<i className="fe-icon-arrow-right"></i></span>
                                </div>
                            </a>
                        </div>
                        <div className="col-sm-4 mb- pb-5">
                            <a className="actioncard h-100" href="#">
                                <div className="box-shadow bg-white rounded-circle mx-auto text-center altgolge"><i className="fa fa-award fa-3x head-icon"></i></div>
                                <div className="card-body text-center">
                                    <h3 className="actioncard-title pt-1">Tamamen Ücretsiz</h3>
                                    <p className="actioncard-text text-md">KarşıKomşum'a üyelik ve bireysel kullanım tamamen ücretsizdir ve hep öyle kalacaktır.</p><span className="text-sm text-uppercase font-weight-bold">{BottomBtnLabel}&nbsp;<i className="fe-icon-arrow-right"></i></span>
                                </div>
                            </a>
                        </div>
                        <div className="col-sm-4 mb-30 pb-5">
                            <a className="actioncard h-100" href="#">
                                <div className="box-shadow bg-white rounded-circle mx-auto text-center altgolge"><i className="fa fa-shield-alt fa-3x head-icon"></i></div>
                                <div className="card-body text-center">
                                    <h3 className="actioncard-title pt-1">Güvenli</h3>
                                    <p className="actioncard-text text-md">Mernis adres doğrulama kontrolü sayesinde güvenli</p><span className="text-sm text-uppercase font-weight-bold">{BottomBtnLabel}&nbsp;<i className="fe-icon-arrow-right"></i></span>
                                </div>
                            </a>
                        </div>
                        <div className="col-sm-4 mb-30 pb-5">
                            
                        </div>
                        <div className="col-sm-4 mb- pb-5">
                            <a className="actioncard h-100" href="#">
                                <div className="box-shadow bg-white rounded-circle mx-auto text-center altgolge"><i className="fa fa-map-marker-alt fa-3x head-icon"></i></div>
                                <div className="card-body text-center">
                                    <h3 className="actioncard-title pt-1">Yerel</h3>
                                    <p className="actioncard-text text-md">Yerel servis ve hizmet sağlayıcılara erişim için en hızlı yol</p><span className="text-sm text-uppercase font-weight-bold">{BottomBtnLabel}&nbsp;<i className="fe-icon-arrow-right"></i></span>
                                </div>
                            </a>
                        </div>
                        
                        <div className="col-sm-4 mb-30 pb-5">
                            
                        </div>

                    </div>
                </section>
        )
    }
}
export default Vurgular;