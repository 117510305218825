import React, {Component} from 'react';
import Vurgular from './vurgular';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";

import {showToast} from '../components/utils';

export class MainPageNotLoggedIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      ad: '',
      soyad: '',
      adres:''
    }
  }
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value});
  }
  handleSubmit = (event) => {
    //
    const { cookies } = this.props;
    cookies.set('ad', this.state.ad, { path: '/' });
    cookies.set('soyad', this.state.soyad, { path: '/' });
    cookies.set('adres', this.state.adres, { path: '/' });
    event.preventDefault();
    window.location="/kayitol";
  }
  
    render(){
    return (
        <div className="container-fluid bg-light shadow-lg p-0 mb-2">
            <div className="col-md-12 bgmain p-0 m-0" style={{ backgroundImage: "url(/assets/images/bgmainpage.webp)", backgroundRepeat:"no-repeat", minHeight:'500px' }}>
              <div className="mainpageregdiv">
                <div className="whiteslogan p-1 text-center"><i>Komşularınızla bağlantı kurmak için ücretsiz üye olun</i></div>
                <div>
                  <p>Komşularınızla sohbet etmek mi istiyorsunuz?</p>
                  <p>Yoksa tatile giderken akvaryumunuzu kime emanet edeceğinizi mi düşünüyorsunuz?</p>
                  <p>Tüm bunlar ve daha fazlası için </p>
                  <p><input name="ad" id="ad" className="col-md-4" placeholder="Adınız" onChange={this.handleChange}/>
                  <input name="soyad" id="soyad" className="col-md-4" placeholder="Soyadınız"  onChange={this.handleChange}/></p>
                  <p><input name="adres" id="adres" className="col-md-8" placeholder="Adresiniz" onChange={this.handleChange}/></p>
                  <p><a href="kayitol" className="btn btn-lg btn-green" onClick={this.handleSubmit}>Ücretsiz Üye Ol</a></p><br/>
                  
                </div>

              </div>
                
            </div>
            <div>
                <Vurgular/>     
            </div>
            <div>

            </div>
        </div>
        )
    }
}

export default withCookies(MainPageNotLoggedIn);