import React from "react";
//import { FormGroup, Label, Input } from "reactstrap";
import '../assets/css/richtext.css';


const Step2 = props => {
  if (props.currentStep !== 2) {
    return null;
  }

  function handleContentEdit(e) {
    e.preventDefault();
    var commandName = e.target.getAttribute("data-command");
    document.execCommand(commandName, false);
  }
  
  return (
    <>
      <form>
        <div className="text-left p-1">Ürün Başlığı</div>
        <div><input className="form-control"
          type="text"
          name="urunismi"
          id="urunismi"
          placeholder="Ürün başlığını girin"
          value={props.urunismi} // Prop: The username input data
          onChange={props.handleChange} // Prop: Puts data into the state
        /></div>
        <div className="text-left p-1">Kısa Açıklama</div>
        <div><textarea rows="2" className="form-control"
      id="summary" 
      name="summary"
      onChange={props.handleChange} 
      value={props.summary}
      placeholder="Ürün için kısa açıklama girin">
      </textarea>
      </div>
      <div className="text-left p-1">Açıklama</div>
<div>
  <div className="toolbar">
    <ul className="tool-list">
      <li className="tool">
        <button onClick={handleContentEdit}
          className="tool--btn" 
          data-command="bold"
          title='Kalın'
          >
          <i className=' fas fa-bold'></i>
        </button>
      </li>
      <li className="tool">
        <button onClick={handleContentEdit}
          className="tool--btn" 
          data-command="italic"
          title='İtalik'
          >
          <i className=' fas fa-italic'></i>
        </button>
      </li>
      <li className="tool">
        <button onClick={handleContentEdit}
          className="tool--btn" 
          data-command="underline"
          title='Altını Çiz'
          >
          <i className=' fas fa-underline'></i>
        </button>
      </li>
      <li className="tool">
        <button onClick={handleContentEdit}
          className="tool--btn" 
          data-command="strikeThrough"
          title='Üstünü Çiz'
          >
          <i class="fas fa-strikethrough"></i>
        </button>
      </li>
      <li className="tool">
        <button onClick={handleContentEdit}
          className="tool--btn" 
          data-command='justifyLeft'
          title='Sola Yasla'
        >
          <i className=' fas fa-align-left'></i>
        </button>
      </li>
      <li className="tool">
        <button onClick={handleContentEdit}
          className="tool--btn" 
          data-command='justifyCenter' 
          title='Ortala'
          >
          <i className=' fas fa-align-center'></i>
        </button>
      </li>
      <li className="tool">
        <button onClick={handleContentEdit}
          className="tool--btn" 
          data-command='justifyRight' 
          title='Sağa Yasla'
          >
          <i className=' fas fa-align-right'></i>
        </button>
      </li>
      <li className="tool">
        <button onClick={handleContentEdit}
          className="tool--btn" 
          data-command="indent"
          title='Paragraf Girintisi'
          >
          <i class="fas fa-indent" ></i>
        </button>
      </li>
      <li className="tool">
        <button onClick={handleContentEdit}
          className="tool--btn" 
          data-command="outdent"
          title='Dışarı Çek'
          >
          <i class="fas fa-outdent"></i>
        </button>
      </li>
      <li className="tool">
        <button onClick={handleContentEdit}
          className="tool--btn" 
          data-command="insertOrderedList"
          title='Maddeli Liste'
          >
          <i className=' fas fa-list-ol'></i>
        </button>
      </li>
      <li className="tool">
        <button onClick={handleContentEdit}
          className="tool--btn" 
          data-command="insertUnorderedList"
          title='Noktalı Liste'
          >
          <i className=' fas fa-list-ul'></i>
        </button>
      </li>
      <li className="tool">
        <button onClick={handleContentEdit}
          className="tool--btn" 
          data-command="insertHorizontalRule"
          title='Yatay çizgi çek'
          >
          <strong>-</strong>
        </button>
      </li>
            
      <li className="tool">
        <button onClick={handleContentEdit}
          className="tool--btn" 
          data-command="removeFormat"
          title='Biçimlendirmeyi kaldır'
          >
          <i class="fas fa-eraser"></i>
        </button>
      </li>
      <li className="tool">
        <button onClick={handleContentEdit}
          className="tool--btn" 
          data-command="undo"
          title='Geri Al'
          >
          <i className=' fas fa-undo'></i>
        </button>
      </li>
      <li className="tool">
        <button onClick={handleContentEdit}
          className="tool--btn" 
          data-command="redo"
          title='İleri Git'
          >
          <i className=' fas fa-redo'></i>
        </button>
      </li>

    </ul>
  </div>

        <div id="description" name='description' onInput={props.handleEditableContent} className="form-control text-left" contenteditable="true"></div>

</div>

      </form>
    </>
  );
};

export default Step2;
