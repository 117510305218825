import React, {Component} from 'react';

export class Odeme extends Component {
    
    render() {
        document.title="Karşıkomşum- Ödeme"
        return (
            <div className="container bg-white shadow-lg mb-2 mt-2 rounded">

            </div>

        )
    }
}

export default Odeme;