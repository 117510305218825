import React, { Component } from 'react';
import './rightmenu.css';

class RightMenu extends Component {
 
    render() {
        return (
            <div className="rightmenu  position-sticky pt-1">
                <div className="card text-center mt-2 mb-2">
                    <span className="text-muted p-2 small">Sponsorlu Bağlantı</span>
                    <a href="/redirect?http://www.tadilat.com.tr">
                        <img className="rounded img-fluid text-center" src="/assets/images/sponsored.jpg"/>
                        </a>
                </div>
            </div>
        )
    }
}

export default RightMenu;