import React, {Component} from 'react';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import Header from '../parts/header';
import FooterSection from '../parts/footersection';
import TopBanner from '../parts/topbanner';
              /*<div className="form-check pb-2 text-left text-muted">
                <input type="checkbox" id="reg_remember" name="user_remember" className="form-check-input" value="1"/>
                <label className="form-check-label" for="reg_remember">Beni Hatırla</label>
              </div>*/
export class LogoutPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
        authCheckUrl: this.props.apiurl + '/api/logout',
        isLoggedIn: false,
        message:'',
      }
  }
  componentDidMount() {
    const { cookies } = this.props;
   // alert(cookies.get('access-token'))
    //setInterval(this.getSiteSettingsData(), 1000);
    fetch(this.state.authCheckUrl, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookies.get('access-token') ,
        }
      })
      .then((response) => {
        if(!response.ok) {
          if (response.status===401)  {
            throw new Error('Çıkış yapmanız için üye girişi yapmış olmalısınız')
          }
          else {          
            throw new Error(response.status)
          }
        }
        else{
          return response.json();
        } 
      })
      .then((responseData) => {
            this.setState({isLoggedIn:true,  message:responseData.message});
        })

      .catch((error) => {
        this.setState({isLoggedIn:false, message: 'Bir hata oluştu. ' + error});
      });
  }
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

    render() {
        document.title="Karşıkomşum- Çıkış"
        return (
          <div className="App">
          <TopBanner />
          <Header apiurl={this.props.apiurl}/> 
            <div className="container mb-4 mt-4 pt-3">
            <div className="page-404">
                    <div className="outer">
                        <div className="middle">
                            <div className="inner">
                                <span className="inner-status card">{this.state.message} </span>
                                <span className="inner-detail">
                                    <a href="/" className="btn btn-info mtl m-3"><i className="fa fa-home"></i>&nbsp;
                                        Anasayfa'ya dön
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterSection />
        </div>
        
        )
    }
}

export default withCookies(LogoutPage);