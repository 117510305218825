export function urltemizle(uri) {
    let res = uri.replace(/ /g, '-');
    res = res.replace(/\)/g, '');
    res = res.replace(/\(/g, '');
    res = res.replace(/:/g, '');
    res = res.replace(/'/g, '-');
    res = res.replace(/"/g, '-');
    res = res.replace(/\./g, '-');
    res = res.replace(/</g, '');
    res = res.replace(/>/g, '');
    res = res.replace(/%3c/ig, '');
    res = res.replace(/%3e/ig, '');
    res = res.replace(/%2f/ig, '');
    res = res.replace(/ş/g, 's');
    res = res.replace(/ü/g, 'u');
    res = res.replace(/Ü/g, 'U');
    res = res.replace(/İ/g, 'I');
    res = res.replace(/ı/g, 'i');
    res = res.replace(/ç/g, 'c');
    res = res.replace(/Ç/g, 'C');
    res = res.replace(/ö/g, 'o');
    res = res.replace(/Ö/g, 'o');
    res = metindegistir(res);
    res = encodeURI(res);
    return (res);
  }

  
  export function metindegistir(metin) {
    let res = metin.replace(/alert/ig, '');
    res = res.replace(/redirect/ig, '');
    res = res.replace(/javascript/ig, '');
    res = res.replace(/href/ig, '');
    res = res.replace(/location/ig, '');
    res = res.replace(/script/ig, '');
    res = res.replace(/cookie/ig, '');
    res = res.replace(/function/ig, '');
    res = res.replace(/onLoad/ig, '');
    res = res.replace(/hacked/ig, '');
    res = res.replace(/hacklendi/ig, '');
    res = res.replace(/getelementbyid/ig, '');
    res = res.replace(/getelementbyclassname/ig, '');
    return (res);
  }

  export function tiretemizle (metin) {
    let res = metin.replace(/-/g, ' ');
    return (res);
  }


  export function showToast(e, t) {
    // Get the snackbar DIV
    var x = document.getElementById("snackbar");
  
    // Add the "show" class to DIV
    x.className = "show shadow-lg rounded " + t;
    x.innerHTML= e;
    // After 3 seconds, remove the show class from DIV
    setTimeout(function(){ x.className = x.className.replace("show", ""); }, 5000);
  }

export default urltemizle("");