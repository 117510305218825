import React, {Component} from 'react';

import CookieConsent, { Cookies } from "react-cookie-consent";
import NewPost from '../components/newpost/newpost';
import PostList from '../components/post/postlist';
import LeftMenu from '../components/menu/leftmenu';
import RightMenu from '../components/menu/rightmenu';



export class NewsFeed extends Component {
    constructor(props) {
        super(props);
      }
    /*componentDidMount() {
        fetch('https://www.youtube.com/watch?v=lEIIjn3MHSI', {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            }
          })
          .then((response) => {
              console.log(response);
          })
          .then((responseData) => {
                console.log(responseData);
                //this.setState({isLoggedIn:true,  message:responseData.message});
            })
          .catch((error) => {
              console.log(error);
          });
    }*/

    render(){
    return (
        <div className="container-fluid">
        <div className="row">
            <div className="col-md-3 d-none d-md-table">
                <LeftMenu/>
            </div>
            <div className="col-md-6">
                <NewPost apiurl={this.props.apiurl}/>
                <PostList apiurl={this.props.apiurl}/>
            </div>
            <div className="col-md-3">
                <RightMenu/>
            </div>
        </div>
        </div>
        )
    }
}

export default NewsFeed;