import React, {Component} from 'react';

export class MezatSayaci extends Component {

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

    render(){
      const deadline = new Date(Date.parse(new Date(this.props.endtime)));
      initializeClock(this.props.id, this.props.placement, deadline);
        return (
            <div className="clockdiv row justify-content-center" id={"clock" + this.props.id}>
                  <div className=''>
                    <span className="days"></span>
                    <div className="dayslabel smalltext">GÜN</div>
                  </div>
                  <div className=''>
                    <span className="hours"></span>
                    <div className="hourslabel smalltext">SAAT</div>
                  </div>
                  <div className=''>
                    <span className="minutes"></span>
                    <div className="smalltext">DK.</div>
                  </div>
                  <div className=''>
                    <span className="seconds"></span>
                    <div className="smalltext">SN.</div>
                  </div>
             </div>
        )
    

    function getTimeRemaining(endtime) {
        const total = (endtime) ? Date.parse(endtime) - Date.parse(new Date()):0;
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
        const days = Math.floor(total / (1000 * 60 * 60 * 24));
        
        return {
          total,
          days,
          hours,
          minutes,
          seconds
        };
      }
      
      function initializeClock(id, placement, endtime) {
        const timeinterval = setInterval(updateClock, 1000);
          function updateClock() {
            const clock = document.getElementById("clock"+ id);
            const productPay = document.getElementById("product-pay" + id);
            const productDetailStatus= document.getElementById("product-detail-status");
            const auctionStatus= document.getElementById("auctionstatus");
            const kalanSure= document.getElementById("kalansure");
            const t = getTimeRemaining(endtime);
            const daysSpan = clock && clock.querySelector('.days');
            const daysLabel = clock && clock.querySelector('.dayslabel');
            const hoursSpan = clock && clock.querySelector('.hours');
            //const hoursLabel = clock && clock.querySelector('.hourslabel');
            const minutesSpan = clock && clock.querySelector('.minutes');
            const secondsSpan = clock && clock.querySelector('.seconds');
              if (clock!==null) {
                if (t.days>0) {
                  daysSpan.innerHTML =  ('0' + t.days).slice(-2);
                } else {
                  if (daysSpan!==null) {
                    daysSpan.setAttribute("style","display:none");
                    daysLabel.setAttribute("style","display:none");
                  }
                }
                if (hoursSpan!==null) {
                  hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
                  minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);
                  secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);
                }
              }
              if (t.total <= 0) {
                clearInterval(timeinterval);
                clock.setAttribute("style", "background-color: #f43133; color: #ffffff");
                clock.innerHTML = "<span>Süre Bitti<br>Kazanan: H.S***</span>";
                if (placement==="card") {
                  productPay.innerHTML = "";
                } else {
                  if (placement==="details") {
                    productDetailStatus.setAttribute("style", "background-color: #f43133; color: #ffffff");
                    auctionStatus.innerHTML="<i className='fas fa-gavel'></i> Bu Mezat Sona Erdi";
                    kalanSure.innerHTML="";
                  }
                }
               
              } else {
                if (t.days===0 && t.hours===0 && t.minutes===0 && t.seconds<60) {
                  secondsSpan.setAttribute("style", "color: var(--firebrick2)");
                }
              }
          }
      }

    }

}

export default MezatSayaci;