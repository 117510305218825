import React, {Component} from 'react';
import {showToast} from '../components/utils';
import Header from '../parts/header';
import FooterSection from '../parts/footersection';
import TopBanner from '../parts/topbanner';

export class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }
      state = {
        password:'',
        password_confirmation:''
      };

      /*componentDidMount() {
       
      }*/

      togglePasswordVisibility = () => {
        this.state.passwordShown ? this.setState({passwordShown:false}) : this.setState({passwordShown:true}) 
      }
    
      togglePassword2Visibility = () => {
        this.state.password2Shown ? this.setState({password2Shown:false}) : this.setState({password2Shown:true}) 
      }


      handleChange(event) {
        const { name, value } = event.target;
        this.setState({
          [name]: value
        });
      }
    
      handleSubmit = event => {
        event.preventDefault();
        var formData = new FormData()
        var retype = 'bg-success';
        formData.append('email', this.props.email)
        formData.append('token', this.props.token)
        formData.append('password', this.state.password)
        formData.append('password_confirmation', this.state.password_confirmation)

        fetch(this.props.apiurl + '/api/reset-password', {
            method: 'POST',
            body: formData
          })
          .then((response) => {
            if(!response.ok) {
              retype = 'bg-danger';
              return response.json();
            }
            else{
              retype = 'bg-success';
              return response.json();
            } 
          })
          .then((responseData) => {
             showToast(responseData.message, retype);
             if (retype=='bg-success') {
               window.location='/girisyap';
             }
           })
    
          .catch((error) => {
            showToast(error, "bg-danger");});
      };
    
      
    render() {
        document.title="Karşıkomşum- Şifre Değiştir"
        return (
          <div className="App">
          <TopBanner />
          <Header apiurl={this.props.apiurl}/> 
        <div className="container  p-3">
          <div className="row justify-content-center text-left">
              <div className="col-sm-4 card p-3">
                    <h3>Yeni Şifrenizi Belirleyin</h3>
                    
                    <form className="card" onSubmit={this.handleSubmit}>
                        <div className="card-body">
                          
                            <div className="form-group">
                                <label className="sr-only">Yeni Şifreniz</label>
                                <div className="input-group">
                                  <input type={this.state.passwordShown ? "text" : "password"} id="reg_userpassword" name="password" className="form-control" placeholder="Şifre" onChange={this.handleChange} required/>
                                  <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button" id="button-append1" onClick={this.togglePasswordVisibility}>
                                      <i className="fa fa-eye" aria-hidden="true"></i>
                                    </button>
                                  </div>
                              </div>
                            </div>
                            <div className="form-group">
                                <label className="sr-only">Yeni Şifreniz (Tekrar)</label>
                                <div className="input-group">
                                  <input type={this.state.password2Shown ? "text" : "password"} id="reg_userpassword2" name="password_confirmation" className="form-control" placeholder="Şifre (Tekrar)" onChange={this.handleChange} required/>
                                  <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button" id="button-append1" onClick={this.togglePassword2Visibility}>
                                      <i className="fa fa-eye" aria-hidden="true"></i>
                                    </button>
                                  </div>
                              </div>
                            </div>
                          </div>
                        <div className="card-footer">
                            <button className="btn btn-blue float-right" type="submit">Yeni Şifremi Kaydet</button>
                        </div>
                    </form>
                </div>
            </div>
            </div>
            <FooterSection />
        </div>
        )
    }
}

export default ResetPassword;