import React, {Component} from 'react';
import CardCarousel from '../components/cardcarousel_eski.js';
import Stars from '../components/stars';
import MezatSayaci from '../components/mezatsayaci';
import {tiretemizle, urltemizle, metindegistir} from '../components/utils';
export class SearchResultDisplay extends Component {
    
    render() {
        document.title="Karşıkomşum- Arama Sonuçları"
        return (
            <div className="container mb-2 mt-2 rounded">
                <div className="row">
                    <div className="card col-md-3">

<section>

  <section>

    <span className="m-1"><h5>Alt Kategoriler</h5></span>

    <div className="text-muted small text-uppercase mb-5 text-left">
      
      <p className="mb-3"><a href="#!" className="card-link-secondary">Dizüstü Bilgisayar</a></p>
      <p className="mb-3"><a href="#!" className="card-link-secondary">Oyun Bilgisayarı</a></p>
      <p className="mb-3"><a href="#!" className="card-link-secondary">Yazıcılar</a></p>
      <p className="mb-3"><a href="#!" className="card-link-secondary">Kablolama</a></p>
      <p className="mb-3"><a href="#!" className="card-link-secondary">Soğutma</a></p>
      <p className="mb-3"><a href="#!" className="card-link-secondary">Ekran Kartları</a></p>

    </div>

  </section>

  <section>

    <section className="mb-4 text-left p-4">

      <h6 className="font-weight-bold mb-3">Kondüsyon</h6>

      <div className="form-check pl-0 mb-3">
        <input type="checkbox" className="form-check-input filled-in" id="new"/>
        <label className="form-check-label small text-uppercase card-link-secondary" for="new">Sıfır</label>
      </div>
      <div className="form-check pl-0 mb-3">
        <input type="checkbox" className="form-check-input filled-in" id="used"/>
        <label className="form-check-label small text-uppercase card-link-secondary" for="used">İkinci El</label>
      </div>
      <div className="form-check pl-0 mb-3">
        <input type="checkbox" className="form-check-input filled-in" id="collectible"/>
        <label className="form-check-label small text-uppercase card-link-secondary" for="collectible">Koleksiyon Ürünü</label>
      </div>
      <div className="form-check pl-0 mb-3 pb-1">
        <input type="checkbox" className="form-check-input filled-in" id="renewed"/>
        <label className="form-check-label small text-uppercase card-link-secondary" for="renewed">Kusurlu</label>
      </div>

    </section>

    <section className="mb-4">

      <h6 className="font-weight-bold mb-3">Ortalama Satıcı Reyting</h6>

      <a href="#!">
      <Stars rating='5'></Stars>
      </a>
      <a href="#!">
      <Stars rating='4'></Stars>
      </a>
      <a href="#!">
      <Stars rating='3'></Stars>
      </a>
      <a href="#!">
      <Stars rating='2'></Stars>
      </a>
      <a href="#!">
      <Stars rating='1'></Stars> 
      </a>

    </section>

    <section className="mb-4">

      <h6 className="font-weight-bold mb-3">Mevcut Fiyat</h6>

      <div className="form-check pl-0 mb-3">
        <input type="radio" className="form-check-input" id="under25" name="materialExampleRadios"/>
        <label className="form-check-label small text-uppercase card-link-secondary" for="under25">25 ₺ altı</label>
      </div>
      <div className="form-check pl-0 mb-3">
        <input type="radio" className="form-check-input" id="2550" name="materialExampleRadios"/>
        <label className="form-check-label small text-uppercase card-link-secondary" for="2550">25 ₺ - 50 ₺</label>
      </div>
      <div className="form-check pl-0 mb-3">
        <input type="radio" className="form-check-input" id="50100" name="materialExampleRadios"/>
        <label className="form-check-label small text-uppercase card-link-secondary" for="50100">50 ₺ - 100 ₺</label>
      </div>
      <div className="form-check pl-0 mb-3">
        <input type="radio" className="form-check-input" id="100200" name="materialExampleRadios"/>
        <label className="form-check-label small text-uppercase card-link-secondary" for="100200">100 ₺ - 200 ₺</label>
      </div>
      <div className="form-check pl-0 mb-3">
        <input type="radio" className="form-check-input" id="200above" name="materialExampleRadios"/>
        <label className="form-check-label small text-uppercase card-link-secondary" for="200above">200₺ ve üstü</label>
      </div>
      <form>
        <div className="d-flex align-items-center mt-4 pb-1">
          <div className="md-form md-outline my-0">
            <input id="from" type="text" className="form-control mb-0"/>
            <label for="form">Min ₺</label>
          </div>
          <p className="px-2 mb-0 text-muted"> - </p>
          <div className="md-form md-outline my-0">
            <input id="to" type="text" className="form-control mb-0"/>
            <label for="to">Max ₺</label>
          </div>
        </div>
      </form>

    </section>

    <section className="mb-4">

      <h6 className="font-weight-bold mb-3">Açılış Fiyatı</h6>

      <div className="slider-price d-flex align-items-center my-4">
        <span className="font-weight-normal small text-muted mr-2">0</span>
        <form className="multi-range-field w-100 mb-1">
          <input id="multi" className="multi-range" type="range" />
        </form>
        <span className="font-weight-normal small text-muted ml-2">999 ₺</span>
      </div>

    </section>

    <section className="m-4 text-left">

      <h6 className="font-weight-bold mb-3 ">RAM</h6>

      <div className="form-check pl-0 mb-3">
        <input type="checkbox" className="form-check-input filled-in" id="2"/>
        <label className="form-check-label small text-uppercase card-link-secondary" for="2">2 GB</label>
      </div>
      <div className="form-check pl-0 mb-3">
        <input type="checkbox" className="form-check-input filled-in" id="4"/>
        <label className="form-check-label small text-uppercase card-link-secondary" for="4">4 GB</label>
      </div>
      <div className="form-check pl-0 mb-3">
        <input type="checkbox" className="form-check-input filled-in" id="8"/>
        <label className="form-check-label small text-uppercase card-link-secondary" for="8">8 GB</label>
      </div>
      <div className="form-check pl-0 mb-3">
        <input type="checkbox" className="form-check-input filled-in" id="16"/>
        <label className="form-check-label small text-uppercase card-link-secondary" for="16">16 GB</label>
      </div>

    </section>


  </section>


</section>

                    </div>
                    <div className="col-md-9">
                    <div className="row mt-2 mb-2 pl-3 pb-1 pt-2"><h4>Arama Sonuçları</h4></div>
                        <div className="card card-body">
                            <div className="media align-items-center align-items-lg-start text-center text-lg-left flex-column flex-lg-row">
                                <div className="mr-2 mb-3 mb-lg-0"> <img src="https://i.imgur.com/5Aqgz7o.jpg" width="150" height="150" alt=""/> </div>
                                <div className="media-body">
                                    <h6 className="media-title font-weight-semibold"> <a href={urltemizle("mezat/Apple iPhone XR Red, 128 GB/" + this.props.productid)} data-abc="true">Apple iPhone XR (Red, 128 GB)</a> </h6>
                                    <ul className="list-inline list-inline-dotted mb-3 mb-lg-2">
                                        <li className="list-inline-item"><a href="#" className="text-muted" data-abc="true">Cep Telefonu</a></li>
                                        <li className="list-inline-item"><a href="#" className="text-muted" data-abc="true">Apple</a></li>
                                    </ul>
                                    <p className="mb-3">128 GB ROM | 15.49 cm (6.1 inch) Display 12MP Rear Camera | 7MP Front Camera A12 Bionic Chip İşlemci | Gorilla Glass</p>
                                    
                                    <ul className="list-inline list-inline-dotted mb-0">
                                        <li className="list-inline-item"><a href="#" data-abc="true">Mobile point</a> satıcısının tüm ürünleri</li>
                                    </ul>
                                    <div className="list-inline-item  ml-3 mt-3"><MezatSayaci id='3' endtime='2021-04-25 09:00:00'></MezatSayaci></div>
                                </div>
                                <div className="mt-3 mt-lg-0 ml-lg-3 text-center">
                                    <h3 className="mb-0 font-weight-semibold">459.99 ₺</h3>
                                    <div> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> </div>
                                    <div className="text-muted">198 oy</div> <a type="button" href="sepetim/dolu" className="btn btn-blue mt-4 text-white"><i className="icon-cart-add mr-2"></i>Hemen Al</a>
                                </div>
                            </div>
                        </div>
                        <div className="card card-body mt-3">
                            <div className="media align-items-center align-items-lg-start text-center text-lg-left flex-column flex-lg-row">
                                <div className="mr-2 mb-3 mb-lg-0"> <img src="https://i.imgur.com/Aj0L4Wa.jpg" width="150" height="150" alt=""/> </div>
                                <div className="media-body">
                                    <h6 className="media-title font-weight-semibold"> <a href={urltemizle("mezat/Apple iPhone XS Max Gold, 64 GB/" + this.props.productid)} data-abc="true">Apple iPhone XS Max (Gold, 64 GB)</a> </h6>
                                    <ul className="list-inline list-inline-dotted mb-3 mb-lg-2">
                                        <li className="list-inline-item"><a href="#" className="text-muted" data-abc="true">Cep Telefonu</a></li>
                                        <li className="list-inline-item"><a href="#" className="text-muted" data-abc="true">Apple</a></li>
                                    </ul>
                                    <p className="mb-3">256 GB ROM | 15.49 cm (6.1 inch) Display 12MP Rear Camera | 15MP Front Camera A12 Bionic Chip Processor | Gorilla Glass with high quality display </p>
                                    <ul className="list-inline list-inline-dotted mb-0">
                                        <li className="list-inline-item"><a href="#" data-abc="true">Mobile junction</a> satıcısının tüm ürünleri</li>

                                    </ul>
                                    <div className="list-inline-item  ml-3 mt-3"><MezatSayaci id='4' endtime='2021-04-28 09:00:00'></MezatSayaci></div>
                                </div>
                                <div className="mt-3 mt-lg-0 ml-lg-3 text-center">
                                    <h3 className="mb-0 font-weight-semibold">612.99 ₺</h3>
                                    <div> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> </div>
                                    <div className="text-muted">234 oy</div> <a href="sepetim/dolu" type="button" className="btn btn-blue mt-4 text-white"><i className="icon-cart-add mr-2"></i> Hemen Al</a>
                                </div>
                            </div>
                        </div>
                        <div className="card card-body mt-3">
                            <div className="media align-items-center align-items-lg-start text-center text-lg-left flex-column flex-lg-row">
                                <div className="mr-2 mb-3 mb-lg-0"> <img src="https://i.imgur.com/5Aqgz7o.jpg" width="150" height="150" alt=""/> </div>
                                <div className="media-body">
                                    <h6 className="media-title font-weight-semibold"> <a href="#" data-abc="true">Apple iPhone XR (Red, 128 GB)</a> </h6>
                                    <ul className="list-inline list-inline-dotted mb-3 mb-lg-2">
                                        <li className="list-inline-item"><a href="#" className="text-muted" data-abc="true">Cep Telefonu</a></li>
                                        <li className="list-inline-item"><a href="#" className="text-muted" data-abc="true">Apple</a></li>
                                    </ul>
                                    <p className="mb-3">128 GB ROM | 15.49 cm (6.1 inch) Display 12MP Rear Camera | 7MP Front Camera A12 Bionic Chip Processor | Gorilla Glass with high quality display </p>
                                    <ul className="list-inline list-inline-dotted mb-0">
                                        <li className="list-inline-item"><a href="#" data-abc="true">Mobile point</a> satıcısının tüm ürünler</li>
                                    </ul>
                                    <div className="list-inline-item  ml-3 mt-3"><MezatSayaci id='31' endtime='2021-04-25 09:00:00'></MezatSayaci></div>
                                </div>
                                <div className="mt-3 mt-lg-0 ml-lg-3 text-center">
                                    <h3 className="mb-0 font-weight-semibold">459.99 ₺</h3>
                                    <div> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> </div>
                                    <div className="text-muted">198 oy</div> <a href="sepetim/dolu" type="button" className="btn btn-blue mt-4 text-white"><i className="icon-cart-add mr-2"></i>Hemen Al</a>
                                </div>
                            </div>
                        </div>
                        <div className="card card-body mt-3">
                            <div className="media align-items-center align-items-lg-start text-center text-lg-left flex-column flex-lg-row">
                                <div className="mr-2 mb-3 mb-lg-0"> <img src="https://i.imgur.com/Aj0L4Wa.jpg" width="150" height="150" alt=""/> </div>
                                <div className="media-body">
                                    <h6 className="media-title font-weight-semibold"> <a href="#" data-abc="true">Apple iPhone XS Max (Gold, 64 GB)</a> </h6>
                                    <ul className="list-inline list-inline-dotted mb-3 mb-lg-2">
                                        <li className="list-inline-item"><a href="#" className="text-muted" data-abc="true">Cep Telefonu</a></li>
                                        <li className="list-inline-item"><a href="#" className="text-muted" data-abc="true">Apple</a></li>
                                    </ul>
                                    <p className="mb-3">256 GB ROM | 15.49 cm (6.1 inch) Display 12MP Rear Camera | 15MP Front Camera A12 Bionic Chip Processor | Gorilla Glass with high quality display </p>
                                    <ul className="list-inline list-inline-dotted mb-0">
                                        <li className="list-inline-item"><a href="#" data-abc="true">Mobile junction</a> satıcısının tüm ürünler</li>

                                    </ul>
                                    <div className="list-inline-item  ml-3 mt-3"><MezatSayaci id='13' endtime='2021-04-22 09:00:00'></MezatSayaci></div>
                                </div>
                                <div className="mt-3 mt-lg-0 ml-lg-3 text-center">
                                    <h3 className="mb-0 font-weight-semibold">612.99 ₺</h3>
                                    <div> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> </div>
                                    <div className="text-muted">239 oy</div> <a href="sepetim/dolu" type="button" className="btn btn-blue mt-4 text-white"><i className="icon-cart-add mr-2"></i> Hemen Al</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        )
    }
}

export default SearchResultDisplay;