import React, { Component } from "react";

import Step1 from "./salesformstep1";
import Step2 from "./salesformstep2";
import Step3 from "./salesformstep3";
import Step4 from "./salesformstep4";

import MultiStepProgressBar from "./multistepprogressbar";
import { Card } from 'react-bootstrap';
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";

class SalesFormMaster extends Component {
  constructor(props) {
    super(props);

    // Set the intiial input values
    
    // Bind the submission to handleChange()
    this.handleChange = this.handleChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleMainCategoryChange = this.handleMainCategoryChange.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handleSubCategoryChange = this.handleSubCategoryChange.bind(this);
    this.fileHandleChange = this.fileHandleChange.bind(this);
    this.handleEditableContent = this.handleEditableContent.bind(this);
    // Bind new functions for next and previous
    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);
  }
  state = {
    currentStep: 1,
    anakategori: "",
    kategori: "",
    altkategori: "",
    marka: "",
    model:"",
    seri: "",
    summary: "",
    description: "",
    urunismi: "",
    openingprice: 0,
    buynowprice: "",
    startdate: new Date(),
    enddate: new Date(),
    files: []
  };

  // Use the submitted data to set the state
  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  }

  handleEditableContent(e) {
    this.setState({description: e.currentTarget.innerHTML})
  }

  handleStartDateChange(date) {
    this.setState({
      startdate: date
    })
  }
  handleEndDateChange(date) {
    this.setState({
      enddate: date
    })
  }
  removeOptions(selectElement) {
    var i, L = selectElement.options.length - 1;
    for(i = L; i >= 0; i--) {
       selectElement.remove(i);
    }
  }
  addOptions(selectElement, id, value) {
      var option = document.createElement("option");
      option.value = id;
      option.text =value;
      selectElement.appendChild(option);
  }

  handleMainCategoryChange(event) {
    const { name, value } = event.target;
    this.setState({
      'anakategori': value
    });
    this.removeOptions(document.getElementById('kategori'));
    this.props.categories.map((cat=> {
      if (cat.parent==value) {
        return this.addOptions(document.getElementById('kategori'), cat.id, cat.title);
      } else {
        return null
      }
    }))
  }

  handleCategoryChange(event) {
    const { name, value } = event.target;
    this.setState({
      'kategori': value
    });
    this.removeOptions(document.getElementById('altkategori'));
    this.props.categories.map((cat=> {
      if (cat.parent==value) {
        return this.addOptions(document.getElementById('altkategori'), cat.id, cat.title);
      } else {
        return null
      }
    }))
  }

  handleSubCategoryChange(event) {
    const { name, value } = event.target;
    this.setState({
      'altkategori': value
    });
  }

  fileHandleChange(e) {
    this.setState({
      "files": e
    });
  }

  // Trigger an alert on form submission
  handleSubmit = event => {
    event.preventDefault();
    if (this.state.startdate>this.state.enddate) {
      alert("başlangıç tarihi, bitiş tarihinden küçük olamaz")
    }
    
      const { cookies } = this.props;

    var formData = new FormData()

    
    this.state.files.map((file, index) => {
      formData.append('images[]', file);
    });

    formData.append('name',  this.state.urunismi)
    formData.append('title', this.state.urunismi)
    formData.append('summary', this.state.summary)
    formData.append('category_1', this.state.anakategori)
    formData.append('category_2', this.state.kategori)
    formData.append('category_3', this.state.altkategori)
    formData.append('description', this.state.description)
    formData.append('buynow_price', this.state.buynowprice)
    formData.append('opening_price', this.state.openingprice)
    formData.append('start_time', this.state.startdate)
    formData.append('end_time', this.state.enddate)

    fetch(this.props.apiurl + '/api/addAuction', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + cookies.get('access-token') ,
        },
        body: formData
      })
      .then((response) => {
        if(!response.ok) {
          throw new Error(response.status)
        }
        else{
          return response.json();
        } 
      })
      .then((responseData) => {
          //cookies.set('access-token', responseData.token, { path: '/' });
          window.location='/mezat/' + responseData.slug;
       })

      .catch((error) => {
        alert(error);
        //console.log('error: ' + error);
      });
  };


  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  // Test current step with ternary
  // _next and _previous functions will be called on button click
  _next() {
    let currentStep = this.state.currentStep;

    // If the current step is 1 or 2, then add one on "next" button click
    currentStep = currentStep >= 3 ? 4 : currentStep + 1;
    this.setState({
      currentStep: currentStep
    });
  }

  _prev() {
    let currentStep = this.state.currentStep;

    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep
    });
  }

  // The "next" and "previous" button functions
  get previousButton() {
    let currentStep = this.state.currentStep;

    // If the current step is not 1, then render the "previous" button
    if (currentStep !== 1) {
      return (
        <a className="btn btn-secondary float-left" onClick={this._prev}>
          Önceki
        </a>
      );
    }

    // ...else return nothing
    return null;
  }

  get nextButton() {
    let currentStep = this.state.currentStep;
    // If the current step is not 3, then render the "next" button
    if (currentStep < 4) {
      return (
        <a className="btn btn-blue float-right" onClick={this._next}>
          Sonraki
        </a>
      );
    }
    // ...else render nothing
    return null;
  }

  get submitButton() {
    let currentStep = this.state.currentStep;

    // If the current step is the last step, then render the "submit" button
    if (currentStep > 3) {
      return <a className="btn btn-blue float-right" onClick={this.handleSubmit}>Kaydet</a>;
    }
    // ...else render nothing
    return null;
  }

  render() {
    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <Card>
            <Card.Header><h2>Satış Kaydı</h2></Card.Header>
            <Card.Body>
              <Card.Title >
                <MultiStepProgressBar currentStep={this.state.currentStep} />
              </Card.Title>
              <Card.Body />
              <Step1
                currentStep={this.state.currentStep}
                handleChange={this.handleChange}
                handleMainCategoryChange = {this.handleMainCategoryChange}
                handleCategoryChange = {this.handleCategoryChange}
                handleSubCategoryChange = {this.handleSubCategoryChange}
                handleEditableContent = {this.handleEditableContent}
                anakategori={this.state.anakategori}
                kategori={this.state.kategori}
                altkategori={this.state.altkategori}
                categories={this.props.categories}
                urunismi={this.state.urunismi}
                summary={this.state.summary}
                description={this.state.description}
                openingprice={this.state.openingprice}
                buynowprice={this.state.buynowprice}
                startdate={this.state.startdate}
                enddate={this.state.enddate}
                files={this.state.files}
              />
              <Step2
                currentStep={this.state.currentStep}
                handleChange={this.handleChange}
                handleEditableContent = {this.handleEditableContent}
                anakategori={this.state.anakategori}
                kategori={this.state.kategori}
                altkategori={this.state.altkategori}
                urunismi={this.state.urunismi}
                summary={this.state.summary}
                description={this.state.description}
                openingprice={this.state.openingprice}
                buynowprice={this.state.buynowprice}
                startdate={this.state.startdate}
                enddate={this.state.enddate}
                files={this.state.files}
              />
              <Step3
                currentStep={this.state.currentStep}
                anakategori={this.state.anakategori}
                kategori={this.state.kategori}
                altkategori={this.state.altkategori}
                urunismi={this.state.urunismi}
                summary={this.state.summary}
                description={this.state.description}
                openingprice={this.state.openingprice}
                buynowprice={this.state.buynowprice}
                startdate={this.state.startdate}
                enddate={this.state.enddate}
                handleChange={this.handleChange}
                handleStartDateChange={this.handleStartDateChange}
                handleEndDateChange={this.handleEndDateChange}
                handleEditableContent = {this.handleEditableContent}
                files={this.state.files}
              />
              <Step4
                currentStep={this.state.currentStep}
                fileHandleChange = {this.fileHandleChange}
                anakategori={this.state.anakategori}
                kategori={this.state.kategori}
                altkategori={this.state.altkategori}
                urunismi={this.state.urunismi}
                summary={this.state.summary}
                description={this.state.description}
                openingprice={this.state.openingprice}
                buynowprice={this.state.buynowprice}
                startdate={this.state.startdate}
                enddate={this.state.enddate}
                handleChange={this.handleChange}
                handleEditableContent = {this.handleEditableContent}
                files={this.state.files}
              />
            </Card.Body>
            <Card.Footer>
              {this.previousButton}
              {this.nextButton}
              {this.submitButton}
            </Card.Footer>
          </Card>
        </form>
      </>
    );
  }
}

export default withCookies(SalesFormMaster);
