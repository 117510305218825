import React, {Component} from 'react';
import {showToast} from '../components/utils';
import Header from '../parts/header';
import FooterSection from '../parts/footersection';
import TopBanner from '../parts/topbanner';

export class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  state = {
    name:'',
    email:'',
    message:'',
  };

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    var retype = 'btn-success';
    var formData = new FormData()
    formData.append('name',  this.state.name)
    formData.append('email', this.state.email)
    formData.append('message', this.state.message)

    fetch(this.props.apiurl + '/api/contactform', {
        method: 'POST',
        body: formData
      })
      .then((response) => {
        if(!response.ok) {
          retype = 'bg-danger';
          return response.json();
        }
        else{
          retype = 'btn-success';
          return response.json();
        } 
      })
      .then((responseData) => {
         showToast(responseData.message, retype);
         document.getElementById("send").disabled=true;
       })
      .catch((error) => {
        showToast(error, "bg-danger");
      });
  };

    render() {
        document.title="Karşıkomşum- İletişim"
        return (
          <div className="App">
          <TopBanner />
          <Header apiurl={this.props.apiurl}/> 
        <div className="container bg-white shadow-lg mb-4 mt-4 pt-3 rounded">
              <div className="row text-center">
              <div className="col-sm-5">
                  <div className="contact-detail-box">
                    <i className="fa fa-th fa-2x text-colored"></i>
                    <h4>Şirket Bilgileri</h4>
                    <div className="text-left ml-5"><b>Ünvan:</b> Cobee Bilişim Teknolojileri Sanayii ve Ticaret Anonim Şirketi<br/>
                    <b>Vergi No:</b> 2110679718<br/>
                    <b>Ticaret Sicil No:</b> 373879
                    </div>
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="contact-detail-box">
                    <i className="fa fa-map-marker fa-2x text-colored"></i>
                    <h4>Adresimiz</h4>
                    <address>
                    Umut Sokak No: 63<br/>
                    Beytepe Mahallesi<br/>
                    Çankaya/Ankara

                  </address>
                  </div>
                </div>

                <div className="col-sm-3">
                  <div className="contact-detail-box">
                    <i className="fa fa-phone fa-2x text-colored"></i>
                    <h4>iletişim</h4>
                    (+90) 312 995 01 11<br/>
                    <a href="mailto:info@karsikomsum.com" className="text-muted">info@karsikomsum.com</a>
                  </div>
                </div>

              </div>


              <div className="row text-left">

                <div className="col-sm-6">
                    <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="name2">Ad Soyad</label>
                      <input className="form-control" id="name2" name="name" type="text" placeholder="Adınız Soyadınız" onChange={this.handleChange} required/>
                      <div className="error d-none" id="err-name">Lütfen adınızı soyadınızı yazın</div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="email2">E-posta adresiniz</label>
                      <input className="form-control" id="email2" name="email" type="text" placeholder="E-posta adresiniz" onChange={this.handleChange}  required/>
                      <div className="error d-none" id="err-emailvld">Geçersiz E-posta adresi</div> 
                    </div>

                    <div className="form-group">
                      <label htmlFor="message2">Mesajınız</label>
                      <textarea className="form-control" id="message2" name="message" rows="5" placeholder="Mesaj içeriği" onChange={this.handleChange} required></textarea>

                      <div className="error d-none" id="err-message">Lütfen bir mesaj içeriği yazın</div>
                    </div>

                    <div className="row">            
                      <div className="col-12 text-right">
                        <div id="ajaxsuccess" className="text-success">E-posta başarılı şekilde gönderildi</div>
                        <div className="error" id="err-timedout">Sunucu ile bağlantı zamanaşımına uğradı</div>
                        <div className="error" id="err-state"></div>
                        <button type="submit" className="btn btn-blue btn-rounded" id="send">Gönder</button>
                      </div> 
                    </div>
                    </form>
                </div>

                <div className="col-sm-6 pr-0">
                  <div className="contact-map ">
                  <iframe title="harita" className="harita rounded" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3062.9589979010902!2d32.75004831474268!3d39.85275189767048!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d34a2fb3b4d289%3A0x68ccf1b13fc5aa05!2sCoBee%20Bilisim%20Teknolojileri%20A.S!5e0!3m2!1str!2str!4v1627548809434!5m2!1str!2str" allowfullscreen="" loading="lazy"></iframe>
                  </div>
                </div>

              </div>
                  
            </div>
            <FooterSection />
        </div>
        )
    }
}

export default ContactUs;