
import './assets/css/chunk6.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import './assets/css/App.css';
import './assets/css/chatbox.css';
import './assets/css/faq.css';

import './assets/css/contact.css';
import './assets/css/shoppingcart.css';
import './assets/css/404.css';
import './assets/css/index.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams
} from "react-router-dom";

import MainPage from './pages/mainpage';
import ContactUs from './pages/contactus';
import AboutUs from './pages/aboutus';
import Sepetim from './pages/sepetim';
import Odeme from './pages/odeme';
import Contracts from './pages/contracts';
import ProductDetails from './pages/productdetails';
import SearchResultDisplay from './pages/searchresultdisplay';
import Profil from './pages/profil';
import Registration from './pages/registration';
import NoMatch from './pages/nomatch';
import RedirectPage from './pages/redirectpage';
import RemindPassword from './pages/remindpassword';
import ResetPassword from './pages/resetpassword';
import LoginPage from './pages/loginpage';
import LogoutPage from './pages/logoutpage';
import SalesPage from './pages/salespage';
import VerifyEmail from './pages/verifyemail';
import {showToast} from './components/utils';
//import {Navigation} from 'react-minimal-side-navigation';
//import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';

import Echo from 'laravel-echo'

//window.Pusher = require('pusher-js');

/*window.Echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.REACT_APP_PUSHER_KEY,
  cluster: 'mt1',
  encrypted: false,
  forceTLS: false,
  wsHost: process.env.REACT_APP_WS_HOST,
  wsPort: process.env.REACT_APP_WS_PORT
});
*/
function App() {

  const productroot='product';
  //const APP_BACKEND_URL=process.env.REACT_APP_API_HOST;
  const APP_BACKEND_URL="http://api.karsikomsum.com"
  window.addEventListener('offline', function(e) {showToast("İnternet bağlantınız koptu. Sayfada işlem yapamazsınız",'bg-danger', 15000);});
  window.addEventListener('online', function(e) { showToast("İnternet bağlantınız düzeldi. Devam edebilirsiniz",'bg-success', 8000);});


  return (
    <Router>
      <Switch>      
      <Route exact path="/">
        <MainPage apiurl={APP_BACKEND_URL}/>
      </Route>
      <Route exact path="/satisyap">
        <SalesPage apiurl={APP_BACKEND_URL}/>
      </Route>
      <Route exact path="/sepetim">
        <Sepetim />
      </Route>
      <Route exact path="/odeme">
        <Odeme />
      </Route>
      <Route exact path="/arama">
        <SearchResultDisplay />
      </Route>
      <Route exact path="/hakkimizda">
        <AboutUs />
      </Route>
      <Route exact path="/iletisim">
        <ContactUs apiurl={APP_BACKEND_URL}/>
      </Route>
      <Route exact path={"/profil/:name/"} render={({match})=>(
        <Profil activetab={match.params.name} />
      )} />

      <Route exact path={["/sozlesmeler", "/sozlesmeler/:name"]} render={({match})=>(
        <Contracts activetab={match.params.name} apiurl={APP_BACKEND_URL}/>
      )} />

      <Route exact path="/kayitol">
        <Registration apiurl={APP_BACKEND_URL}/>
      </Route>
      <Route exact path="/girisyap">
        <LoginPage apiurl={APP_BACKEND_URL}/>
      </Route>
      <Route exact path="/cikis">
        <LogoutPage apiurl={APP_BACKEND_URL}/>
      </Route>
      <Route exact path="/sifremiunuttum">
        <RemindPassword apiurl={APP_BACKEND_URL}/>
      </Route>
      <Route exact path={"/sifreyenile/:token/:email"} render={({match})=>(
        <ResetPassword apiurl={APP_BACKEND_URL} token={match.params.token} email={match.params.email} />
      )} />
      <Route exact path={"/epostadogrula/:id/:hash"} render={({match})=>(
        <VerifyEmail apiurl={APP_BACKEND_URL} id={match.params.id} hash={match.params.hash} expires={match.params.expires}/>
      )} />
      <Route exact path={"/" + productroot + "/:slug"} render={({match})=>(
        <ProductDetails productslug={match.params.slug} apiurl={APP_BACKEND_URL}/>
      )} />
      <Route exact path="/redirect">
        <RedirectPage apiurl={APP_BACKEND_URL}/>
      </Route>
      <Route path="*">
        <NoMatch />
      </Route>
      </Switch>
    </Router>

  );
}
export default App;
