import React, {Component} from 'react';
import CardCarousel from '../components/cardcarousel.js';
import Product from '../parts/product';
export class Sepetim extends Component {
    
    render() {
        document.title="Karşıkomşum- Sepetim"
        return (
            <div className="container bg-white shadow-lg mb-2 mt-2 rounded">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="">
                                <div className="card-body cart">
                                    <div className="col-sm-12 empty-cart-cls text-center"> 
                                        <h1><i className="text-success fas fa-shopping-cart"></i></h1>
                                        <h3><strong>Sepetin şu an boş</strong></h3>
                                        <h4>Sepetini karsikomsum'ın fırsatlarla dolu dünyasından doldurmak için<br/>
                                        aşağıdaki ürünleri incelemeye başlayabilirsin.</h4> <a href="/" className="btn btn-blue cart-btn-transform m-3" data-abc="true">alışverişe devam</a>
                                    </div>
                                    <CardCarousel slidesToScroll={3} slidesToShow={3}>
                                        <div className="p-3">
                                        <Product productid='1' productname='HP LAPTOP' salelabel='FIRSAT' badgetype='bg-danger' seller='Cello Bilişim' rating='2' currentprice='2000 ₺' openingprice='YOK' image1='./assets/images/anakart.jpeg' image2='./assets/images/anakart2.jpeg' endtime='2021-05-14 15:30:00'></Product>
                                        </div>
                                        <div className="p-3">
                                        <Product productid='2' productname='DENEME METNİ' salelabel='FIRSAT' badgetype='bg-danger' seller='Cello Bilişim' rating='4' currentprice='1500 ₺' openingprice='100 ₺' image1='./assets/images/anakart.jpeg' image2='./assets/images/anakart2.jpeg' endtime='2021-05-14 09:00:00'></Product>
                                        </div>
                                        <div className="p-3">
                                        <Product productid='3' productname='ASUS BOARD' salelabel='FIRSAT' badgetype='bg-danger' seller='Cello Bilişim' rating='5' currentprice='Teklif yok'  openingprice='700 ₺' image1='./assets/images/anakart.jpeg' image2='./assets/images/anakart2.jpeg' endtime='2021-05-14 11:00:00'></Product>
                                        </div>
                                        <div className="p-3">
                                        <Product productid='4' productname='HP LAPTOP' salelabel='FIRSAT' badgetype='bg-danger' seller='Cello Bilişim' rating='2' currentprice='2000 ₺' openingprice='YOK' image1='./assets/images/anakart.jpeg' image2='./assets/images/anakart2.jpeg' endtime='2021-04-25 15:30:00'></Product>
                                        </div>
                                        <div className="p-3">
                                        <Product productid='5' productname='DENEerME METNİ' salelabel='FIRSAT' badgetype='bg-danger' seller='Cello Bilişim' rating='4' currentprice='1500 ₺' openingprice='100 ₺' image1='./assets/images/anakart.jpeg' image2='./assets/images/anakart2.jpeg' endtime='2021-05-14 09:00:00'></Product>
                                        </div>
                                        <div className="p-3">
                                        <Product productid='6' productname='ASUS BOARD' salelabel='FIRSAT' badgetype='bg-danger' seller='Cello Bilişim' rating='5' currentprice='Teklif yok'  openingprice='700 ₺' image1='./assets/images/anakart.jpeg' image2='./assets/images/anakart2.jpeg' endtime='2021-05-14 11:00:00'></Product>
                                        </div>
                                    </CardCarousel>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>

        )
    }
}

export default Sepetim;