import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import {showToast} from '../utils';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import ImageUploadMultiple from '../imageuploadmultiple.js'
import './newpost.css';

class NewPost extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePaste = this.handlePaste.bind(this);
        this.fileHandleChange = this.fileHandleChange.bind(this);
      }
    state = {
        pasted: false,
        pastedUrl: null,
        writtenText: '',
        allText: '',
        files: []
    }
    handleSubmit = event => {
        event.preventDefault();
        const { cookies } = this.props;
        var retype = 'btn-success';
        
        if (this.state.allText!=='' && this.state.files) {
            var formData = new FormData()
            formData.append('post_content',  this.state.allText)
            formData.append('post_url', this.state.pastedUrl)
            this.state.files.map((file, index) => {
                formData.append('images[]', file);
            });
            fetch(this.props.apiurl + '/api/addPost', {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Authorization': 'Bearer ' + cookies.get('access-token') ,
                },
                body: formData
              })
              .then((response) => {
                if(!response.ok) {
                  retype = 'bg-danger';
                  return response.json();
                }
                else{
                  retype = 'btn-success';
                  return response.json();
                } 
              })
              .then((responseData) => {
                  
                 if (responseData.message==='Unauthenticated.') {
                    showToast('Paylaşım yapmak için giriş yapmalısınız', 'bg-danger', 5000);
                } else {
                    showToast(responseData.message, 'btn-success', 5000);
                    window.location.reload();
                }
                 //document.getElementById("send").disabled=true;
               })
              .catch((error) => {
                showToast(error, "bg-danger", 5000);
              });
        } else {
            showToast("Paylaşılacak metni girmeden paylaşım yapamazsınız", "bg-danger", 5000);
        }
      };
    handleChange = (event) => {
        if (event.target.value!='') {
            this.setState({allText: event.target.value})
            if (this.state.pasted) {
                var afterPaste = event.target.value;
                var pastedText = afterPaste.replace(this.state.writtenText, '')
                pastedText = pastedText.trim();
                if (this.validURL(pastedText)) {
                    this.setState({pastedUrl: pastedText});
                }
                this.setState({pasted: false});
            } else {
                this.setState({writtenText: event.target.value});
            }
        } else {
            this.setState({pastedUrl:null, allText:''});
        }
    }

    fileHandleChange(e) {
        this.setState({
          "files": e
        });
    }
    

    handlePaste = () => {
        this.setState({pasted: true});
    };

    validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
      }
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };


    render() {
        return (
            <div className="card p-0 m-2">
                    <div className="card-body">
                        <div id="myTabContent">
                                <div className="form-group">
                                    <label className="sr-only" for="message">post</label>
                                    <textarea className="form-control" id="message" rows="3" onChange={e => this.handleChange(e)} onPaste={this.handlePaste} placeholder="Ne düşünüyorsun? Komşularınla paylaş" required></textarea>
                                    {this.state.pastedUrl!=null && ReactPlayer.canPlay(this.state.pastedUrl) && <ReactPlayer className='col-12 mt-2' url={this.state.pastedUrl} />}
                                </div>
                        </div>
                        <div className="">
                            <hr/>
                            <ImageUploadMultiple fileHandleChange={this.fileHandleChange}/>
                            
                            <div className="btn-group float-right">
                                <button onClick={this.handleSubmit} className="btn btn-primary btn-sm">Paylaş</button>
                            </div>
                            
                        </div>
                    </div>
                </div>
        )
    }
}

export default withCookies(NewPost);