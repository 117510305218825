import React from "react";
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import tr from 'date-fns/locale/tr';

import "react-datepicker/dist/react-datepicker.css";
import addDays from 'date-fns/addDays'

//import { FormGroup, Label, Input } from "reactstrap";

const Step3 = props => {
  if (props.currentStep !== 3) {
    return null;
  }
  registerLocale("tr", tr)
  return (

    <>
      <form>
      <div className="row">
      <div className="col-md-2 text-left">Açılış Fiyatı</div>
        <div className="col-md-4"><input className="form-control"
          type="number"
          name="openingprice"
          id="openingprice"
          min="0"
          placeholder="Mezat açılış fiyatı koymak istiyorsanız belirtin"
          value={props.openingprice} // Prop: The username input data
          onChange={props.handleChange} // Prop: Puts data into the state
        /></div>
        <div className="col-md-2 text-left">Mezat Başlangıcı</div>
        <div className="col-md-4 form-group">
          <DatePicker
              selected={ props.startdate }
              onChange={ props.handleStartDateChange}
              name="startdate"
              dateFormat="dd/MM/yyyy HH:mm"
              showTimeSelect
              timeFormat="HH:mm"
              timeCaption="Saat"
              locale="tr"
              minDate={new Date()}
              maxDate={addDays(new Date(), 7)}
              text={props.startdate}
          />
      </div>
      </div>
      <div className="row">        
        <div className="col-md-2 text-left">Hemen Al Fiyatı</div>
        <div className="col-md-4"><input className="form-control"
          type="number"
          name="buynowprice"
          id="buynowprice"
          min="0"
          placeholder="Hemen Al Fiyatını belirtmeniz gerekmektedir."
          value={props.buynowprice} // Prop: The username input data
          onChange={props.handleChange} // Prop: Puts data into the state
          required
        /></div>
        <div className="col-md-2 text-left">Mezat Bitişi</div>
        <div className="col-md-4 form-group">
          <DatePicker
              selected={ props.enddate }
              onChange={ props.handleEndDateChange}
              name="enddate"
              dateFormat="dd/MM/yyyy HH:mm"
              showTimeSelect
              timeFormat="HH:mm"
              timeCaption="Saat"
              locale="tr"
              minDate={new Date()}
              maxDate={addDays(new Date(), 7)}
              text={props.enddate}
          />
      </div>
      </div>
      </form>
    </>
  );
};

export default Step3;
